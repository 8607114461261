import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout, Menu, Button, Select, Dropdown, Cascader } from "antd";
import {
  BellOutlined,
  ExportOutlined,
  SettingOutlined,
  KeyOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
} from "@ant-design/icons";
import "./style.less";
import http from "../../utils/http";
import { Routes, Route, useNavigate, Outlet } from "react-router-dom";
import Logo from "../../assets/编组 3@2x (1).png";
import Img1 from "../../assets/icon／销售@2x.png";
import Img2 from "../../assets/icon／销售@2x (1).png";
import Img3 from "../../assets/icon／二手车@2x.png";
import Img4 from "../../assets/icon／生产@2x.png";
import Img5 from "../../assets/icon／4S@2x.png";
import Img6 from "../../assets/icon／提示@2x.png";
import Img7 from "../../assets/icon-行政管理.svg";
import { useStore } from "../content/store";
import ChangePass from "./change";
import UploadExport from "./uploadExport";
import { handelResp } from "../../utils";
import { useFullScreenHandle } from "react-full-screen";

const { SubMenu } = Menu;

const { Header, Sider, Content } = Layout;

function fullScreen() {
  var de = document.documentElement;
    if (de.requestFullscreen) {
        de.requestFullscreen();
    } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
    } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
    }
}

function exitFullscreen() {
  if(document.exitFullscreen) {
       document.exitFullscreen();
   } else if(document.mozCancelFullScreen) {
       document.mozCancelFullScreen();
   } else if(document.webkitExitFullscreen) {
       document.webkitExitFullscreen();
   }
 }
// const menusList = ["A", "E", "R", "M"];
const iconMapping = {
  销售: <img className="menu-icon" src={Img1} />,
  售后: <img className="menu-icon" src={Img2} />,
  二手车: <img className="menu-icon" src={Img3} />,
  生产: <img className="menu-icon" src={Img4} />,
  "4S管理总部": (
    <img className="menu-icon 4s-icon" style={{ height: "18px" }} src={Img5} />
  ),
  管理部: <img className="menu-icon" src={Img7} />,
};
const menusMapping = {
  A: "实绩",
  E: "预算",
  RE: "预算报表",
  RA: "实绩报表",
  M: "管理",
  T: "工具",
  OP: "经营计划",
  R: "滚动预算",
  RR: "滚动报表",
  DR: '销售日报'
};
function Admin() {
  const params = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState({});
  const [type, setType] = useState(params.type);
  const [co_name, setco_name] = useState("");
  const [display_name, setdisplay_name] = useState("");
  const [isSuper, setSuper] = useState(false);
  const [showChange, setChangeShow] = useState(false);
  // const [pageId, setPageId] = useState(params.page_id);
  const [store, setStore] = useStore();
  const nav = useNavigate();
  const screen = useFullScreenHandle();
  const [isScreen, setIsScreen] = useState(false);

  const [comps, setComps] = useState([]);
  const [treeComps, setTreeComps] = useState([])
  const [compsMapping, setCompsMapping] = useState({})
  const getCompData = async () => {
    setComps([]);
    const resp = await http.get("/base/getCompanyList", {
      page_id: "auditec",
      page_size: 100,
      page: 1,
    });
    handelResp(resp).then(() => {
      setComps(resp.data.mCompany ? resp.data.mCompany : []);
    });
    setTreeComps([])
    setCompsMapping({})
    const mapp = {}
    const handelchild = (val, obj={})=>{
      obj.label = val.co_name
      obj.value = val.id + val.co_name
      mapp[val.id] = val
      if(val.SubNode){
        obj.children = []
        val.SubNode.forEach(v=>{
          const res = handelchild(v)
          if(Array.isArray(res)){
            obj.children.push(res[0])
            obj.children.push(res[1])
          }else{
            obj.children.push(res)
          }
        })
        obj.label += '下属'
        return [{
          label: val.co_name,
          value: val.id
        }, obj]
      }else{
        return {
          label: val.co_name,
          value: val.id
        }
      }
    }
    setCompsMapping(mapp)
    const res = await http.get('/base/getCompanyTree')
    handelResp(res).then(data=>{
      console.log(data)
      const res = handelchild(data.coTree)
      console.log(res)
      setTreeComps([
        res[0],
        ...res[1].children
      ])
    })
  };

  const pageId = params.page_id;

  const optMenus = (datas) => {
    // datas['M']['管理（预算）'] = {
    //   business_name: "E",
    //   // page_auth: 5,
    //   page_id: "E",
    //   page_name: "管理（预算）",
    //   page_status: "",
    //   page_type: "M",
    // }
    // datas['M']['管理（实绩）'] = {
    //   business_name: "A",
    //   // page_auth: 5,
    //   page_id: "A",
    //   page_name: "管理（实绩）",
    //   page_status: "",
    //   page_type: "M",
    // }
  };
  const getInfo = async () => {
    // /base/getUserInfo
    const resp = await http.get("/base/getUserInfo");
    handelResp(resp).then(() => {
      setco_name(resp.data.mUserExt01s[0].co_name);
      setdisplay_name(resp.data.mUserExt01s[0].display_name);
      localStorage.setItem("co_type", resp.data.mUserExt01s[0].co_type);
      localStorage.setItem("co_name", resp.data.mUserExt01s[0].co_name);
      localStorage.setItem(
        "display_name",
        resp.data.mUserExt01s[0].display_name
      );
      const su = resp.data.mUserExt01s[0].co_type === "HQ" ? true : false;
      setSuper(su);
      setStore({ isSuper: su, co_name: resp.data.mUserExt01s[0].co_name });
      if (su) {
        getCompData();
      }
    });
  };

  const getCurrentFlow = async () => {
    const resp = await http.get("/base/getCurrentBudgetInfo");
    handelResp(resp).then(() => {
      const { achievementBudgetFlow, estimateBudgetFlow } = resp.data;
      setStore({ a: achievementBudgetFlow, e: estimateBudgetFlow });
    });
  };

  const selectAuth = async () => {
    const resp = await http.get("/base/selectMAuthExt01");
    // console.log(resp);
    handelResp(resp).then(() => {
      const datas = {};
      const auths = {}
      resp.data.mAuthExt01s.forEach((element) => {
        // console.log(element)
        if (!datas[element.page_type]) {
          datas[element.page_type] = {};
        }
        const page_category = datas[element.page_type][element.business_name];
        if (!page_category) {
          datas[element.page_type][element.business_name] = [element];
        } else {
          datas[element.page_type][element.business_name].push(element);
        }
        auths[element.page_id] = element.page_auth
      });
      setStore({auths})
      optMenus(datas);
      // console.log(datas);
      setItems(datas);
    });
  };

  useEffect(() => {
    selectAuth();
    getInfo();
    getCurrentFlow();
  }, []);

  return (
    
    <div className="admin">
      <Layout>
        <Header
          className="site-layout-background"
          style={{
            padding: "0 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: ()=>setCollapsed(!collapsed),
            })} */}
          {/* <div className="logo" style={{width: '120px', height: '60px'}} ></div> */}
          <div
            className="header-item"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="logo" onClick={() => nav(`/admin`)}>
              <img src={Logo} alt="" />
            </div>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={type ? [type] : []}
            >
              {Object.keys(items).map((val) => {
                return (
                  <Menu.Item
                    key={val}
                    onClick={() => {
                      setType(val);
                      nav(`/admin/content/${val}/${menusMapping[val]}/-/-`);
                    }}
                  >
                    {menusMapping[val]}
                  </Menu.Item>
                );
              })}
            </Menu>
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="header-option"
          >
            {false && (
              <Select
                defaultValue={localStorage.getItem("coId")}
                onChange={(e) => {
                  console.log(e);
                  let co_name = localStorage.getItem("co_name");
                  const comp = comps.filter((val) => val.id === e);
                  if (comp.length) {
                    co_name = comp[0].co_name;
                  }
                  setStore({
                    coId: e,
                    isSuper: !(e.indexOf("-") > -1),
                    co_name,
                  });
                }}
                style={{ marginRight: 20, width: 200 }}
              >
                {/* <Select.Option
                  value={localStorage.getItem("coId")}
                  key={localStorage.getItem("coId")}
                >
                  {localStorage.getItem("co_name")}(总公司)
                </Select.Option> */}
                {comps.map((val, index) => {
                  return (
                    <Select.Option value={val.id} key={val.id}>
                      {val.co_name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
            {isSuper && <Cascader 
              defaultValue={[localStorage.getItem("coId")]}
              allowClear={false}
              options={treeComps} onChange={e=>{
              console.log(e)
              // console.log(compsMapping[e[e.length-1]])
              const com = compsMapping[e[e.length-1]]
              setStore({
                coId: com.id,
                isSuper: com.co_type === "HQ",
                co_name: com.co_name,
              });

            }} style={{ marginRight: 20, width: 200 }} />}
            <div className="co-name" title={`${display_name}(${co_name})`}>
              {display_name}({co_name})
            </div>
            {/* <span className="msg">
              <img src={Img6} />
            </span> */}
            <div className="header-btns">
              {/* <Button
                icon={<ExportOutlined />}
                size="large"
                className="logout"
                onClick={() => {
                  localStorage.removeItem("token");
                  window.location = `//${window.location.host}/login`;
                }}
              >
                退出
              </Button> */}
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="1"
                      icon={<KeyOutlined />}
                      onClick={() => setChangeShow(true)}
                    >
                      修改密码
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      icon={
                        isScreen ? <ShrinkOutlined /> : <ArrowsAltOutlined />
                      }
                      onClick={() => {
                        if(isScreen){
                          // screen.exit()
                          exitFullscreen()
                          setIsScreen(false)
                        }else{
                          // screen.enter()
                          fullScreen()
                          setIsScreen(true)
                        }
                      }}
                    >
                      {isScreen ? "退出全屏" : "全屏"}
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<ExportOutlined />}
                      onClick={() => {
                        localStorage.removeItem("token");
                        if (localStorage.getItem("isSuperLogin")) {
                          window.location = `//${window.location.host}/loginSuper`;
                          localStorage.removeItem("isSuperLogin");
                        } else {
                          window.location = `//${window.location.host}/login`;
                        }
                      }}
                    >
                      退出
                    </Menu.Item>
                  </Menu>
                }
              >
                <SettingOutlined
                  className="hover"
                  style={{ color: "#fff", fontSize: "30px" }}
                />
              </Dropdown>
            </div>
          </div>
        </Header>
        <Layout>
          <Sider collapsedWidth={50} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            {/* { type && type.length === 2 && <UploadExport type={type} name={menusMapping[type]} />} */}
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={pageId ? [pageId] : []}
              style={{ height: "calc(100vh - 112px)", overflow: "auto",  border: 0}}
            >
              {type &&
                items[type] &&
                Object.keys(items[type]).map((element, index) => {
                  return Array.isArray(items[type][element]) ? (
                    <SubMenu
                      key={index}
                      icon={iconMapping[element]}
                      title={element}
                    >
                      {type && type.length === 1 && type !== "M" && (
                        <UploadExport
                          type={type}
                          business_cd={items[type][element][0]["business_cd"]}
                          onSubmit={() => {
                            selectAuth();
                          }}
                          name={element}
                        />
                      )}
                      {items[type][element].map((item, key) => {
                        return (
                          <Menu.Item
                            key={item.page_id}
                            onClick={() => {
                              nav(
                                "/admin/content/" +
                                  type +
                                  "/" +
                                  menusMapping[type] +
                                  "/" +
                                  item.page_id +
                                  "/" +
                                  item.page_name
                              );
                            }}
                          >
                            <div className={`page-status f${item.page_status}`}>
                              {item.page_name}
                            </div>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={items[type][element]["page_id"]}
                      onClick={() => {
                        nav(
                          "/admin/opt/" +
                            type +
                            "/" +
                            menusMapping[type] +
                            "/" +
                            items[type][element]["page_id"] +
                            "/" +
                            items[type][element].page_name
                        );
                      }}
                    >
                      {items[type][element].page_name}
                    </Menu.Item>
                  );
                })}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Content
              className="site-layout-background"
              style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: 280,
                maxHeight: "calc(100vh - 108px)",
                overflow: "auto",
                borderRadius: 8,
                background: "#FAFAFA",
                position: "relative",
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
      {showChange && <ChangePass onCancel={() => setChangeShow(false)} />}
    </div>
  );
}

export default Admin;
