import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import "./style.less";
import http from "../../utils/http";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import BigImg from "../../assets/编组 7@2x.png";
import LoginImg from "../../assets/icon／登录@2x.png";
import { useStore } from '../content/store'
import ChangePass from '../admin/change'

function Login(props) {
  const navigate = useNavigate();
  const [store, setStore] = useStore()
  const [showChange, setChangeShow ] = useState(false)


  const onFinish = async (values) => {
    console.log("Success:", values);
    const resp = await http.post("/userLogin", values);

    // console.log(resp)

    if (resp.code === 200) {
      // localStorage.setItem('token', resp.data.token)
      Object.keys(resp.data).forEach((val) => {
        localStorage.setItem(val, resp.data[val]);
      });
      // console.log( resp.data)
      setStore({coId: resp.data.coId})
      console.log(props);
      navigate("/admin");
      localStorage.removeItem('isSuperLogin')
    }else{
      message.error(resp.msg)
      if(resp.code === 8888){
        localStorage.setItem('token', resp.data.token)
        localStorage.setItem('userId', resp.data.userId)
        setChangeShow(true)
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="login-container">
      {showChange && <ChangePass onCancel={() => {
        localStorage.removeItem('token')
        setChangeShow(false)
      }} />}
      <div className="login">
        <div className="left">
          <img src={BigImg} alt="" />
        </div>
        <div className="right">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          {/* <h2>上海圆众电子商务有限公司</h2> */}
          {/* <h1>预算管理系统</h1> */}
          <h1>4S集团管理决策系统</h1>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="用户名"
              name="user_name"
              // initialValue="maruadmin"
              rules={[{ required: true, message: "请输入用户名" }]}
            >
              <Input prefix={<div className="prefix"/>}/>
            </Form.Item>

            <Form.Item
              label="密码"
              name="password"
              // initialValue="admin123"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password prefix={<div className="prefix"/>} />
            </Form.Item>

            {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" size="large" htmlType="submit" icon={<img className="login-img" src={LoginImg}/>}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="bottom">
        www.maruzon.com
      </div>
    </div>
  );
}

export default Login;
