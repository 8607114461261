import React, { useEffect, useState, useRef } from "react";
import { Button, message, Table, Input, Form, DatePicker } from "antd";
import http from "../../utils/http";
import { useParams } from "react-router-dom";
import BtnIcon from "../../assets/icon／审核@2x.png";
import BtnIcon1 from "../../assets/编组@2x (4).png";
import BtnIcon2 from "../../assets/编组@2x (5).png";
import BtnIcon3 from "../../assets/编组@2x (6).png";
import BtnIcon4 from "../../assets/编组@2x (7).png";
import "./contentM.less";
import { useStore } from "./store";
import { handelResp, handleMonth } from "../../utils";
import BiM from "./biM";
import Alarm from "./alarm";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { WeeklyForm } from "./weekly";
import WeeklyReport from './weeklyReport'
import StockReport from "./stockReport";
import RpList from "./rpList";

function Mc(props) {
  const [comps, setComps] = useState([]);
  const params = useParams();
  const getCompData = async () => {
    setComps([]);
    const resp = await http.get("/base/selectMCompanyExt01", {
      page_id: props.pageId,
      page_size: 100,
      page: 1,
    });
    if (resp.code === 200) {
      setComps(resp.data.mCompanyExt01s ? resp.data.mCompanyExt01s : []);
    }
  };
  useEffect(() => {
    getCompData();
  }, [params]);

  const cols = [
    {
      title: "公司名",
      dataIndex: "co_name",
      key: "co_name",
    },
    {
      title: "预算年月",
      dataIndex: "budget_flow_id",
      key: "budget_flow_id",
    },
    {
      title: "状态",
      dataIndex: "budget_status_name",
      key: "budget_status_name",
      align: "center",
      render: (val) => {
        let name = "m-ready";
        if (val === "已退回") {
          name = "m-back";
        }
        if (val === "已通过") {
          name = "m-pass";
        }
        if (val === "修正") {
          name = "m-change";
        }
        if (val === "待审核") {
          name = "m-will";
        }
        return <div className={"stratus-tag " + name}>{val}</div>;
      },
    },
    {
      title: "操作",
      key: "opts",
      align: "right",
      render: (val, row) => {
        return (
          <div className="btns">
            {/* <Button className={`btn back-btn ${row.can_send_back ? '' : 'gary'}`} icon={<img src={BtnIcon1} />} style={{ marginRight: 10 }} type="primary">退回</Button> */}
            {/* <Button className={`btn pass-btn ${row.can_pass_audit ? '' : 'gary'}`} icon={<img src={BtnIcon2} />} type="primary">通过</Button> */}
            <Button
              className={`btn submit ${
                row.page_control_auth.can_pass_audit ? "" : "gary"
              }`}
              type="primary"
              icon={<img style={{ width: 14 }} src={BtnIcon} />}
              onClick={async () => {
                if (!row.page_control_auth.can_pass_audit) return;
                const resp = await http.put(
                  "/base/budgetApprove" +
                    `?budget_flow_id=${row.budget_flow_id}`,
                  null
                );
                console.log(resp);
                if (resp.code === 200) {
                  message.success(resp.msg);
                  getCompData();
                }
              }}
              // disabled={!val.page_control_auth.can_pass_audit}
            >
              通过
            </Button>
            <Button
              type="primary"
              icon={<img src={BtnIcon1} />}
              className={`btn back-btn ${
                row.page_control_auth.can_audit_back ? "" : "gary"
              }`}
              onClick={async () => {
                if (!row.page_control_auth.can_audit_back) return;
                const resp = await http.put(
                  "/base/budgetSendBack" +
                    `?budget_flow_id=${row.budget_flow_id}`,
                  null
                );
                console.log(resp);
                if (resp.code === 200) {
                  message.success(resp.msg);
                  getCompData();
                }
              }}
            >
              退回
            </Button>
            <Button
              type="primary"
              icon={<img src={BtnIcon2} />}
              className={`btn modify-btn ${
                row.page_control_auth.can_Modify_budget ? "" : "gary"
              }`}
              onClick={async () => {
                if (!row.page_control_auth.can_Modify_budget) return;
                const resp = await http.put(
                  "/base/budgetModify" +
                    `?budget_flow_id=${row.budget_flow_id}`,
                  null
                );
                console.log(resp);
                if (resp.code === 200) {
                  message.success(resp.msg);
                  getCompData();
                }
              }}
            >
              修正
            </Button>
            <Button
              type="primary"
              icon={<img src={BtnIcon3} />}
              className={`btn start-btn ${
                row.page_control_auth.can_Start_budget ? "" : "gary"
              }`}
              onClick={async () => {
                if (!row.page_control_auth.can_Start_budget) return;
                const resp = await http.put(
                  "/base/startBudgetFlow" +
                    `?co_id=${val.id}&flow_type=${props.pageId[
                      props.pageId.length - 2
                    ].toUpperCase()}`,
                  null
                );
                console.log(resp);
                if (resp.code === 200) {
                  message.success(resp.msg);
                  getCompData();
                }
              }}
            >
              启动
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="contentM no-scroll">
      <div>
        {/* <Button type="primary"
        className="submit"
        icon={<img style={{ width: 14, height: 16, marginRight: 8 }} src={BtnIcon} />}
        style={{ float: 'right' }}>提交总公司</Button> */}
        <h1>{params.title}</h1>
      </div>
      <div className="content">
        <Table size="small" columns={cols} dataSource={comps} />
      </div>
    </div>
  );
}

const statusMapping = {
  "00": "准备中",
  "01": "审核中",
  "02": "已审核",
  "03": "已退回",
  "04": "修正中",
};

const statusMappingname = {
  "00": "draft",
  "01": "draft",
  "02": "pass",
  "03": "no-pass",
  "04": "draft",
};

function Ms(props) {
  const [pages, setPages] = useState([]);
  const params = useParams();
  const getPageDatas = async () => {
    setPages([]);
    const resp = await http.get("/base/selectTPageFlowExt01", {
      page_id: props.pageId,
    });
    if (resp.code === 200) {
      setPages(resp.data.tPageFlowExt01s ? resp.data.tPageFlowExt01s : []);
    }
  };
  useEffect(() => {
    getPageDatas();
  }, [params]);
  const cols = [
    {
      title: "页面名",
      key: "page_name",
      dataIndex: "page_name",
    },
    {
      title: "预算年度",
      key: "budget_flow_id",
      dataIndex: "budget_flow_id",
    },
    {
      title: "状态",
      key: "page_status_name",
      dataIndex: "page_status_name",
      align: "center",
      render: (val) => {
        let name = "pass";
        if (val === "草稿") {
          name = "draft";
        }
        if (val === "已退回") {
          name = "no-pass";
        }
        return <div className={name}>{val}</div>;
      },
    },
    {
      title: "操作",
      key: "opts",
      align: "right",
      render: (val, row) => {
        return (
          <div className="btns">
            <Button
              className={`btn back-btn ${
                row.page_control_auth.can_send_back ? "" : "gary"
              }`}
              icon={<img src={BtnIcon1} />}
              style={{ marginRight: 10 }}
              type="primary"
              onClick={async () => {
                if (!row.page_control_auth.can_send_back) return;
                const resp = await http.put(
                  "/base/pageSendBack" + `?page_flow_id=${row.id}`,
                  null
                );
                console.log(resp);
                if (resp.code === 200) {
                  message.success("退回成功");
                  getPageDatas();
                } else {
                  message.success(resp.msg);
                }
              }}
            >
              退回
            </Button>
            {/* <Button className={`btn pass-btn ${row.page_control_auth.can_send_to_audit ? '' : 'gary'}`} icon={<img src={BtnIcon2} />} type="primary" onClick={async ()=>{
            const resp = await http.put(
              "/base/budgetSubmit" +
              `?page_flow_id=${params.page_id}`,
              null
            );
            console.log(resp);
            if (resp.code === 200) {
              message.success(resp.msg);
              getPageDatas()
            }
          }}>通过</Button> */}
          </div>
        );
      },
    },
  ];
  console.log(pages);
  return (
    <div className="contentM">
      <div>
        <Button
          type="primary"
          className={`submit ${
            pages.filter((val) => val.page_control_auth.can_send_to_audit)
              .length
              ? ""
              : "gary"
          }`}
          icon={
            <img
              style={{ width: 14, height: 16, marginRight: 8 }}
              src={BtnIcon}
            />
          }
          onClick={async () => {
            if (
              !pages.filter((val) => val.page_control_auth.can_send_to_audit)
                .length
            )
              return;
            const resp = await http.put(
              "/base/budgetSubmit" +
                `?budget_flow_id=${pages[0].budget_flow_id}`,
              null
            );
            console.log(resp);
            if (resp.code === 200) {
              message.success("提交成功");
              getPageDatas();
            } else {
              message.success(resp.msg);
            }
          }}
          style={{ float: "right" }}
        >
          提交总公司
        </Button>
        {pages.length ? (
          <div
            style={{ float: "right", marginRight: "20px" }}
            className={statusMappingname[pages[0].budget_status]}
          >
            {statusMapping[pages[0].budget_status]}
          </div>
        ) : (
          ""
        )}

        <h1>{params.title}</h1>
      </div>
      <div className="content">
        <Table size="small" columns={cols} dataSource={pages} />
      </div>
    </div>
  );
}

function Backup(props) {
  const params = useParams();
  const [list, setList] = useState([]);
  const [store, setStore] = useStore();
  // console.log(store)
  const co_id = localStorage.getItem("coId");
  const getList = async () => {
    const resp = await http.get("/base/getEstimateBackupList", { co_id });
    handelResp(resp).then((data) => setList(data.estimateBackups));
  };
  useEffect(() => {
    // del({backup_name: '211@121'})
    getList();
  }, []);
  const submit = async (values) => {
    const resp = await http.put(
      `/base/estimateBackup?co_id=${co_id}&year=${
        store.e && store.e.year
      }&backup_name=${values.backup_name}`
    );
    handelResp(resp, "备份成功").then((data) => getList());
  };

  const reback = async (row) => {
    console.log(row.backup_name);
    const url = `/base/estimateRestore?co_id=${co_id}&year=${row.year}&backup_name=${row.backup_name}`;
    const resp = await http.put(url);
    handelResp(resp, "恢复成功").then(() => getList());
  };
  const del = async (row) => {
    console.log(row.backup_name);
    // const url = `/base/deleteEstimateBackup?backup_name=${row.backup_name}`
    const url = `/base/deleteEstimateBackup?backup_name=${row.backup_name}`;
    const resp = await http.delete(url);
    handelResp(resp, "删除成功").then((data) => getList());
  };

  return (
    <div className="backup content">
      <h1 className="content-title">{params.title}</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Form layout="inline" onFinish={submit}>
          <Form.Item name="backup_name" label="备份名称">
            <Input></Input>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              创建备份
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{ marginTop: 20 }}></div>
      <Table
        columns={[
          {
            title: "备份名称",
            dataIndex: "backup_name",
            key: "backup_name",
          },
          {
            title: "预算年份",
            dataIndex: "year",
            key: "year",
          },
          {
            title: "更新时间",
            dataIndex: "last_update_date",
            key: "last_update_date",
            render: (val) => new Date(val).toLocaleString(),
          },
          {
            title: "备份时间",
            dataIndex: "backup_name",
            key: "backup_name",
            render: (val) => {
              const time = val.split("@")[1];

              return `${time.slice(0, 4)}-${time.slice(4, 6)}-${time.slice(
                6,
                8
              )} ${time.slice(8, 10)}:${time.slice(10, 12)}`;
            },
          },
          {
            title: "恢复",
            dataIndex: "year",
            key: "opts",
            render: (val, row) => {
              const year = parseInt(val);
              if (store.e && !(year < store.e.year)) {
                return (
                  <div>
                    <Button
                      style={{ marginRight: 10 }}
                      type="primary"
                      size="small"
                      onClick={() => reback(row)}
                    >
                      恢复
                    </Button>
                    <Button
                      type="primary"
                      danger
                      size="small"
                      onClick={() => del(row)}
                    >
                      删除
                    </Button>
                  </div>
                );
              }
            },
          },
        ]}
        dataSource={list}
        pagination={false}
      ></Table>
    </div>
  );
}
dayjs.extend(customParseFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
const RateColor = 'red'
const MonthColor = '#333333'
const TodayColor = '#057fbb'
function ContentReport() {
  const dateFormat = "YYYY/MM/DD";
  const [date, setDate] = useState(dayjs());
  const [unSubmit, setUnSubmit] = useState([]);
  const [data, setData] = useState([]);
  const [top, setTop] = useState(0)
  const [showPointer, setShowPointer] = useState(false)
  const mapping = {
    交付完成率: {name: "delivery_complete_rate", color: RateColor},
    零售交付完成率: {name: "retail_complete_rate", color: RateColor},

    月累计订单: {name:"monthly_order", color:MonthColor,},
    月累计零售订单: {name:"monthly_retail_order", color:MonthColor,},

    当日展厅新增订单: {name:"daily_4s_order", color:TodayColor,},
    当日DCC新增订单: {name:"daily_dcc_order", color:TodayColor,},
    当日二网新增订单: {name:"daily_2nd_order", color:TodayColor,},

    月预算交付目标: {name:"monthly_delivery_target", color:MonthColor,},
    月实际交付完成: {name:"monthly_delivery_achievement", color:MonthColor,},
    月预算零售交付目标: {name:"monthly_retail_target", color:MonthColor,},
    月累计零售交付完成: {name:"monthly_retail_achievement", color:MonthColor,},

    当日展厅交付: {name:"daily_4s_delivery", color:TodayColor,},
    当日DCC交付: {name:"daily_dcc_delivery", color:TodayColor,},
    当日二网交付: {name:"daily_2nd_delivery", color:TodayColor,},

    月累计批售车交付: {name:"monthly_wholesale_delivery", color:MonthColor,},
    月累计9字头交付: {name:"monthly_9_delivery", color:MonthColor,},

    当日批售车交付: {name:"daily_wholesale_delivery", color:TodayColor,},
    当日9字头交付: {name:"daily_9_delivery", color:TodayColor,},

    月累计展厅客流: {name:"monthly_passenger", color:MonthColor,},
    月累计DCC线索: {name:"monthly_dcc", color:MonthColor,},

    当日展厅客流: {name:"daily_passenger", color:TodayColor,},
    当日DCC线索: {name:"daily_dcc", color:TodayColor,},
    
  };
  const onChange = (datem, dateString) => {
    setDate(datem);
  };
  const ref = useRef()
  useEffect(()=>{
    if(ref.current){
      // console.log(ref)
      // console.log(ref.current.clientWidth, ref.current.scrollWidth)
      if(ref.current.scrollWidth > (ref.current.clientWidth + 10)){
        setShowPointer(true)
      }else{
        setShowPointer(false)
      }
    }
  }, [data])
  const downLoad = async () => {
    const response = await http.download("/daily/exportJyDailyExcel", {
      parent_id: localStorage.getItem("coId"),
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      day: handleMonth(date.date()),
    });
    console.log(response);
    var blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      decodeURI("日报" + date.format(dateFormat) + ".xlsx")
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const getData = async () => {
    console.log(date);
    const resp = await http.get("/daily/selectJyDaily", {
      parent_id: localStorage.getItem("coId"),
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      day: handleMonth(date.date()),
    });
    handelResp(resp).then((data) => {
      // console.log(data);
      if (data.jyDailys) {
        setData(data.jyDailys);
      } else {
        setData([]);
      }
    });

    const response = await http.get("/daily/getNotSubmitList", {
      parent_id: localStorage.getItem("coId"),
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      day: handleMonth(date.date()),
    });
    handelResp(response).then((data) => {
      if (data.dailyCos) {
        setUnSubmit(data.dailyCos);
      }
    });
  };
  const onScroll = e=>{
    // console.log(e.target.scrollTop)
    setTop(e.target.scrollTop)
  }

  useEffect(() => {
    getData();
  }, [date]);
  const subjects = Object.keys(mapping);
  return (
    <div className="content-report">
      <div>
        <span>选择日期:</span>&nbsp;&nbsp;
        <DatePicker
          onChange={onChange}
          defaultValue={date}
          format={dateFormat}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button type="primary" onClick={downLoad}>
          下载
        </Button>
      </div>
      <p className="un-submit-txt">
        当日未提交日报：{unSubmit.length ? unSubmit.map((val, index)=>{
        return <span key={index}>{val.co_name}&nbsp;&nbsp;</span>
      }): '无'}</p>
      <div className="table" onScroll={onScroll} >
        <div className="subject">
          <div className="title" style={{top}}>
            <div className="txt">
              指标项目
            </div>
          </div>
          <div className="subject-items">
            {subjects.map((val, i) => {
              const color = mapping[val]['color']
              return <div className="subject-item" key={i} style={{color}}>
                {val}
              </div>;
            })}
          </div>
        </div>
        {showPointer && <div className="pointer">
              <LeftOutlined onClick={e=>{
                ref.current.scrollLeft -= 100 
              }} />
              <RightOutlined onClick={e=>{
                // console.log(e.target.parentNode.parentNode.parentNode)
                ref.current.scrollLeft += 100 
              }}/>
        </div>}
        <div className="content" ref={ref}>
            
            <div className="comp" style={{backgroundColor: '#FFE7E7'}}>
                  <div className="title" style={{top}}>
                    <div className="txt">合计</div>
                  </div>
                  <div className="items">
                    {subjects.map((sub, key)=>{
                      const isFlot = sub.indexOf('率')> -1
                      let value = 0
                      data.forEach(val=>{
                        value += val[mapping[sub]['name']]
                      })
                      if(sub === '交付完成率'){
                        let a = 0
                        let b = 0
                        data.forEach(val=>{
                          a += val[mapping['月预算交付目标']['name']]
                        })
                        data.forEach(val=>{
                          b += val[mapping['月实际交付完成']['name']]
                        })
                        value = a ? b / a: 0
                      }
                      if(sub === '零售交付完成率'){
                        let a = 0
                        let b = 0
                        data.forEach(val=>{
                          a += val[mapping['月预算零售交付目标']['name']]
                        })
                        data.forEach(val=>{
                          b += val[mapping['月累计零售交付完成']['name']]
                        })
                        value = a ? b / a: 0
                      }
                      const color = mapping[sub]['color']
                      return <div key={key} className="value" style={{color}}>
                        {isFlot ? (value*100).toFixed(0) : value}{ isFlot && '%'}
                      </div>
                    })}
                  </div>
            </div>
          {data.map((val, i)=>{
            return <div className="comp" key={i}>
                <div className="title" style={{top}}>
                  <div className="txt"> {val.co_name.slice(0, 5)}</div>
                </div>
                <div className="items">
                  {subjects.map((sub, key)=>{
                    const isFlot = sub.indexOf('率')> -1
                    const value = val[mapping[sub]['name']]
                    const color = mapping[sub]['color']
                    return <div key={key} className="value" style={{color}}>
                      {isFlot ? (value*100).toFixed(0) : value}{ isFlot && '%'}
                    </div>
                  })}
                </div>
            </div>
          })}
        </div>
      </div>
    </div>
  );
}
function ContentM() {
  const params = useParams();
  const page_id = params.page_id;
  const Pages = {
    backupes: <Backup />,
    auditac: <Mc pageId={page_id} />,
    auditec: <Mc pageId={page_id} />,
    auditas: <Ms pageId={page_id} />,
    audites: <Ms pageId={page_id} />,
    alarm: <Alarm />,
    "dash-board": <BiM />,
    "daily-report": <ContentReport />,
    'audit-mtarget': <MonthTarget />,
    'wr001': <WeeklyForm />,
    // 'wr002': <WeeklyReport />,
    'weekly-report': <WeeklyReport />,
    'stock-report': <StockReport />,
    'analyze-report': <RpList />,
  };
  return (
    <div>
      {/* {page_id === 'backupes' ? <Backup/> : params.page_id[page_id.length - 1] === "c" ? (
        <Mc pageId={page_id} />
      ) : (
        <Ms pageId={page_id} />
      )} */}
      {Pages[page_id]}
    </div>
  );
}

function MonthTarget(props) {
  const [comps, setComps] = useState([]);
  const dateFormat = "YYYY/MM";
  const params = useParams();
  const [date, setDate] = useState(dayjs());
  const getCompData = async () => {
    setComps([]);
    const resp = await http.get("/daily/getTargetList", {
      parent_id: localStorage.getItem("coId"),
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
    });
    handelResp(resp).then((data) => {
      setComps(data.mTargetExt01s ? data.mTargetExt01s : []);
    });
  };
  useEffect(() => {
    getCompData();
  }, [params, date]);

  const mp = {
    "00": "待提交",
    "01": "待审核",
    "02": "退回",
    "03": "通过",
  };

  const cols = [
    {
      title: "公司名",
      dataIndex: "co_name",
      key: "co_name",
    },
    {
      title: "月预算交付目标",
      dataIndex: "target1",
      key: "target1",
      align: "center",
    },
    {
      title: "月预算零售交付目标",
      dataIndex: "target2",
      key: "target2",
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (val) => {
        let name = "m-ready";
        if (val === "02") {
          name = "m-back";
        }
        if (val === "03") {
          name = "m-pass";
        }
        if (val === "00") {
          name = "m-change";
        }
        if (val === "01") {
          name = "m-will";
        }
        return <div className={"stratus-tag " + name}>{mp[val]}</div>;
      },
    },
    {
      title: "操作",
      key: "opts",
      align: "right",
      render: (val, row) => {
        return (
          <div className="btns">
            <Button
              className={`btn submit ${row.status === "01" ? "" : "gary"}`}
              type="primary"
              icon={<img style={{ width: 14 }} src={BtnIcon} />}
              onClick={async () => {
                const resp = await http.put("/tMTarget/update", {
                  ...row,
                  status: "03",
                });
                handelResp(resp).then((data) => {
                  message.success("通过成功");
                  getCompData();
                });
              }}
            >
              通过
            </Button>
            <Button
              type="primary"
              icon={<img src={BtnIcon1} />}
              className={`btn back-btn ${row.status === "01" ? "" : "gary"}`}
              onClick={async () => {
                const resp = await http.put("/tMTarget/update", {
                  ...row,
                  status: "02",
                });
                handelResp(resp).then((data) => {
                  message.success("退回成功");
                  getCompData();
                });
              }}
            >
              退回
            </Button>
          </div>
        );
      },
    },
  ];

  const onChange = (datem, dateString) => {
    setDate(datem);
  };

  return (
    <div className="contentM no-scroll">
      <div>
        {/* <Button type="primary"
        className="submit"
        icon={<img style={{ width: 14, height: 16, marginRight: 8 }} src={BtnIcon} />}
        style={{ float: 'right' }}>提交总公司</Button> */}
        <h1>{params.title}</h1>
      </div>
      <span>选择日期:</span>&nbsp;&nbsp;
      <DatePicker
        picker="month"
        onChange={onChange}
        defaultValue={date}
        format={dateFormat}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <div className="content">
        <Table size="small" columns={cols} dataSource={comps} />
      </div>
    </div>
  );
}

export default ContentM;
