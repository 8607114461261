import React, { useEffect, useState, useRef } from 'react'
import http from '../../utils/http'
import { Card } from 'antd'
import './style.less'
import * as echarts from 'echarts/core';
import {
    // 系列类型的定义后缀都为 SeriesOption
    LineChart,
    BarChart,
    PieChart,
} from 'echarts/charts';
import {
    TitleComponent,
    // 组件类型的定义后缀都为 ComponentOption
    GridComponent, LegendComponent,
    ToolboxComponent, TooltipComponent,
    DataZoomComponent,
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import { parseW } from '../eaCharts'
import { useStore } from '../../pages/content/store';
import { handelResp } from '../../utils';
// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use(
    [TitleComponent, GridComponent, BarChart, DataZoomComponent,
        LineChart, CanvasRenderer, LegendComponent, PieChart,
        TooltipComponent, ToolboxComponent]
);


const option = {
    title: {
        // text: 'World Population'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        valueFormatter: (value) => value.toFixed(2)
    },
    legend: {},
    grid: {
        left: '1%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
            formatter: '{value} 万'
        },
    },
    yAxis: {
        type: 'category',
        data: [],
        splitLine:{
            show:true
        },
        axisLabel:{
            formatter: function (vals){
                vals = vals.split('|')
                return `{a|${vals[0].length > 4 ? vals[0].slice(0,4): vals[0]}}{b|${vals[1]}}{c|${vals[2]}}`
            },
            rich: {
                a: {
                    width: 70,
                    align: 'left',
                    ellipsis: true,
                    overflow: 'truncate'
                },
                b: {
                    width: 60,
                    align: 'right'
                },
                c: {
                    width: 60,
                    align: 'right'
                }
            }
        },
    },
    series: [
        {
            name: '预算',
            type: 'bar',
            data: [],
            color: '#B8E986',
        },
        {
            name: '实绩',
            type: 'bar',
            data: [],
            color: '#3B7CFF'
        }
    ]
};
function format (num) {
 
    return (num+ '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g,'$1,');
 
}

export default props => {

    const { id, year, month, cost_id } = props
    const [datas, setDatas] = useState()
    const ref = useRef(null)
    const [store, setStore] = useStore()
    const isSuper = store.isSuper

    const setChart = (datas) => {
        if (!ref.current) return
        const eDatas = []
        const aDatas = []
        const cate = []
        datas.forEach(val => {
            cate.push(`${isSuper ? val.co_name : val.business_name} | ${format(val.ahievement_value.toFixed(2))} | ${val.estimate_value ? (val.ahievement_value / val.estimate_value * 100).toFixed(2) : 0}%`)
            eDatas.push(parseW(val.estimate_value))
            aDatas.push(parseW(val.ahievement_value))
        })
        option.series.forEach(val=>{
            val.itemStyle = {
                normal: {
                    barBorderRadius: [0, 5, 5, 0]
                }
              }
        })
        option.series[0].data = eDatas
        option.series[1].data = aDatas
        option.yAxis.data = cate
        const chart = echarts.init(ref.current)
        chart.setOption(option)

    }
    
    const getData = async () => {
        let cost_url = '/base/getProfitCompanyValue'
        let url = '/base/getSubjectCompanyValue'
        
        if(!isSuper){
            url = '/base/getSubjectBusinessValue'
            cost_url = '/base/getProfitBusinessValue'
        }
        // console.log(isSuper)
        const resp = await http.get(cost_id ? cost_url :url, {
            subject_id: cost_id ? null :id ,
            co_id: store.coId,
            year,
            month: '02',
            income_subject_id: cost_id ? id : null,
            cost_subject_id: cost_id
        })
        handelResp(resp).then(()=>{
            if(isSuper){
                setDatas(resp.data.subjectCompanyValues)
                setChart(resp.data.subjectCompanyValues)
            }else{
                setDatas(resp.data.subjectBusinessValues)
                setChart(resp.data.subjectBusinessValues)
            }
        })
    }



    useEffect(() => {
        getData()
    }, [store])

    return <div className="done-chart">
        <Card>
            <div className='done-chart-container'>
                {/* {datas && <div className='items'>
                    <div className="item">
                    <div className="text"></div>
                    <div className="text text2">主营收入</div>
                    <div className="text text2">完成率</div>
                    </div>
                    {
                        // datas.map(val => {
                        //     return <div className="item">
                        //         <div className="text">{isSuper ? val.co_name : val.business_name}</div>
                        //         <div className="text text2">{val.ahievement_value.toFixed(2)}</div>
                        //         <div className="text text2">{val.estimate_value ? (val.ahievement_value / val.estimate_value * 100).toFixed(2) : 0}%</div>
                        //     </div>
                        // })
                    }
                </div>} */}
                <div className="chart" ref={ref} style={{ width: '100%', height: 400 }}></div>
            </div>
        </Card>
    </div>
}