import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Drawer, Form, Radio, Input, InputNumber } from "antd";
// import disposeRegistry from '../../config/disposeRegistry';
// import { anyAwait } from '../../utils/methods';
import { mapping } from "../index";
import "./index.less";
import CoSelector from "../coSelector";
import AnySelector from "../anySelector";
import SingleSelector from "../singleSelector";

// type TProps = {
//   visible: boolean;
//   data: TEventData;
//   onClose?: () => void;
// };
const { TextArea } = Input;

export default function Index({ onClose, data, visible }) {
  const [disposeData, setDispose] = useState({});
  const [formRef] = Form.useForm();
  const onSubmit = async () => {
    const ref = formRef;
    // const [, isValid] = await anyAwait(ref.validate());
    // if (isValid === true) {
    const curFormData = ref.getFieldsValue(true);
    Object.assign(disposeData.formData, curFormData);
    // console.log("表单项修改：", disposeData);
    onClose();
    // }
  };
  const RenderDisposeForm = useMemo(
    () =>
      mapping[disposeData.compType]?.props.map((c, k) => {
        let formItem = null;

        const { formData, itemId } = disposeData;
        const key = `${itemId}-dispose-${k}`;
        const { options, defaultValue } = c;
        if (c.isSystem) return;
        switch (c.type) {
          case "radio":
            formItem = (
              <Radio.Group value={formData[c.name] || defaultValue}>
                {options.map((o, i) => (
                  <Radio
                    defaultChecked={formData[c.name] === o.value}
                    key={`${key}-${o.value}`}
                    value={o.value}
                  >
                    {o.label}
                  </Radio>
                ))}
              </Radio.Group>
            );
            break;
          case "inputNumber":
            formItem = <InputNumber />;
            break;
          case "coSelect":
            formItem = <CoSelector />;
            break;
          case "anySelect":
            formItem = <AnySelector {...c.prop} />;
            break;
          case "textArea":
            formItem = (
              <TextArea rows={4} placeholder="" />
            );
            break;
          case "ManyAnySelect":
            formItem = <c.Comp {...c.prop} />;
            break;
          case "singleSelect":
            formItem = <SingleSelector {...c.prop} />;
            break;
          case "customer":
            formItem = c.Comp
            break
          default:
            formItem = <Input />;
        }
        return (
          <Form.Item key={key} label={c.label} name={c.name} valuePropName={c.valuePropName}>
            {formItem}
          </Form.Item>
        );
      }),
    [disposeData.compType]
  );

  useEffect(() => {
    const { idx, current } = data;
    if (!current) return;
    const ref = formRef;
    const { formData } = current[idx];
    // console.log("当前表单：", current[idx]);
    setDispose(current[idx]);
    if (!formData) return;
    ref.setFieldsValue(formData);
    return ()=>{
      ref.resetFields()
    }
  }, [data, disposeData.formData]);
  return (
    <Drawer
      title={`${disposeData.compName}`}
      placement="right"
      open={visible}
      onClose={onClose}
      extra={
        <Button type={"primary"} onClick={onSubmit}>
          保存
        </Button>
      }
    >
      {/* 重新加载，避免表单缓存 */}
      {visible ? (
        <div className="form-sandbox__dispose">
          <Form form={formRef} labelAlign={"left"}>
            {disposeData?.compType && RenderDisposeForm}
          </Form>
        </div>
      ) : null}
    </Drawer>
  );
}
