import React, { useState, useEffect, useRef } from "react";
import http from "../../../../../../utils/http";
import { handelResp, handleMonth } from "../../../../../../utils";
import * as echarts from "echarts/core";
import { Select, Button, Tag, Modal, Input } from "antd";
import { monthMapping } from './index'
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { useStore } from "../../../../../../pages/content/store";
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);
const { confirm } = Modal;
const TagSelect = (props) => {
  const [indicatorList, setIndicatorList] = useState([]);
  const [tags, setTags] = useState([]);
  const [ids, setIds] = useState(props.value?props.value.subject_ids: []);
  const [currentTag, setCurrentTags] = useState(props.value ? props.value : {});

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("");

  const getIndicatorList = async () => {
    const resp = await http.get("/indi/selectIndicatorList");
    handelResp(resp).then((data) => {
      setIndicatorList(data.indicatorLists ? data.indicatorLists : []);
    });
  };
  const getTags = async () => {
    const resp = await http.get("/indi/getIndiTags");
    handelResp(resp).then((data) => {
      setTags(data.indicatorTags ? data.indicatorTags : []);
    });
  };
  useEffect(() => {
    getIndicatorList();
    getTags()
  }, []);

  // const onChange = (value) => {
  //   if (value && props.onChange) {
  //     props.onChange(
  //       props.item ? tags.filter((val) => val.id === value)[0] : value
  //     );
  //   }
  // };

  const handleChange = async (ids) => {
    setIds(ids);
    currentTag.id = ''
  };

  useEffect(()=>{
    if (ids.length && props.onChange) {
      props.onChange(
        {...currentTag, subject_ids: ids}
      );
    }
  }, [ids, currentTag])

  const handleOk = async () => {
    setConfirmLoading(true);
    const resp = await http.post("/indi/saveIndiTag", {
      subject_ids: ids,
      tag_name: modalText,
    });
    handelResp(resp).then(() => {
      setVisible(false);
      setConfirmLoading(false);
      getTags();
      setModalText("");
    });
  };

  return (
    // <Select
    //   showSearch
    //   // mode="multiple"
    //   optionFilterProp="children"
    //   filterOption={(input, option) => option.children.includes(input)}
    //   // allowClear
    //   style={{ width: "100%" }}
    //   placeholder="请选择指标"
    //   //   defaultValue={[]}
    //   defaultValue={
    //     props.item ? (props.value ? props.value.id : null) : props.value
    //   }
    //   onChange={onChange}
    // >
    //   {tags.map((val, index) => {
    //     return (
    //       <Select.Option key={val.id} title={val.tag_name}>
    //         {val.tag_name}
    //       </Select.Option>
    //     );
    //   })}
    // </Select>
    <div>
       <Input placeholder="请输入指标名称" value={currentTag.tag_name} 
        onChange={e=>{
          console.log(e.target.value)
          currentTag.tag_name = e.target.value
          setCurrentTags({...currentTag, id: ""})
        }}
       ></Input>
      <div
        className="search-filter"
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: 'center'
        }}
      >
        <Select
          showSearch
          mode="multiple"
          autoClearSearchValue={false}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          allowClear
          style={{ width: "80%" }}
          placeholder="请选择指标"
          //   defaultValue={[]}
          value={ids}
          onClear={() => {
            setIds([]);
            // seteData([]); 
            // setaData([]);
            setCurrentTags({});
          }}
          onChange={handleChange}
        >
          {indicatorList.map((val, index) => {
            return (
              <Select.Option key={val.id} title={val.ind_describe}>
                {val.ind_name}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          size="small"
          onClick={async () => {
            setVisible(true)
          }}
        >
          保存
        </Button>
      </div>

      <div className="tag-container">
        <div className="tag-label">标签：</div>
        <div className="tags">
          {tags.map((val, index) => {
            return (
              <Tag
                className={`hover ${val.id === props.value?.id ? "tag-active" : ""}`}
                color={val.id === currentTag.id ? 'green' : null}
                key={index}
                closable={val.user_id === "000000" ? false : true}
                onClick={() => {
                  setIds(val.subject_ids);
                  setCurrentTags(val);
                }}
                // color={currentTag === index ? "#1DA57A" : undefined}
                onClose={(e) => {
                  confirm({
                    title: "确定要删除这个标签吗?",
                    icon: <ExclamationCircleOutlined />,
                    content: "",
                    okText: "是的",
                    okType: "danger",
                    cancelText: "不删",
                    async onOk() {
                      const resp = await http.delete(
                        `/indi/deleteIndiTag?id=${val.id}`
                      );
                      handelResp(resp);
                      getTags();
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                  e.preventDefault();
                }}
              >
                {val.tag_name}
              </Tag>
            );
          })}
        </div>
      </div>
      <Modal
        title="保存为标签"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={()=>setVisible(false)}
      >
        <Input
          placeholder="请输入保存的标签名"
          value={modalText}
          onChange={(e) => setModalText(e.target.value)}
        ></Input>
      </Modal>
    </div>
  );
};
const props = [
  {
    label: "年",
    type: "input",
    name: "year",
    isSystem: true,
  },
  {
    label: "月",
    type: "input",
    name: "month",
    isSystem: true,
  },
  {
    label: "公司名",
    type: "coSelect",
    name: "co_id",
    isSystem: true,
  },
  {
    label: "图表高度",
    type: "inputNumber",
    name: "height",
  },
  {
    label: "指标组合",
    type: "ManyAnySelect",
    name: "sid",
    prop: {
      item: true,
    },
    Comp: TagSelect,
    // isSystem: true
  },
  {
    label: "图表类型",
    type: "customer",
    name: "chartType",
    Comp: <Select>
      <Select.Option key="line">折线图</Select.Option>
      <Select.Option key="bar">柱状图</Select.Option>
    </Select>
  },
];



function MonthChart(props) {
  const { year, month, co_id, sid, chartType, height } = props;
  const [opt, setOpt] = useState();
  const ref = useRef();
  const [store, setStore] = useStore();
  const getData = async () => {
    const resp = await http.put("/indi/getIndicatorMonthlyYTM05", {
      co_id: co_id,
      month: month,
      subject_ids: sid ? sid.subject_ids : [],
      year: year,
    });
    // console.log(resp)
    if (resp.code === 200) {
      handelResp(resp).then((data) => {
        if (!data.subjectReportData05s.length) return;
        setChart(data.subjectReportData05s);
      });
    }
  };

  const setChart = (datas) => {
    // console.log(datas)

    const eDatas = [];
    const aDatas = [];
    const pData = [];
    const nums = [];
    const isQuter = monthMapping[month]
    const gap = isQuter ? isQuter[1] - isQuter[0] : parseInt(month)
    const mt = store.a ? store.a.month : new Date().getMonth();
    const yr = store.a ? store.a.year : new Date().getFullYear();
    const monthLen = parseInt(year) < parseInt(yr) ? 12 : parseInt(mt)
    Array(monthLen)
      .fill(0)
      .forEach((val, index) => {
        // const num = (index + 1).toString();
        // nums.push(num);
        // eDatas.push(datas["estimate_value_m" + handleMonth(num)]);
        // pData.push(datas["past1_value_m" + handleMonth(num)]);
        // const month = store.a ? store.a.month : new Date().getMonth();
        const offset = isQuter ? isQuter[0] : 0
        const num = (index + 1 + offset).toString();
        nums.push(num);
        // if (month > index) {
        //   aDatas.push(datas["achievement_value_m" + handleMonth(num)]);
        // }
      });
    const option = {
      legend: {
        //   data: ['预算', '实绩'],
        bottom: "1%",
      },
      title: {
        text: sid?.tag_name,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // axis: 'y'
        },
        // formatter: '{a}月'
        // valueFormatter: (value) => value.toFixed(2),
      },
      xAxis: {
        type: "category",
        data: [],
      },
      grid: {
        left: "1%",
        top: "15%",
        right: "2%",
        bottom: "30%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          // formatter: "{value} 万",
        },
      },
      series: datas.map(item=>{
        
        return {
          name: item.subject_name,
          type:  chartType ? chartType : "line",
          smooth: true,
          data: Array(monthLen)
                .fill(0)
                .map((val, index) => {
                  const offset = isQuter ? isQuter[0] : 0
                  const num = (index + 1 + offset).toString();
                  // const num = (index + 1).toString();
                  // nums.push(num);
                  // eDatas.push(datas["estimate_value_m" + handleMonth(num)]);
                  // pData.push(datas["past1_value_m" + handleMonth(num)]);
                  // const month = store.a ? store.a.month : new Date().getMonth();
                  // if (month > index) {
                  //   aDatas.push(datas["achievement_value_m" + handleMonth(num)]);
                  // }
                  return item["achievement_value_m" + handleMonth(num)]
                })
        }
      }),
    };
    // console.log(nums)
    option.xAxis.data = nums;
    // console.log('option', option)
    // option.yAxis.axisLabel.formatter = "{value}";
    // option.series[0].data = pData;
    // option.series[1].data = eDatas;
    // option.series[2].data = aDatas;
    // option.title.text = datas.co_name + datas.subject_name;
    setOpt(option);
  };

  useEffect(() => {
    if (!ref.current) return;
    if (!opt) return;
    const chart = echarts.init(ref.current);
    chart.setOption(opt);
    return () => {
      chart.clear();
      chart.dispose();
    };
  }, [opt, month, co_id, sid]);

  useEffect(() => {
    // console.log(year, month, co_id, sid)
    // console.log(year & month & co_id & sid)
    if (year && month && co_id && sid) {
      getData();
    }
  }, [year, month, co_id, sid]);

  return <div className="rg-chart"  style={{height: height ? height : null}} ref={ref}></div>;
}

export default { Comp: MonthChart, props };
