import React, { useState, useEffect, useRef } from "react";
import { Popover, Spin, Statistic, Table, message } from "antd";
import http from "../../utils/http";
import { EyeOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { displayNum } from "../../utils";
import TipChart from "./TipChart";
import html2canvas from "html2canvas";
import { MCanvas } from "mcanvas";
import { useStore } from "./store";

const colors = ["#F7B500", "#619E21", "#3B7CFF"];

export const getContent = (tags, data, isW) => {
  const columns = [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>月份</span>,
      key: "month",
      render: (val, row, index) => (
        <span style={{ whiteSpace: "nowrap" }}>{`${index + 1}月:`}</span>
      ),
    },
  ];

  tags.forEach((val, index) => {
    columns.push({
      title: <span style={{ color: colors[index] }}>{val["title"]}</span>,
      key: val["prefix"],
      align: "right",
      render: (_, row, index) => {
        return displayNum(
          data[`${val["prefix"]}${index < 9 ? "0" : ""}${index + 1}`],
          data.unit,
          {
            fontSize: 14,
            textAlign: "right",
            color:
              data.hasMPrefix === val["prefix"] && index >= data.m
                ? "#619E21"
                : "",
          },
          isW
        );
      },
    });
  });
  return (
    <div style={{ width: 400, height: 540 }}>
      <TipChart data={data} tags={tags} colors={colors} />
      <Table
        size="small"
        rowClassName="tips-item"
        pagination={false}
        dataSource={Array(12).fill(0)}
        columns={columns}
      ></Table>
    </div>
  );
};

function Tips(props) {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const resp = await http.post("/base/selectTAchievementExt01", {
      ...props.value,
      year: props.year,
      co_id: localStorage.getItem("coId"),
    });
    // console.log(resp);
    if (resp.code === 200) {
      setDatas(resp.data.tAchievementExt01s);
      setLoading(false);
    }
  };
  const params = useParams();
  useEffect(() => {
    setDatas([]);
  }, [params]);

  const data = {};
  if (datas.length) {
    datas.forEach((val) => {
      data["a" + val.month_key] = val.achievement_subject_value;
      data["e" + val.month_key] = val.estimate_subject_value;
      data["p" + val.month_key] = val.past_subject_value;
      data["unit"] = val.unit;
    });
  }
  const get_tags = () => {
    const ts = [
      {
        title: `${parseInt(props.year) - 1}年实绩`,
        prefix: "p",
      },
      {
        title: `${props.year}年预算`,
        prefix: "e",
      },
    ];
    if (props.isA) {
      ts.push({
        title: `${parseInt(props.year)}年实绩`,
        prefix: "a",
      });
    }

    return ts;
  };

  const getContent = () => {
    const genCols = () => {
      const cols = [
        {
          title: "月份",
          key: "month",
          render: (val, row, index) => `${index + 1}月:`,
        },
        {
          title: `${parseInt(props.year) - 1}年实绩`,
          key: "past1",
          align: "right",
          render: (val, row, index) =>
            displayNum(row.past_subject_value, row.unit),
        },
        {
          title: `${props.year}年预算`,
          key: "past2",
          align: "right",
          render: (val, row, index) =>
            displayNum(row.estimate_subject_value, row.unit),
        },
      ];
      if (props.isA) {
        cols.push({
          title: `${parseInt(props.year)}年实绩`,
          key: "past3",
          align: "right",
          render: (val, row, index) =>
            displayNum(row.achievement_subject_value, row.unit),
        });
      }
      return cols;
    };

    return loading ? (
      <div
        style={{
          width: 400,
          height: 420,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    ) : (
      <div style={{ width: 400, height: 540 }}>
        <TipChart data={data} tags={get_tags()} colors={colors} />
        <Table
          size="small"
          rowClassName="tips-item"
          pagination={false}
          dataSource={datas}
          columns={genCols()}
        ></Table>
      </div>
    );
  };

  return (
    <div>
      {/* <Popover
        placement="right"
        title={props.value.subject_name}
        content={getContent()}
        mouseEnterDelay={0.8}
        onVisibleChange={() => {
          // if (!datas.length) {
          // }
          getData();
        }}
      >
        <span className="tips-text">
          <span className="tips-icon-eye" style={{ marginRight: "6px" }}>
            <EyeOutlined />
          </span>
          {props.value.subject_name}
        </span>
      </Popover> */}
      <TipsContent
        onVisibleChange={() => {
          console.log("=====");
          getData();
        }}
        title={props.value.subject_name}
        data={data}
        tags={get_tags()}
      />
    </div>
  );
}

export const TipsContent = (props) => {
  const { title, tags, data, isW, children } = props;
  const [store, setStore] = useStore();

  const copy = (e)=>{
    // console.log(e.target.parentElement.parentElement.parentElement.parentElement)
    html2canvas(e.target.parentElement.parentElement.parentElement.parentElement, { backgroundColor: null }).then(
      async (canvas) => {
        const mark = new MCanvas({
          width: canvas.width,
          height: canvas.height,
          backgroundColor: null,
        });

        const asss = [0, 1, 2, 3];
        asss.forEach((val) => {
          mark.text(store.co_name, {
            width: canvas.width,
            align: "center",
            normalStyle: {
              font: "italic bold 100px Alibaba-PuHuiTi-B ",
              color: "rgba(0,0,0, .7)",
              type: "fill",
              lineHeight: canvas.width / 3,
              wordBreak: false,
              lineWidth: 2,
              gradient: {
                type: 2, // 1: 横向渐变； 2: 纵向渐变；
                colorStop: ["rgba(117,117,117,.1)", "rgba(158,158,158,.1)"], // eg. ['red','blue'],
              },
            },
            pos: {
              x: val % 2 ? (-canvas.width / 10) * 1 : (canvas.width / 10) * 1,
              y: ((canvas.height ) / asss.length) * val,
              rotate: -20,
            },
          });
        });

        await mark.draw({
          type: "png",
        });

        const mc = new MCanvas({
          width: canvas.width-10,
          height: canvas.height,
          backgroundColor: null,
        });

        mc.background(canvas, {
          type: "crop",
          left: 10
        });
        mc.watermark(mark.ctx.canvas, {
          width: "100%",
          pos: "leftTop",
        });
        // mc.watermark(ref.current, {
        //   width: "100%",
        //   pos: "leftBottom",
        //   margin: 0,
        // });
        await mc.draw({
          type: "png",
        });

        const imgEle = new Image()
        imgEle.src = mc.ctx.canvas.toDataURL('image/png')
        // imgEle.src = canvas.toDataURL('image/png')

        document.body.appendChild(imgEle)
        imgEle.onload = function(){
          // console.log(this)
          const range = document.createRange()
          const selection = window.getSelection()
          range.selectNode(this)
          selection.removeAllRanges()
          selection.addRange(range)
          document.execCommand('copy')
          document.body.removeChild(this)
          message.success('复制成功')
        }
        // console.log(mc.canvas)
        // console.log(mc.ctx)

        // const ctx = mc.ctx
        // ctx.shadowColor = "rgb(0, 0, 0)";
        // ctx.shadowOffsetX = 0;
        // ctx.shadowOffsetY = 6;
        // ctx.shadowBlur = 10;
        // ctx.fillStyle="#ffffff";
        // ctx.fillRect(0, mark.ctx.canvas.height+21, ctx.canvas.width-20, 1);

        // document.body.appendChild(mc.ctx.canvas)

        // console.log(canvas)
        // window.pageYoffset = pageYoffset;

        // document.body.scrollTop = bscrollTop;
      }
    );
  }
  return (
    <div>
      <Popover
        // ref={ref}
        placement="right"
        trigger="hover"
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        title={<span className="hover" onClick={copy}>{title}</span>}
        content={getContent(tags, data, isW)}
        onVisibleChange={() => {
          if (props.onVisibleChange) {
            props.onVisibleChange();
          }
        }}
      >
        {children ? (
          children
        ) : (
          <span className="tips-text">
            {title}
            <span className="tips-icon-eye" style={{ marginLeft: "6px" }}>
              {/* <EyeOutlined /> */}
            </span>
          </span>
        )}
      </Popover>
    </div>
  );
};

export default Tips;
