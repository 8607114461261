import React, { useEffect, useRef } from "react";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import http from "../../../utils/http";
import { useStore } from "../store";

echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);

const option = {
  legend: {
    //   data: ['预算', '实绩'],
    //   bottom: 20
  },
  title: {
    text: "",
  },
  tooltip: {
    trigger: "axis",
    // formatter: '{a}月'
    valueFormatter: (value) => value.toFixed(2),
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  xAxis: {
    type: "category",
    data: [],
  },
  grid: {
    left: "1%",
    top: "25%",
    right: "20%",
    bottom: "10%",
    containLabel: true,
  },
  yAxis: [
    {
      name: "销量",
      type: "value",
      axisLabel: {
        formatter: "{value} 台",
      },
    },
    {
      name: "利润",
      type: "value",
      axisLabel: {
        formatter: "{value} 万",
      },
    },
  ],
  series: [
    {
      name: "利润",
      data: [],
      type: "line",
      yAxisIndex: 1,
      smooth: true,
      color: "#B8E986",
    },
    {
      name: "销量",
      data: [],
      type: "line",
      smooth: true,
      color: "#3B7CFF",
    },
  ],
};

function Customer() {
  const ref = useRef();
  const ref1 = useRef();
  const [store, _] = useStore();
  const datas = [
    {
      name: "销量",
      code: "B010",
      data: [],
    },
    {
      name: "单车毛利",
      code: "701022",
      data: [],
    },
    {
      name: "单车综合毛利",
      code: "701030",
      data: [],
    },
    {
      name: "单车费用",
      code: "701033",
      data: [],
    },
  ];

  const datasb = [
    {
      name: "售后利润",
      code: "702011",
      data: [],
    },
    {
      name: "售后台数",
      code: "B030",
      data: [],
    },
    {
      name: "维修台数",
      code: "B03010",
      data: [],
    },
    {
      name: "单车利润",
      code: "702023",
      data: [],
    },
    {
      name: "基盘用户数",
      unit: "人",
      data: [4455, 4497, 4539, 4513, 4472, 4449],
    },
  ];

  const setChart = async (title, dts, ref) => {
    const yAxis = [];
    const series = [];
    let nums = [];
    const resp = await http.put("/indi/getIndicatorMonthlyYTM", {
      co_id: store.coId,
      month: store && store.a && store.a.month,
      subject_ids: dts.filter((val) => val.code).map((val) => val.code),
      year: store && store.a ? store.a.year : new Date().getFullYear(),
    });
    const subjectEAReportDatas = resp.data.subjectEAReportDatas;
    // console.log(resp);
    for (let index = 0; index < dts.length; index++) {
      
      // console.log(subjectEAReportDatas[index]);
      const element = dts[index];
      if (!element.code) {
        yAxis.push({
          name: element.name,
          type: "value",
          //   gridIndex: index,
          offset: index ? (index-1) * 80 : 0,
          axisLabel: {
            formatter: `{value} ${element.unit}`,
          },
        });
        series.push({
          name: element.name,
          data: element.data,
          type: "line",
          yAxisIndex: index ? index : undefined,
          smooth: true,
          // color: "#3B7CFF",
        });
        continue
      }
      // const resp = await http.put("/indi/getOneIndicatorMonthlyYTM", {
      //   co_id: store.coId,
      //   month: store && store.a && store.a.month,
      //   subject_ids: [element.code],
      //   year: store && store.a ? store.a.year : new Date().getFullYear(),
      // });
      // if (resp.code === 200) {
      // datas.push(resp.data.subjectEAReportData);
      const data = [];
      nums = [];
      Array(12)
        .fill(0)
        .forEach((val, i) => {
          const num = (i + 1).toString();
          const month = store.a
            ? parseInt(store.a.month)
            : new Date().getMonth();
          if (month >= i) {
            nums.push(num);
            data.push(
              subjectEAReportDatas[index].achievement_data[
                "subject_value_ytm" + num
              ]
            );
          }
        });

      yAxis.push({
        name: subjectEAReportDatas[index].achievement_data.subject_name,
        type: "value",
        offset: index ? (index - 1) * 80 : 0,
        axisLabel: {
          formatter: `{value} ${subjectEAReportDatas[index].achievement_data.unit}`,
        },
      });
      series.push({
        name: subjectEAReportDatas[index].achievement_data.subject_name,
        data,
        type: "line",
        yAxisIndex: index ? index : undefined,
        smooth: true,
        // color: "#3B7CFF",
      });
      // }
    }
    option.xAxis.data = nums;
    option.title.text = title;
    option.yAxis = yAxis;
    option.series = series;
    // option.title.text = datas.achievement_data.subject_name
    // console.log(option);
    const chart = echarts.init(ref);
    chart.setOption(option);
  };

  useEffect(() => {
    if(store.a){
      setChart("销售", datas, ref.current);
      setChart("售后", datasb, ref1.current);
    }
    
  }, [store]);

  return (
    <div className="sale-ratio" style={{ height: "700px", width: "100%" }}>
      <div ref={ref} style={{ width: "100%", height: "320px" }}></div>
      <div ref={ref1} style={{ width: "100%", height: "320px" }}></div>
    </div>
  );
}

export default Customer;
