import React, { useState, useEffect } from "react";
import "./index.less";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import UploadExport from "../../pages/admin/uploadExport";

export default function DatePick(props) {
  const [year, setYear] = useState(props.year);
  const [month, setMonth] = useState(props.month);
  const { type } = useParams();

  useEffect(() => {
    if (props.onChange) {
      props.onChange({ year, month });
    }
  }, [year, month]);

  useEffect(() => {
    setMonth(props.month);
    setYear(props.year);
  }, [props.year, props.month]);

  useEffect(() => {
    // setMonth(1)
  }, [year]);

  return (
    <div className="date-pick">
      <h1>{props.title}</h1>
      <div
        className="date-year"
        style={
          {
            // borderBottom: month ? '1px solid #AAAAAA' : ''
          }
        }
      >
        <div
          className="left"
          onClick={() => {
            setYear(year - 1);
          }}
        >
          <DoubleLeftOutlined />
        </div>
        <div className="year-item">{year}年</div>
        <div
          className="right"
          style={{ color: year < new Date().getFullYear() ? "" : "#999999" }}
          onClick={() => {
            if (!(year < new Date().getFullYear())) return;
            setYear(year + 1);
          }}
        >
          <DoubleRightOutlined />
        </div>
      </div>
      {month && (
        <div className="date-month">
          {Array(12)
            .fill(0)
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`month-item ${
                    index + 1 === month ? "month-item-active" : ""
                  }`}
                  style={{
                    color:
                      index + 1 > props.month &&
                      new Date().getFullYear() === year
                        ? "#999999"
                        : "",
                  }}
                  onClick={() => {
                    if (
                      index + 1 > props.month &&
                      new Date().getFullYear() === year
                    )
                      return;
                    setMonth(index + 1);
                  }}
                >
                  {index + 1}
                </div>
              );
            })}
        </div>
      )}
      {!props.canNotShowUp && (
        <div className="btn">
          <UploadExport type={type} year={year} month={month} />
        </div>
      )}
      {!month && props.canNotShowUp && (
          <div className="date-month" style={{ visibility: "hidden" }}>
            {Array(12)
              .fill(0)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`month-item ${
                      index + 1 === month ? "month-item-active" : ""
                    }`}
                    style={{
                      color:
                        index + 1 > props.month &&
                        new Date().getFullYear() === year
                          ? "#999999"
                          : "",
                    }}
                    onClick={() => {
                      if (
                        index + 1 > props.month &&
                        new Date().getFullYear() === year
                      )
                        return;
                      setMonth(index + 1);
                    }}
                  >
                    {index + 1}
                  </div>
                );
              })}
          </div>
      )}
    </div>
  );
}
