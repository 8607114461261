import React, { useState, useEffect } from "react";
import { Select } from "antd";
import http from "../../../utils/http";
import { useStore } from "../store";
import Tops from "./Tops";

function TopProfitSelect() {
  const levels = [
    {
      name: "一级毛利",
      value: 1,
    },
    {
      name: "二级毛利",
      value: 2,
    },
    // {
    //     name: '三级毛利',
    //     value: 1
    // }
  ];

  const [current, setCurrent] = useState(levels[0]);
  const [store, _] = useStore();
  const [data, setData] = useState([]);
  const getData = async () => {
    const resp = await http.get("/base/getCarSalesRank", {
      co_id: store.coId,
      month: store && store.a && store.a.month,
      year: store && store.a ? store.a.year : new Date().getFullYear(),
      model: "YTM",
      level: current.value,
    });
    // console.log(resp);
    if (resp.code === 200) {
      setData(
        resp.data.ranks.map((val) => ({
          name: val.subject_name,
          value: val.subject_value,
        })).sort((a, b)=>a.value-b.value)
      );
    }
  };

  useEffect(() => {
    if (store.a) {
        getData();
      }
  }, [store]);

  useEffect(() => {
    if (store.a) {
      getData();
    }
  }, [current]);

  

  return (
    <div className="top-chart" style={{ left: "67%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h2>{current.name}</h2>
        <Select
          style={{ width: "100px" }}
          value={current.value}
          onChange={(e) => {
            setCurrent(levels.filter((val) => val.value === e)[0]);
          }}
        >
          {levels.map((val) => {
            return (
              <Select.Option key={val.value} value={val.value}>
                {val.name}
              </Select.Option>
            );
          })}
        </Select>
      </div>

      <Tops data={data} isW={true} />
    </div>
  );
}

export default TopProfitSelect;
