import React from 'react';
import './style.less'
const props = [
  {
    label: "公司名",
    type: "input",
    name: "co_name",
    isSystem: true
  },
  {
    label: "年",
    type: "input",
    name: "year",
    isSystem: true
  },
  {
    label: "月",
    type: "input",
    name: "month",
    isSystem: true
  },
  {
    label: "名称",
    type: "input",
    name: "name",
    isSystem: true
  },
]

function BigTitle(props) {
  let { co_name, year, month, name } = props
  if(!co_name){
    co_name = '公司名'
  }
  if(!year){
    year = 'xx'
  }
  if(!month){
    month = 'xx'
  }
  if(!name){
    name = 'xx'
  }
  return (
    <div className='rg-big-title'>
      {`${co_name}${year}年${month}月${name}报告`}
    </div>
  );
}

export default {Comp:BigTitle, props};
