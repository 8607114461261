import React, { useEffect, useState, useRef } from "react";
import http from "../../utils/http";
import { useParams } from "react-router-dom";
import { Statistic, Card } from "antd";
import { useStore } from "./store";

import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);

const dataZoom = [
  {
    type: "inside",
    start: 90,
    end: 100,
  },
  {
    start: 90,
    end: 100,
    handleIcon:
      "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
    handleSize: "80%",
    handleStyle: {
      color: "#fff",
      shadowBlur: 3,
      shadowColor: "rgba(0, 0, 0, 0.6)",
      shadowOffsetX: 2,
      shadowOffsetY: 2,
    },
  },
];

export const option = {
  title: {
    text: "",
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    top: "5%",
    left: "center",
  },
  series: [
    {
      name: "Access From",
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: "40",
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 1048, name: "Search Engine" },
        { value: 735, name: "Direct" },
        { value: 580, name: "Email" },
        { value: 484, name: "Union Ads" },
        { value: 300, name: "Video Ads" },
      ],
    },
  ],
};

export default () => {
  const params = useParams();
  const [datas, setDatas] = useState([]);
  const [monthDatas, setMonthDatas] = useState([]);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [store, setStore] = useStore();
  // const year = (new Date()).getFullYear()
  // const month = (new Date()).getMonth() + 1

  const setChart = () => {
    if (!ref.current || !ref2.current) return;
    const chart = echarts.init(ref.current);
    const chart2 = echarts.init(ref2.current);

    // option.title.text = params.title
    option.series[0].data = datas;
    chart.setOption(option);
    option.series[0].data = monthDatas;
    chart2.setOption(option);
  };

  const getFlowInfo = async () => {
    const getData = async (month) => {
      const resp = await http.get("/base/selectBudgetStatusGroup", {
        co_id: localStorage.getItem("coId"),
        flow_type: month ? "A" : "E",
        // year,
        // month: month ? (month < 9 ? '0' : '') + month.toString() : null
      });
      if (resp.code === 200) {
        const dts = [];
        resp.data.statusGroups.forEach((val) => {
          dts.push({ name: val.status_name, value: val.status_count });
        });
        month ? setMonthDatas(dts) : setDatas(dts);
      }
    };
    await getData();
    await getData("-");
  };

  const getStatusInfo = async () => {
    const getData = async (month) => {
      const resp = await http.get("/base/selectPageStatusGroup", {
        co_id: localStorage.getItem("coId"),
        flow_type: month ? "A" : "E",
        // year: (new Date()).getFullYear(),
        // month: month ? (month < 9 ? '0' : '') + month.toString() : null
      });
      if (resp.code === 200) {
        const dts = [];
        resp.data.statusGroups.forEach((val) => {
          dts.push({ name: val.status_name, value: val.status_count });
        });
        month ? setMonthDatas(dts) : setDatas(dts);
      }
    };
    await getData();
    await getData("-");
  };
  const getDatas = async () => {
    if (localStorage.getItem("co_type") === "HQ") {
      await getFlowInfo();
    } else {
      await getStatusInfo();
    }
  };

  useEffect(setChart, [datas, monthDatas]);

  useEffect(() => {
    getDatas();
  }, [params]);

  return (
    <div
      className="manage"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div className="nums" style={{ width: "15%" }}>
        <h1 style={{ fontSize: 20 }}>{store.e && store.e.year}年预算</h1>
        {datas.map((val, index) => {
          return (
            <Card key={index} style={{ marginBottom: "20px" }}>
              <Statistic
                title={<h1>{val.name}</h1>}
                value={parseInt(val.value)}
              />
            </Card>
          );
        })}
      </div>
      <div
        className="chart"
        ref={ref}
        style={{ width: "30%", height: 400, margin: "" }}
      ></div>
      <div className="nums" style={{ width: "15%" }}>
        <h1 style={{ fontSize: 20 }}>
          {store.a && store.a.year}年{store.a && store.a.month}月实绩
        </h1>
        {monthDatas.map((val, index) => {
          return (
            <Card key={index} style={{ marginBottom: "20px" }}>
              <Statistic
                title={<h1>{val.name}</h1>}
                value={parseInt(val.value)}
              />
            </Card>
          );
        })}
      </div>
      <div
        className="chart"
        ref={ref2}
        style={{ width: "30%", height: 400, margin: "" }}
      ></div>
    </div>
  );
};
