/* eslint-disable react/no-unknown-property */
import EventCover from "../eventCover";
import "./index.less";
// import { DatePicker, Input, Select } from "antd";
import { mapping } from '../index'

export default function renderFormItem(
  current,
  { renderChild, parent = null }
) {
  return (
    current &&
    current.map((item, idx) => {
      let comp = null;
      const label = (
        <>
          <span>{item.formData?.formName || item.compName}</span>
          <span className="form-sandbox__payground__idtext">{item.itemId}</span>
        </>
      );
      const placeHolder = `${item.formData?.placeHolder || item.compName}`;
      const eventData = { idx, parent, current };
      if (item.compType === 'wrap') {
        comp = (
          <EventCover eventData={eventData} noMask={true}>
            {/* <p>{label}</p> */}
            {renderChild && renderChild(item)}
          </EventCover>
        );
      } else {
        const mapComp = mapping[item.compType]
        // console.log('mapComp', mapComp, item.compType)
        comp = (<EventCover eventData={eventData} noMask={true}>
          {mapComp && <mapComp.Comp {...item.formData} />}
        </EventCover>)
      }
      // switch (item.compType) {
      //   case 'wrap':
      //     comp = (
      //       <EventCover eventData={eventData} noMask={true}>
      //         <p>{label}</p>
      //         {renderChild && renderChild(item)}
      //       </EventCover>
      //     );
      //     break;
      //   case 'date-picker':
      //     comp = (
      //       <EventCover eventData={eventData}>
      //         {/* <FormItem labelAlign='top' label={label}>
      //           <DatePicker mode='date' placeholder={placeHolder} />
      //         </FormItem> */}
      //         <DatePicker mode='date' placeholder={placeHolder} />
      //       </EventCover>
      //     );
      //     break;
      //   case 'selector':
      //     comp = (
      //       <EventCover eventData={eventData}>
      //         {/* <FormItem labelAlign='top' label={label}>
      //           <Select options={[{ label: '选项一', value: '1' }]} placeholder={placeHolder} />
      //         </FormItem> */}
      //         <Select  placeholder={placeHolder} />
      //       </EventCover>
      //     );
      //     break;
      //   default:
      //     comp = (
      //       <EventCover eventData={eventData}>
      //         {/* <FormItem labelAlign='top' label={label}>
      //           <Input readonly placeholder={placeHolder} />
      //         </FormItem> */}
      //         <Input  placeholder={placeHolder} />
      //       </EventCover>
      //     );
      // }
      return (
        <div
          className={`form-sandbox__payground__box`}
          key={item.itemId}
          comp-type={item.compType}
        >
          {comp}
        </div>
      );
    })
  );
}
