import React, { useState, useEffect } from "react";
import http from "../../../utils/http";
import { useStore } from "../store";
import Tops from './Tops'

function TopSale() {
  const [data, setData] = useState([]);
  const [store, _] = useStore();
  const getData = async () => {
    const resp = await http.get("base/getRank", {
      co_id: store.coId,
      month: store && store.a && store.a.month,
      subject_id: "B010",
      year: store && store.a ? store.a.year : new Date().getFullYear(),
    });
    // console.log(resp)
    if(resp.code === 200){
        setData(resp.data.ranks.map(val=>({name: val.subject_name, value: val.subject_value})).sort((a,b)=>a.value-b.value))
    }
  };
  useEffect(()=>{
      if(store.a){
        getData()
      }
  }, [store])

  return <div className="top-chart" style={{left: '33.5%'}}>
      <h2>单车销售排名</h2>
      <Tops data={data} />
  </div>;
}

export default TopSale;
