import React, { useState, useEffect } from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Button,
  Table,
  DatePicker,
  Select,
  Modal,
  InputNumber,
} from "antd";
import "./style.less";
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import TopImg from "../../../assets/》@2x.png";
import BottomImg from "../../../assets/》备份@2x.png";
import http from "../../../utils/http";
import { useStore } from "../../content/store";
import { handelResp } from "../../../utils";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import lodash from "lodash";
import moment from "moment";

const { Option } = Select;

const parseF = v =>{
  return parseFloat(v.toFixed(2))
}
function ComprePain(props) {
  const [isMore, setIsMore] = useState(true);
  const [services, setServices] = useState([]);
  const [cache, setCache] = useState();
  const [form] = Form.useForm();
  const tables = [
    [
      "服务",
      {
        n: "service_id",
        c: (
          <Select>
            {services.map((val) => {
              return <Option key={val.id}>{val.service_name}</Option>;
            })}
          </Select>
        ),
      },
      "",
      "销售单台综合服务毛利(含税)",
      {
        n: "service_profit_penetration",
        c: <InputNumber disabled />,
      },
    ],
    ["", "含税", "除税", "税率", ""],
    [
      "单台收入",
      {
        n: "sales_revenue",
        c: <InputNumber />,
      },
      {
        n: "sales_revenue_x_tax",
        c: <InputNumber disabled />,
      },
      {
        n: "tax_rate",
        c: <InputNumber />,
      },
      "",
    ],
    [
      "单台成本",
      {
        n: "sales_cost",
        c: <InputNumber />,
      },
      {
        n: "sales_cost_x_tax",
        c: <InputNumber disabled />,
      },
      "",
      "",
    ],
    [
      "单台毛利",
      {
        n: "sales_profit",
        c: <InputNumber disabled />,
      },
      {
        n: "sales_profit_x_tax",
        c: <InputNumber disabled />,
      },
      "",
      "",
    ],
    [
      "渗透率",
      {
        n: "penetration_rate",
        c: <InputNumber />,
      },
      "",
      "",
      "",
    ],
    [
      "毛利率",
      {
        n: "sales_profit_rate",
        c: <InputNumber disabled />,
      },
      "",
      "",
      "",
    ],
  ];

  const save = async () => {
    // console.log("-=-=-=-=-=", cache);
    if (!cache) return;
    const urls = cache.id ? "update" : "create";
    console.log("SAVE service", cache);
    const resp = await (cache.id
      ? http.q2().put("/tServicePlan/" + urls, cache)
      : http.q2().post("/tServicePlan/" + urls, cache));
    handelResp(resp).then((data) => {
      setCache();
    });
  };

  useEffect(() => {
    save();
  }, [props.car, props.saleType]);

  const calc = (values) => {
    if (values.sales_revenue && values.tax_rate) {
      form.setFieldsValue({
        sales_revenue_x_tax: parseF(values.sales_revenue * (1 - values.tax_rate)),
      });
    }
    values = form.getFieldsValue();
    if (values.sales_cost && values.tax_rate) {
      form.setFieldsValue({
        sales_cost_x_tax: parseF(values.sales_cost * values.tax_rate),
      });
    }
    values = form.getFieldsValue();
    if (values.sales_cost && values.sales_revenue) {
      form.setFieldsValue({
        sales_profit: parseF(values.sales_revenue - values.sales_cost),
      });
    }
    values = form.getFieldsValue();
    if (values.sales_profit && values.tax_rate) {
      form.setFieldsValue({
        sales_profit_x_tax: parseF(values.sales_profit * values.tax_rate),
      });
    }
    values = form.getFieldsValue();
    if (values.penetration_rate && values.sales_profit) {
      form.setFieldsValue({
        service_profit_penetration:
        parseF(values.sales_profit * values.penetration_rate),
      });
    }
    values = form.getFieldsValue();
    if (values.sales_revenue && values.sales_profit) {
      form.setFieldsValue({
        sales_profit_rate: parseF(values.sales_profit / values.sales_revenue),
      });
    }
    if (props.onChange) {
      props.onChange(form.getFieldsValue());
    }
  };

  const mapping = {
    0: 3,
    1: 5,
    2: 5,
    3: 6,
    4: 5,
  };
  const getServices = async () => {
    const resp = await http.q2().get("/plan/selectTCorpServiceExt01");
    handelResp(resp).then((data) => {
      setServices(data.tCorpServiceExt01s);
    });
  };
  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ ...props.value });
    calc(form.getFieldsValue());
  }, [props.value]);

  return (
    <div className="compre-pain">
      <Form
        name={lodash.uniqueId()}
        form={form}
        onValuesChange={(changedValues, allValues) => {
          calc(allValues);
          setCache({
            ...props.value,
            ...allValues,
            sales_plan_id: props.sales_plan_id,
          });
        }}
      >
        {tables.map((val, index) => {
          if (!isMore && index > 0) {
            return;
          }
          const isCenter =
            val.filter((val) => typeof val === "string").length === 5;
          return (
            <div className="line" key={index}>
              <Row gutter={24} align="middle">
                {val.map((item, i) => {
                  return (
                    <Col span={mapping[i]} key={i}>
                      {typeof item === "string" ? (
                        <div
                          className="label"
                          style={{ textAlign: isCenter ? "center" : "" }}
                        >
                          {item}
                        </div>
                      ) : (
                        <Form.Item name={item.n} style={{ margin: 0 }}>
                          {item.c}
                        </Form.Item>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
        <div className="is-expend" onClick={() => setIsMore(!isMore)}>
          <img src={!isMore ? BottomImg : TopImg} alt="" />
        </div>
      </Form>
    </div>
  );
}

function SalesPain(props) {
  const { plan_id, car } = props;
  const [saleType, setSaleType] = useState("01");
  const [service, setService] = useState([]);
  const [isShowService, setShowService] = useState(false);
  // const [store, _] = useStore();
  const [state, setState] = useState({});
  const [cache, setCache] = useState();
  const [isShowDetail, setShowDetail] = useState(false);
  const [detailForm] = Form.useForm();
  const [calcForm] = Form.useForm();

  const calcName = (t) => {
    return `sale-type-tab ${saleType === t ? "active" : null}`;
  };

  const getService = async () => {
    if (!state.id) return;
    const resp = await http.q2().get("/tServicePlan/select", {
      sales_plan_id: state.id,
    });
    handelResp(resp).then((data) => {
      const plans = data.tServicePlans;
      setService(plans ? plans : []);
    });
  };

  const getData = async () => {
    const resp = await http.q2().get("/tSalesPlan/select", {
      op_id: plan_id,
      car_id: car.model_id,
      distri_channel: saleType,
    });
    handelResp(resp).then((data) => {
      let list = {};
      if (data.tSalesPlans) {
        data.tSalesPlans.forEach((val) => {
          list = val;
        });
        // console.log(list);
      }
      setState(list);
    });
  };
  useEffect(() => {
    getData();
  }, [plan_id, car, saleType]);

  useEffect(() => {
    getService();
  }, [state]);

  useEffect(() => {
    calcForm.resetFields();
    detailForm.resetFields();
    if (state) {
      calcForm.setFieldsValue({ ...state });
      detailForm.setFieldsValue({ ...state });
      calc({ ...state });
    }
  }, [state, saleType]);

  const save = async () => {
    setShowService(false);
    if (!cache) return;
    const urls = cache.id ? "update" : "create";
    console.log("SAVE", cache);
    const resp = await (cache.id
      ? http.q2().put("/tSalesPlan/" + urls, { ...state, ...cache })
      : http.q2().post("/tSalesPlan/" + urls, cache));
    handelResp(resp).then((data) => {
      setCache();
    });
  };

  useEffect(() => {
    save();
  }, [saleType]);

  useEffect(() => {
    save();
  }, [car]);

  const sync = (values) => {
    values.distri_channel = saleType;
    values.car_id = car.model_id;
    values.op_id = plan_id;
    values.id = state.id;
    if (cache) {
      setCache({ ...cache, ...values });
    } else {
      setCache(values);
    }
  };

  useEffect(() => {
    if (cache) {
      calc({...state, ...cache});
    }
  }, [cache]);

  const calc = (values) => {
    
    let total = 0;
    Array(12)
      .fill(0)
      .forEach((val, key) => {
        const name = `sales_volume_${key > 9 ? key : "0" + key}`;
        const v = values[name];
        total += v ? v : 0;
      });
    calcForm.setFieldsValue({ total_car: total });

    if (values.sales_cost && values.tax_rate) {
      calcForm.setFieldsValue({
        sales_cost_x_tax:
        parseF(values.sales_cost - values.sales_cost * values.tax_rate),
      });
    }
    values = calcForm.getFieldsValue();
    if (values.sales_revenue && values.tax_rate) {
      calcForm.setFieldsValue({
        sales_revenue_x_tax:
        parseF(values.sales_revenue - (values.sales_revenue * values.tax_rate)),
      });
    }
    values = calcForm.getFieldsValue();
    if (values.sales_cost && values.sales_revenue) {
      calcForm.setFieldsValue({
        "1_profit": values.sales_revenue - values.sales_cost,
      });
    }
    values = calcForm.getFieldsValue();
    if (values["1_profit"] && values.tax_rate) {
      calcForm.setFieldsValue({
        "1_profit_x_tax":
        parseF(values["1_profit"] - values["1_profit"] * values.tax_rate),
      });
    }
    values = calcForm.getFieldsValue();
    if (values["1_profit_x_tax"] && values.total_car) {
      calcForm.setFieldsValue({
        "1_profit_total": parseF(values["1_profit_x_tax"] * parseInt(values.total_car)),
      });
    }
    values = calcForm.getFieldsValue();
    if (values.sales_revenue && values["1_profit"]) {
      calcForm.setFieldsValue({
        "1_profit_rate": parseF(values["1_profit"] / values.sales_revenue),
      });
    }
    values = calcForm.getFieldsValue();
    if (values.sales_rebate && values["1_profit"]) {
      calcForm.setFieldsValue({
        "2_profit": values.sales_rebate + values["1_profit"],
      });
    }
    values = calcForm.getFieldsValue();
    if (values["2_profit"] && values.total_car) {
      calcForm.setFieldsValue({
        "2_profit_total": values["2_profit"] * parseInt(values.total_car),
      });
    }

    values = calcForm.getFieldsValue();
    if (values["2_profit"] && values.sales_revenue) {
      calcForm.setFieldsValue({
        "2_profit_rate": parseF(values["2_profit"] / values.sales_revenue),
      });
    }

    values = calcForm.getFieldsValue();

    let service_profit = 0;
    let service_profit_x_tax = 0;
    service.forEach((val) => {
      const service_profit_penetration = val.service_profit_penetration
        ? val.service_profit_penetration
        : 0;
      service_profit += service_profit_penetration;
      if (val.tax_rate) {
        const sales_profit_x_tax =
          service_profit_penetration * (1 - val.tax_rate);
        service_profit_x_tax += sales_profit_x_tax;
      }
    });

    calcForm.setFieldsValue({ service_profit, service_profit_x_tax: parseF(service_profit_x_tax) });
    values = calcForm.getFieldsValue();
    if (values["2_profit"] && values.service_profit) {
      calcForm.setFieldsValue({
        "3_profit": parseF(values["2_profit"] + values.service_profit),
      });
    }

    if (values["2_profit"] && values.service_profit_x_tax) {
      calcForm.setFieldsValue({
        "3_profit_x_tax": parseF(values["2_profit"] + values.service_profit_x_tax),
      });
    }

    values = calcForm.getFieldsValue();
    if (values["3_profit_x_tax"] && values.total_car) {
      calcForm.setFieldsValue({
        "3_profit_total": parseF(values["3_profit_x_tax"] * parseInt(values.total_car)),
      });
    }

    values = calcForm.getFieldsValue();
    if (values["3_profit"] && values.sales_revenue) {
      calcForm.setFieldsValue({
        "3_profit_rate": parseF(values["3_profit"] / values.sales_revenue),
      });
    }

    if (!values["3_profit_total"]) {
      values["3_profit_total"] = values["2_profit_total"];
    }

    values = calcForm.getFieldsValue();
    if (values["single_vc"] && values.total_car) {
      calcForm.setFieldsValue({
        single_vc_total: values["single_vc"] * parseInt(values.total_car),
      });
    }

    values = calcForm.getFieldsValue();
    if (values["3_profit_total"] && values.single_vc_total) {
      calcForm.setFieldsValue({
        "4_profit_total": parseF(values["3_profit_total"] - values.single_vc_total),
      });
    }
  };

  const deletes = async () => {
    const resp = await http.q2().get("/tSalesPlan/select");
    handelResp(resp).then((data) => {
      if (data.tSalesPlans) {
        data.tSalesPlans.forEach((val) => {
          http.q2().delete("/tSalesPlan/delete?id=" + val.id);
        });
        // console.log(list);
      }
    });
  };

  // window.de = deletes;

  const tables = [
    [
      "车型",
      car.model_name ? car.model_name : "",
      "",
      "台数（年）",
      {
        n: "total_car",
        c: (
          <Input
            disabled
            suffix={
              <EditOutlined
                className="hover"
                onClick={() => setShowDetail(true)}
              />
            }
          />
        ),
      },
    ],
    ["", "含税", "除税", "税率", ""],
    [
      "单台平均成本",
      { n: "sales_cost", c: <InputNumber /> },
      { n: "sales_cost_x_tax", c: <InputNumber disabled /> },
      { n: "tax_rate", c: <InputNumber /> },
      "",
    ],
    [
      "单台平均售价",
      { n: "sales_revenue", c: <InputNumber /> },
      { n: "sales_revenue_x_tax", c: <InputNumber disabled /> },
      "",
      "",
    ],
    [
      "单台一级毛利",
      { n: "1_profit", c: <InputNumber disabled /> },
      { n: "1_profit_x_tax", c: <InputNumber disabled /> },
      "一级毛利合计",
      { n: "1_profit_total", c: <InputNumber disabled /> },
    ],
    [
      "一级毛利率",
      { n: "1_profit_rate", c: <InputNumber disabled /> },
      "",
      "",
      "",
    ],
    ["", "", "", "", ""],
    ["单台返利", { n: "sales_rebate", c: <InputNumber /> }, "", "", ""],
    [
      "单台二级毛利",
      { n: "2_profit", c: <InputNumber disabled /> },
      "",
      "二级毛利合计",
      { n: "2_profit_total", c: <InputNumber disabled /> },
    ],
    [
      "二级毛利率",
      { n: "2_profit_rate", c: <InputNumber disabled /> },
      "",
      "",
      "",
    ],
    ["", "", "", "", ""],
    [
      "单台综合服务毛利",
      { n: "service_profit", c: <InputNumber disabled /> },
      { n: "service_profit_x_tax", c: <InputNumber disabled /> },
      "",
      "",
    ],
    [
      "单台三级毛利",
      { n: "3_profit", c: <InputNumber disabled /> },
      { n: "3_profit_x_tax", c: <InputNumber disabled /> },
      "三级毛利合计",
      { n: "3_profit_total", c: <InputNumber disabled /> },
    ],
    [
      "三级毛利率",
      { n: "3_profit_rate", c: <InputNumber disabled /> },
      "",
      "",
      "",
    ],
    ["", "", "", "", ""],
    ["单车变动费用", { n: "single_vc", c: <InputNumber /> }, "", "", ""],
    [
      "变动费用",
      { n: "single_vc_total", c: <InputNumber disabled /> },
      "",
      "四级毛利合计",
      { n: "4_profit_total", c: <InputNumber disabled /> },
    ],
  ];
  const mapping = {
    0: 4,
    1: 5,
    2: 5,
    3: 5,
    4: 5,
  };
  return (
    <div className="sale-pain">
      <div className="sale-type">
        <div className={calcName("01")} onClick={() => setSaleType("01")}>
          零售
        </div>
        <div className={calcName("02")} onClick={() => setSaleType("02")}>
          二网
        </div>
        <div className={calcName("03")} onClick={() => setSaleType("03")}>
          大客户
        </div>
      </div>
      <div className="sale-content">
        <Form
          name="calc"
          form={calcForm}
          onValuesChange={(changedValues, allValues) => {
            sync(allValues);
          }}
        >
          {tables.map((val, index) => {
            const isCenter =
              val.filter((val) => typeof val === "string").length === 5;
            return (
              <div className="line" key={index}>
                <Row gutter={24} align="middle">
                  {val.map((item, i) => {
                    return (
                      <Col span={mapping[i]} key={i}>
                        {typeof item === "string" ? (
                          <div
                            className="label"
                            style={{ textAlign: isCenter ? "center" : "" }}
                          >
                            {item}
                          </div>
                        ) : (
                          <Form.Item name={item.n} style={{ margin: 0 }}>
                            {item.c}
                          </Form.Item>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              </div>
            );
          })}
        </Form>
        {!isShowService && (
          <div
            className="expend-btn"
            onClick={async () => {
              if (!state.id) {
                const resp = await http.q2().post("/tSalesPlan/create", {
                  distri_channel: saleType,
                  car_id: car.model_id,
                  op_id: plan_id,
                });
                handelResp(resp).then((data) => {
                  getData();
                  setShowService(true);
                });
              } else {
                getData();
                setShowService(true);
              }
            }}
          >
            综合业务展开
          </div>
        )}
        {(
          <div style={{display: isShowService ? '': 'none'}}>
            {service.map((val, index) => {
              return (
                <ComprePain
                  key={index}
                  value={val}
                  sales_plan_id={state.id}
                  car={car}
                  saleType={saleType}
                  onChange={(values) => {
                    Object.keys(values).forEach((key) => {
                      val[key] = values[key];
                    });
                    calc({ ...state, ...cache });
                  }}
                />
              );
            })}
            <div className="add-item">
              <div
                className="add-item-btn"
                onClick={() => {
                  service.push({});
                  setService([...service]);
                }}
              >
                <PlusOutlined />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        title="台数"
        visible={isShowDetail}
        onCancel={() => setShowDetail(false)}
        onOk={() => {
          const values = detailForm.getFieldsValue();
          sync(values);
          setShowDetail(false);
        }}
      >
        <Form
          name="detail"
          size="small"
          form={detailForm}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
        >
          {Array(12)
            .fill(0)
            .map((val, key) => {
              key += 1;
              return (
                <Form.Item
                  key={key}
                  label={`${key.toString()}月销量`}
                  style={{ marginBottom: "10px" }}
                  name={`sales_volume_${key > 9 ? key : "0" + key}`}
                >
                  <InputNumber size="small" />
                </Form.Item>
              );
            })}
        </Form>
      </Modal>
    </div>
  );
}

function SalesPlan() {
  const [form] = Form.useForm();

  const [currentItem, setCurrentItem] = useState();
  const { plan_id } = useParams();
  const [store, _] = useStore();
  const [plan, setPlan] = useState()
  const [cars, setCars] = useState([]);
  const getCars = async () => {
    const resp = await http.q2().get("/plan/selectTCorpModelExt01");
    handelResp(resp).then((data) => {
      setCars(data.tCorpModelExt01s);
    });
  };
  const getPlan = async ()=>{
    const resp = await http.q2().get("/tOperatingPlan/selectByPK",{id: plan_id})
    handelResp(resp).then(data=>{
      setPlan(data.tOperatingPlan)
      const values = {...data.tOperatingPlan}
      if(values.year){
        values.year = moment(values.year)
      }
      form.setFieldsValue(values)
    })
  }
  useEffect(()=>{
    getPlan()
  }, [])

  useEffect(() => {
    if (store.coId) {
      getCars();
    }
  }, [store]);

  const updatePlan = lodash.debounce(async (val)=>{
    if(!plan)return
    const data = {...plan, ...val}
    const resp = await http.q2().put("/tOperatingPlan/update", data);
    handelResp(resp).then((data) => {
      getPlan()
    }); 
  }, 1000)

  return (
    <div className="plan">
      <div className="header-input">
        <Form
          form={form}
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onValuesChange={(v, allV) =>{
            if(allV.year){
              allV.year = allV.year.year().toString()
            }
            updatePlan(allV)
          }}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item label="年度" name={'year'}>
                <DatePicker picker="year" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="经营计划名" name='op_name'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="年固定费用" name='fixed_cost'>
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="body-container">
        <div className="list-container">
          {cars.map((val, index) => {
            return (
              <div
                key={index}
                className={`list-item ${
                  currentItem && val.model_id === currentItem.model_id
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setCurrentItem(val);
                }}
              >
                <span>{val.model_name}</span>
              </div>
            );
          })}
        </div>
        <div className="content-container">
          {currentItem && <SalesPain plan_id={plan_id} car={currentItem} />}
        </div>
      </div>
    </div>
  );
}

function SalesPlanList() {
  const [list, setList] = useState([]);
  const [store, _] = useStore();
  const nav = useNavigate();
  const getList = async () => {
    const resp = await http.q2().get("/tOperatingPlan/select", {
      co_id: store.coId,
      pageSize: 100,
    });
    handelResp(resp).then((data) => {
      if (data.tOperatingPlans) {
        setList(data.tOperatingPlans);
      }
    });
  };
  useEffect(() => {
    getList();
  }, []);
  const col = [
    {
      title: "年",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "经营计划名称",
      dataIndex: "op_name",
      key: "op_name",
    },
    {
      title: "固定费用",
      dataIndex: "fixed_cost",
      key: "fixed_cost",
    },
    {
      title: "更新日时",
      dataIndex: "update_time",
      key: "update_time",
      render: (val) => new Date(val.split("Z").join("")).toLocaleString(),
    },
    {
      title: "创建日时",
      dataIndex: "create_time",
      key: "create_time",
      render: (val) => new Date(val.split("Z").join("")).toLocaleString(),
    },
    {
      title: "操作",
      dataIndex: "id",
      key: "id",
      render: (val) => (
        <Button type="primary" onClick={() => nav(val)}>
          编辑
        </Button>
      ),
    },
  ];
  return (
    <div className="sales-plan-list">
      <Button
        type="primary"
        onClick={async () => {
          const resp = await http.q2().post("/tOperatingPlan/create", {
            co_id: store.coId,
            // year: store.e.year
          });
          handelResp(resp).then((data) => {
            nav(data.id);
          });
        }}
      >
        创建计划
      </Button>
      <Table columns={col} dataSource={list} />
    </div>
  );
}

function SalesPlanSub() {
  return (
    <Routes>
      <Route path=":plan_id" element={<SalesPlan />} />
      <Route path="" element={<SalesPlanList />} />
    </Routes>
  );
}

export default SalesPlanSub;
