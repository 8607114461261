import React, { useEffect, useState, useRef } from "react";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { useStore } from "../store";
import http from "../../../utils/http";

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);

const option = {
  legend: {
    //   data: ['预算', '实绩'],
    //   bottom: 20
  },
  title: {
    text: "",
  },
  tooltip: {
    trigger: "axis",
    // formatter: '{a}月'
    valueFormatter: (value) => value.toFixed(2),
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  xAxis: {
    type: "category",
    data: [],
  },
  grid: {
    left: "1%",
    top: "20%",
    right: "2%",
    bottom: "3%",
    containLabel: true,
  },
  yAxis: [
    {
      name: "销量",
      type: "value",
      axisLabel: {
        formatter: "{value} 台",
      },
    },
    {
      name: "利润",
      type: "value",
      axisLabel: {
        formatter: "{value} 万",
      },
    },
  ],
  series: [
    {
      name: "利润",
      data: [],
      type: "line",
      yAxisIndex: 1,
      smooth: true,
      color: "#3B7CFF",
    },
    {
      name: "销量",
      data: [],
      type: "line",
      smooth: true,
      color: "#B8E986",
    },
  ],
};

export const parseW = (val) => {
  // console.log(val)
  return val / 10000;
};
export default (props) => {
  const ref = useRef(null);
  const [data, setData] = useState([]);
  const [store, setStore] = useStore();
  const subjectIds = ["B010", "701014"];

  const setChart = (datas) => {
    if (!ref.current) return;
    const eDatas = [];
    const aDatas = [];
    const nums = [];
    Array(12)
      .fill(0)
      .forEach((val, index) => {
        const num = (index + 1).toString();
        const month = store.a ? parseInt(store.a.month) : new Date().getMonth();
        if (month >= index) {
          nums.push(num);
          eDatas.push(datas[0].achievement_data["subject_value_ytm" + num]);
          aDatas.push(
            parseW(datas[1].achievement_data["subject_value_ytm" + num])
          );
        }
      });
    option.xAxis.data = nums;
    option.series[1].data = eDatas;
    option.series[0].data = aDatas;
    // option.title.text = datas.achievement_data.subject_name
    const chart = echarts.init(ref.current);
    chart.setOption(option);
  };

  const getData = async () => {
    const datas = [];
    for (let index = 0; index < subjectIds.length; index++) {
      const element = subjectIds[index];
      try {
        const resp = await http.put("/indi/getOneIndicatorMonthlyYTM", {
          co_id: store.coId,
          month: store.a.month,
          subject_ids: [element],
          year: store && store.a ? store.a.year : new Date().getFullYear(),
        });
        if (resp.code === 200) {
          datas.push(resp.data.subjectEAReportData);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setData(datas);
  };

  useEffect(() => {
    // console.log(data);
    if (data.length) {
      setChart(data);
    }
  }, [data]);

  useEffect(() => {
    if (store.coId && store.a) {
      getData();
    }
  }, [store]);

  return (
    <div className="line-sale">
      <h2>各月销量+净利</h2>
      <div ref={ref} style={{ width: "100%", height: "220px" }}></div>
    </div>
  );
};
