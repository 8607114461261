import React from "react";

export const props = [
  {
    label: "图片",
    type: "pic",
    name: "src",
  },
  {
    label: "宽度",
    type: "inputNumber",
    name: "width",
  },
  {
    label: "高度",
    type: "inputNumber",
    name: "height",
  },
];

function Image(props) {
  const { src, width, height } = props;
  return (
    <div className="rg-img" style={{ width, height }}>
      {src ? <img src={src} tyle={{ width, height }} alt="" /> : "图片组件"}
    </div>
  );
}

export default { Comp: Image, props };
