import React, {useState, useEffect} from 'react';
import { useStore } from '../store'
import http from '../../../utils/http'
import { Statistic, Select } from "antd";
import Progress from "../../../component/progress";
import Ytm from './ytm'

function TotalSubject() {

  const subjectIds = ["B010", "10", "701014"];
  const colors = ["#42E0FC", "#FFCC41", "#FB745B", "#B8E986"];
  const [current, setCurrent] = useState(0)
  const [store, _] = useStore();
  const [month, setMonth] = useState(store && store.a && store.a.month);
  const [subjectsData, setSubjectData] = useState([]);

  //   console.log(store)
  const getDataSubject = async () => {
    let datas = [];
    // for (let index = 0; index < subjectIds.length; index++) {
    //   const element = subjectIds[index];
    //   try {
    //     const resp = await http.put("/indi/getOneIndicatorMonthlyYTM", {
    //       co_id: store.coId,
    //       month: month ? month : store && store.a && store.a.month,
    //       subject_ids: [element],
    //       year: store && store.a ? store.a.year : new Date().getFullYear(),
    //     });
    //     if (resp.code === 200) {
    //       datas.push(resp.data.subjectEAReportData);
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
      try {
        const resp = await http.put("/indi/getIndicatorMonthlyYTM", {
          co_id: store.coId,
          month: month ? month : store && store.a && store.a.month,
          subject_ids: subjectIds,
          year: store && store.a ? store.a.year : new Date().getFullYear(),
        });
        if (resp.code === 200) {
          // datas.push(resp.data.subjectEAReportData);
          datas = resp.data.subjectEAReportDatas;
        }
      } catch (e) {
        console.log(e);
      }
    setSubjectData(datas);
  };

  useEffect(() => {
    console.log(month);
    if (month) {
      getDataSubject();
    } else {
      setMonth(store && store.a && store.a.month);
      // getDataSubject();
    }
  }, [store]);

  useEffect(() => {
    if (month) {
      getDataSubject();
    }
  }, [month]);
  return (
    <div className="total-subject">
        <div className="total-subject-month">
          <div className="total-subject-title">
           
            <Select
              style={{ width: "70px" }}
              value={parseInt(month)}
              onChange={(e) => setMonth(e < 10 ? "0" + e.toString() : e)}
            >
              {Array(12)
                .fill(0)
                .map((_, val) => {
                  if (val >= parseInt(store.a ? store.a.month : 0)) return;
                  return (
                    <Select.Option key={val + 1} value={val + 1}>
                      {val + 1}月
                    </Select.Option>
                  );
                })}
            </Select>
            <h2 style={{marginRight: '35px'}}>销量，收入，净利</h2>
            <h2>完成率</h2>
          </div>
          {subjectsData.map((val, index) => {
            return (
              <div
                className={`subject-item-container c_${index + 1}`}
                key={index}
              >
                <div className="subject-item" onClick={()=>setCurrent(index)} >
                  <Statistic
                    value={val.achievement_data.subject_value_ytm}
                    precision={val.achievement_data.unit === "台" ? 0 : 2}
                  />
                  <div className="subject-name">
                    {val.achievement_data.subject_name}
                  </div>
                </div>
                <Progress
                  color={colors[index]}
                  a={val.achievement_data.subject_value_ytm}
                  e={val.estimate_data.subject_value_ytm }
                />
              </div>
            );
          })}
        </div>
        <Ytm data={subjectsData.length && subjectsData[current]} />
      </div>
  );
}

export default TotalSubject;
