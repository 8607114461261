import React, { useEffect, useState, useRef } from 'react'
import http from '../../utils/http'
import { Card, Statistic } from 'antd'
import './style.less'
import * as echarts from 'echarts/core';
import {
    // 系列类型的定义后缀都为 SeriesOption
    LineChart,
    BarChart,
    PieChart,
} from 'echarts/charts';
import {
    TitleComponent,
    // 组件类型的定义后缀都为 ComponentOption
    GridComponent, LegendComponent,
    ToolboxComponent, TooltipComponent,
    DataZoomComponent,
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import { useStore } from '../../pages/content/store';
import { doneRate, handelResp } from '../../utils';

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use(
    [TitleComponent, GridComponent, BarChart, DataZoomComponent,
        LineChart, CanvasRenderer, LegendComponent, PieChart,
        TooltipComponent, ToolboxComponent]
);


const option = {
    // legend: {
    //     data: ['预算', '实绩'],
    //     bottom: 20
    // },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            // axis: 'y'
        },
        // formatter: '{a}月'
        valueFormatter: (value) => value.toFixed(2)
    },
    xAxis: {
        type: 'category',
        data: []
    },
    grid: {
        left: '1%',
        top: '10%',
        right: '2%',
        bottom: '3%',
        containLabel: true
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            formatter: '{value} 万'
        }
    },
    series: [
        {
            name: '预算',
            data: [],
            type: 'line',
            smooth: true,
            color: '#B8E986',
        },
        {
            name: '实绩',
            data: [],
            type: 'line',
            smooth: true,
            color: '#3B7CFF'
        }
    ]
};

export const parseW = val => {
    // console.log(val)
    return (val / 10000)
}
export default props => {

    const { id, year, month, cost_id } = props
    const [datas, setDatas] = useState()
    const ref = useRef(null)

    const setChart = (datas) => {
        if (!ref.current) return
        const eDatas = []
        const aDatas = []
        const nums = []
        Array(12)
            .fill(0).forEach((val, index) => {
                const num = (index + 1).toString()
                nums.push(num)
                eDatas.push(parseW(datas.estimate_data['subject_value_ytm' + num]))
                const month = (new Date()).getMonth()
                if (month > index) {
                    aDatas.push(parseW(datas.achievement_data['subject_value_ytm' + num]))
                }
            })
        option.xAxis.data = nums
        option.series[0].data = eDatas
        option.series[1].data = aDatas
        const chart = echarts.init(ref.current)
        chart.setOption(option)
    }
    const [store, setStore] = useStore()
    const getData = async () => {
        const income_url = '/base/getSubjectEAReportData'
        const cost_url = '/base/getProfitEAReportData'
        const resp = await http.get(cost_id ? cost_url : income_url, {
            subject_id: cost_id ? null : id,
            co_id: store.coId ? store.coId : localStorage.getItem("coId"),
            year,
            month,
            cost_subject_id: cost_id,
            income_subject_id: cost_id ? id : null
        })
        handelResp(resp).then(()=>{
            setDatas(resp.data.subjectEAReportData)
            setChart(resp.data.subjectEAReportData)
        })
    }



    useEffect(() => {
        getData()
    }, [store])

    return <div className="ea-chart">
            {datas && <div>
                <div className="line">
                    <div className='text'><span>{datas.achievement_data.subject_name}</span><span><Statistic value={datas.achievement_data.subject_value_month} precision={2}/></span></div>
                    <div className='text-1'><span>完成率</span><span>{datas.estimate_data.subject_value_month ? 
                    (
                        doneRate(datas.achievement_data.subject_value_month, datas.estimate_data.subject_value_month)
                        // datas.achievement_data.subject_value_month / datas.estimate_data.subject_value_month
                         * 100).toFixed(2)
                     : 0}%</span></div>
                </div>
                <div className="line">
                    <div className='text-1'><span>本年累计</span><span><Statistic valueStyle={{color: '#7c7c7c'}} value={datas.achievement_data.subject_value_ytm}  precision={2}/> </span></div>
                    <div className='text-1'><span>本年累计完成率</span><span>{datas.estimate_data.subject_value_ytm ? (datas.achievement_data.subject_value_ytm / datas.estimate_data.subject_value_ytm * 100).toFixed(2) : 0}%</span></div>
                </div>
            </div>}
            <div className="chart" ref={ref} style={{ width: '100%', height: 200 }}></div>
    </div>
}