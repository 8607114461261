import React, { useEffect, useState } from "react";
import { Table, DatePicker, Statistic, Drawer, Popover } from "antd";
import http from "../../utils/http";
import { useParams } from "react-router-dom";
import { InfoCircleOutlined, EyeOutlined } from "@ant-design/icons";
import Indicator from "../../component/indicator";
import DatePick from "../../component/datepick";
import "./contentRE.less";
import { valueSet, calc_name } from "./contentRA";
import { useStore } from "./store";
import { displayNum } from "../../utils";
import { TipsContent } from "./tips";
import { Tools2 } from "./tools";

const tags = (year) => [
  {
    title: `${parseInt(year) - 1}年实绩`,
    prefix: "past1_value_m",
  },
  {
    title: `${parseInt(year)}年预算`,
    prefix: "estimate_value_m",
  },
];

function ContentR() {
  const params = useParams();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [detailData, setDetailData] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [store, setStore] = useStore();
  const [year, setYear] = useState(
    store.e && store.e.year ? parseInt(store.e.year) : new Date().getFullYear()
  );
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDetail, setLoadingDetail] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [titleSubject, setTitleSubject] = useState("");
  const [subjectData, setSbujectData] = useState({});
  const [showCompDetail, setShowCompDetail] = useState(false);
  window.onresize = (e) => {
    setInnerHeight(window.innerHeight);
  };

  const columns = (year, child) => [
    {
      title: <span style={{ whiteSpace: "nowrap" }}>科目</span>,
      dataIndex: "subject_name",
      key: "subject_name",
      width: 250,
      render: (val, row) =>
        child ? (
          <div className="hover" style={{ whiteSpace: "pre" }}>
            <TipsContent
              title={val}
              tags={tags(year)}
              data={row}
              onClick={() => {
                openCompDetail(row);
              }}
            >
              {row.cal_type === "00" ? (
                <h3 className="subject-title">{val}</h3>
              ) : (
                <span className="hover">
                  {valueSet(
                    val,
                    detailData.length ? detailData[0].subject_id : "",
                    row.subject_id,
                    row.cal_type,
                    child
                  )}
                </span>
              )}
            </TipsContent>
          </div>
        ) : row.cal_type === "00" ? (
          <h3 className="subject-title">{val}</h3>
        ) : (
          <div className="hover" style={{ whiteSpace: "pre" }}>
            <TipsContent
              title={val}
              tags={tags(year)}
              data={row}
              onClick={async () => {
                if (!(row.cal_type === "02")) {
                  openCompDetail(row);
                  return;
                }
                setTitle(val);
                setShowDetail(true);
                setDetailData([]);
                setLoadingDetail(true);
                const resp = await http.get("/base/selectTPageSubjectExt04b", {
                  subject_id: row.subject_id,
                  co_id: store.coId,
                  year,
                });
                console.log(resp);
                if (resp.code === 200) {
                  setDetailData(resp.data.tPageSubjectExt04s);
                }
                setLoadingDetail(false);
              }}
            >
              <span
                style={{ whiteSpace: "nowrap" }}
                // className={`${row.cal_type === "02" ? "hover" : ""}`}
                className="hover"
              >
                {/* <span className="tips-text hover"><span className="tips-icon-eye" style={{ marginRight: '6px' }}><EyeOutlined /></span>{val}</span> */}
                {valueSet(
                  val,
                  data.length ? data[0].subject_id : "",
                  row.subject_id,
                  row.cal_type,
                  child
                )}
              </span>
            </TipsContent>
          </div>
        ),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{`${year - 3}年实绩`}</span>
      ),
      dataIndex: "past3_value",
      key: "past3_value",
      align: "right",
      render: (val, row) =>
        row.cal_type === "00" ? "" : displayNum(val, row.unit),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{`${year - 2}年实绩`}</span>
      ),
      dataIndex: "past2_value",
      key: "past2_value",
      align: "right",
      render: (val, row) =>
        row.cal_type == "00" ? "" : displayNum(val, row.unit),
    },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{`${year - 1}年总数`}</span>
      ),
      dataIndex: "past1_value",
      key: "past1_value",
      align: "right",
      render: (val, row) =>
        row.cal_type == "00" ? "" : displayNum(val, row.unit),
    },
    {
      title: <span style={{ whiteSpace: "nowrap" }}>{`${year}年预算数`}</span>,
      dataIndex: "estimate_value",
      key: "estimate_value",
      align: "right",
      render: (val, row) => (
        <span className="import-text">
          {row.cal_type === "00" ? "" : displayNum(val, row.unit)}
        </span>
      ),
    },

    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>{`${year - 1}/${
          year - 2
        }增长率`}</span>
      ),
      dataIndex: "name2",
      key: "name2",
      align: "right",
      render: (val, row) =>
        row.cal_type == "00" ? (
          ""
        ) : (
          <Indicator
            value={
              row.past2_value
                ? ((row.past1_value - row.past2_value) /
                    Math.abs(row.past2_value)) *
                  100
                : 0
            }
          />
        ),
    },

    {
      title: (
        <span style={{ whiteSpace: "nowrap", marginRight: "10px" }}>{`${year}/${
          year - 1
        }增长率`}</span>
      ),
      // dataIndex: 'name',
      key: "name1",
      align: "right",
      render: (val, row) =>
        row.cal_type === "00" ? (
          ""
        ) : (
          <div style={{ paddingRight: "10px" }}>
            <Indicator
              value={
                row.past1_value
                  ? ((row.estimate_value - row.past1_value) /
                      Math.abs(row.past1_value)) *
                    100
                  : 0
              }
            />
          </div>
        ),
    },
  ];
  const getData = async () => {
    setData([]);
    setLoading(true);
    const resp = await http.get("/base/selectTPageSubjectExt04", {
      page_id: params.page_id,
      co_id: store.coId,
      year,
    });
    console.log(resp);
    if (resp.code === 200) {
      setData(resp.data.tPageSubjectExt04s);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [params, year, store]);

  // console.log(params);
  const openCompDetail = (row) => {
    if (!store.isSuper) return;
    setTitleSubject(row.subject_name);
    setSbujectData({
      id: row.subject_id,
      opt: "e",
      business_cd: row.business_cd,
    });
    setShowCompDetail(true);
  };

  const closeCompDetail = (row) => {
    setShowCompDetail(false);
  };
  const onClose = () => {
    setShowDetail(false);
  };

  return (
    <div className="content contentRE no-scroll">
      {/* <h1>{params.title}</h1> */}
      <div style={{ marginBottom: 30 }}>
        {/* <DatePicker
          onChange={(e) => {
            setYear(e.year());
          }}
          picker="year"
          value={moment(year, "YYYY")}
        /> */}
        <DatePick
          title={params.title}
          year={
            store.e && store.e.year
              ? parseInt(store.e.year)
              : new Date().getFullYear()
          }
          onChange={(e) => {
            setYear(e.year);
          }}
        />
      </div>
      <div className="table-card-re">
        <Table
          size="small"
          loading={isLoading}
          rowClassName={calc_name}
          dataSource={data}
          columns={columns(year)}
          pagination={false}
          scroll={{ y: innerHeight - 270 }}
        />
      </div>
      <Drawer
        title={title}
        placement="right"
        onClose={onClose}
        visible={showDetail}
        zIndex={500}
        width={window.innerWidth * 0.7}
      >
        <div className="table-card-re">
          {detailData && (
            <Table
              size="small"
              loading={isLoadingDetail}
              rowClassName={calc_name}
              dataSource={detailData}
              columns={columns(year, true)}
              pagination={false}
              scroll={{ y: innerHeight * 0.76 }}
            />
          )}
        </div>
      </Drawer>
      <Drawer
        title={titleSubject}
        placement="right"
        onClose={closeCompDetail}
        visible={showCompDetail}
        zIndex={1000}
        width={window.innerWidth * 0.8}
      >
        <Tools2 {...subjectData}></Tools2>
      </Drawer>
    </div>
  );
}

export default ContentR;
