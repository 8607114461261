import React from "react";
import { useParams } from "react-router-dom";
import SalesPlan from "./sales-plan";
import './style.less'

const routerMapping = {
  "sales-plan": <SalesPlan />,
};

function Plan() {
  const params = useParams();
  return (
    <div>
      {routerMapping[params.page_id] ? (
        routerMapping[params.page_id]
      ) : (
        <h1
          style={{
            lineHeight: "30vh",
            textAlign: "center",
            fontSize: 60,
            color: "#dddddd",
          }}
        >
          {params.title}
        </h1>
      )}
    </div>
  );
}

export default Plan;
