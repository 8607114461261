import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import http from "../../utils/http";
import { Table, Button, Upload, message, Statistic } from "antd";
import Detail from "./detail";
import Tips from "./tips";
import {
  UploadOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import "./index.less";
import BtnIcon from "../../assets/icon／审核@2x.png";
import Indicator from "../../component/indicator";
import { displayNum } from "../../utils";

export function grow_up_precent(last, current) {
  if (!last) {
    return 0.0;
  }
  return ((current - last) / Math.abs(last)) * 100;
}

function Content() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [datas, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [auth, setAuth] = useState({});
  const [current, setCurrent] = useState();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)
  window.onresize = (e)=>{
    setInnerHeight(window.innerHeight)
  }
  const getData = async () => {
    setLoading(true)
    const resp = await http.get("/base/selectTPageSubjectExt01", {
      co_id: localStorage.getItem("coId"),
      page_id: params.page_id,
    });
    if (resp.code === 200) {
      setMeta(resp.data.tBudgetFlowExt01);
      setAuth(resp.data.pageControlAuth);
      setData(resp.data.tPageSubjectExt01s);
    } else {
      setData([]);
      setMeta({});
      setAuth({});
    }
    setLoading(false)
  };
  const token = localStorage.getItem("token");
  const uploadProps = {
    name: "file",
    method: "put",
    action:
      http.baseUrl +
      `/base/uploadEstimateExcel?page_id=${
        params.page_id
      }&co_id=${localStorage.getItem("coId")}`,
    headers: {
      authorization: "Bearer " + token,
    },
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} 文件上传成功`);
        getData();
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} 文件上传失败`);
        message.error(info.response.msg);
      }
    },
  };

  useEffect(() => {
    setData([])
    getData();
  }, [params]);

  const columns = () => [
    {
      title: "科目名称",
      dataIndex: "subject_name",
      key: "subject_name",
      render: (val, row) => {
        // console.log(row)
        // return row.cal_type === '01' ? <span>&nbsp;&nbsp;&nbsp;&nbsp;{val}</span> : row.cal_type === '02' ? <h3 style={{margin: 0}}>{val}</h3> : '-'
        return row.cal_type === "01" ? (
          <Tips value={row} year={meta.budget_flow_year} unit={row.unit} />
        ) : (
          <h3 className="subject-title" style={{ margin: 0 }}>
            {val}
          </h3>
        );
      },
    },
    {
      title: meta.budget_flow_year
        ? `${parseInt(meta.budget_flow_year) - 3}年`
        : 0,
      dataIndex: "past3_subject_value",
      key: "past3_subject_value",
      align: "right",
      width: 120,
      render: (val, row) => {
        // console.log(row)
        return row.cal_type === "01" ? displayNum(val, row.unit) : "";
      },
    },
    {
      title: meta.budget_flow_year ? (
        <span style={{ whiteSpace: "nowrap" }}>{`${
          parseInt(meta.budget_flow_year) - 2
        }年实绩及增长率`}</span>
      ) : (
        0
      ),
      dataIndex: "past2_subject_value",
      align: "right",
      key: "past2_subject_value",
      render: (val, row) => {
        // console.log(row)
        return (
          <div className="col-val">
            {row.cal_type === "01" ? displayNum(val, row.unit) : ""}
            <span className="val-gap"></span>
            <div className="indc">
              {row.cal_type === "01" ? (
                <Indicator
                  value={grow_up_precent(
                    row.past3_subject_value,
                    row.past2_subject_value
                  )}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: `${parseInt(meta.budget_flow_year) - 2}年增长率`,
    //   key: '21121',
    //   align: 'right',
    //   render: (val, row) => {
    //     // return row.cal_type === "01" ? `${row.past3_subject_value ? (row.past3_subject_value - row.past2_subject_value) / Math.abs(row.past3_subject_value) * 100 : 0}% ` : ''
    //     return row.cal_type === "01" ? <Indicator value={grow_up_precent(row.past3_subject_value, row.past2_subject_value)}/> : ''
    //   }
    // },
    {
      title: meta.budget_flow_year ? (
        <span style={{ whiteSpace: "nowrap" }}>{`${
          parseInt(meta.budget_flow_year) - 1
        }年实绩及增长率`}</span>
      ) : (
        0
      ),
      dataIndex: "past1_subject_value",
      align: "right",
      key: "past1_subject_value",
      render: (val, row) => {
        // console.log(row)
        return (
          <div className="col-val">
            {row.cal_type === "01" ? displayNum(val, row.unit) : ""}
            <span className="val-gap"></span>
            <div className="indc">
              {row.cal_type === "01" ? (
                <Indicator
                  value={grow_up_precent(
                    row.past2_subject_value,
                    row.past1_subject_value
                  )}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: `${parseInt(meta.budget_flow_year) - 1}年增长率`,
    //   key: '21121',
    //   align: 'right',
    //   render: (val, row) => {
    //     return row.cal_type === "01" ? <Indicator value={grow_up_precent(row.past2_subject_value, row.past1_subject_value)}/> : ''
    //   }
    // },
    {
      title: (
        <span style={{ whiteSpace: "nowrap" }}>
          {meta.budget_flow_year
            ? `${parseInt(meta.budget_flow_year)}年预算及增长率`
            : 0}
        </span>
      ),
      dataIndex: "estimate_subject_value",
      align: "right",
      key: "estimate_subject_value",
      render: (val, row) => {
        // console.log(row)
        return (
          <div className="col-val">
            {row.cal_type === "01" ? displayNum(val, row.unit) : ""}
            <span className="val-gap"></span>
            <div className="indc">
              {row.cal_type === "01" ? (
                <Indicator
                  value={grow_up_precent(
                    row.past1_subject_value,
                    row.estimate_subject_value
                  )}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
    // {
    //   title: `${parseInt(meta.budget_flow_year)}年增长率`,
    //   key: '21121',
    //   align: 'right',
    //   render: (val, row) => {
    //     return row.cal_type === "01" ? <Indicator value={grow_up_precent(row.past1_subject_value , row.estimate_subject_value)} />  : ''
    //   }
    // },
    {
      title: <span style={{marginRight: '10px'}}>编辑</span>,
      dataIndex: "detail",
      key: "detail",
      width: 80,
      align: "right",
      render: (val, row) => {
        // console.log(row)
        return row.cal_type === "01"
          ? // <Button type="primary" size="small" onClick={() => setCurrent(row)}>
            //   编辑
            // </Button>
            auth.can_write && (
              <div style={{marginRight: '10px'}} className="edit-btn" onClick={() => setCurrent(row)}>
                {/* <EditOutlined onClick={() => setCurrent(row)} /> */}
              </div>
            )
          : "";
      },
    },
  ];
  const cols = columns();
  // console.log(meta, meta.page_status === "00" || meta.page_status === "02");
  return (
    <div className="content no-scroll">
      <div style={{ display: "flex" }} className="content-btns-e">
        <div className="btns">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={async () => {
              const response = await http.download(
                "/base/exportEstimateExcel",
                {
                  page_id: params.page_id,
                  co_id: localStorage.getItem("coId"),
                }
              );
              var blob = new Blob([response], {
                type: "application/octet-stream",
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute(
                "download",
                decodeURI(params.title + "模版.xlsx")
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            下载数据
          </Button>
          {auth.can_write && (
            <div style={{ marginLeft: 20 }}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />} type="primary" ghost>
                  上传数据
                </Button>
              </Upload>
            </div>
          )}
        </div>
        <div className="btns">
          <div style={{ marginRight: 20 }}>
            {/* <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              className={`save ${!(meta.page_status === '00' || meta.page_status === '02') && 'not-save'}`}
              onClick={async () => {
                
              }}
            >
              保存
            </Button> */}
          </div>
            <Button
              type="primary"
              icon={<img src={BtnIcon} />}
              className={`submit ${
                !(meta.page_status === "00" || meta.page_status === "02") &&
                "not-submit"
              }`}
              onClick={async () => {
                if (!(meta.page_status === "00" || meta.page_status === "02"))
                  return;
                if(!auth.can_submit)return
                const resp = await http.put(
                  `/base/pageSubmit?page_flow_id=${meta.page_flow_id}`
                );
                if (resp.code === 200) {
                  message.success("提交成功");
                  getData();
                } else {
                  message.error(resp.msg);
                }
              }}
            >
              {!(meta.page_status === "00" || meta.page_status === "02")
                ? "审核中"
                : "提交审核"}
            </Button>
        </div>
      </div>
      <h1 style={{ textAlign: "center" }} className="content-title">
        {meta.budget_flow_year}
        {params.title}
      </h1>
      <Table
        columns={cols}
        dataSource={datas}
        size="small"
        loading={isLoading}
        pagination={false}
        scroll={{ y: innerHeight - 235 }}
      />
      {current && (
        <Detail
          cancel={() => {
            setCurrent();
            getData();
          }}
          value={current}
          year={meta.budget_flow_year}
        />
      )}
    </div>
  );
}

export default Content;
