import React, { useEffect, useState, useRef } from "react";
import http from "../../../utils/http";
import { Select, Table, Radio, Switch, Button, Modal, Input, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useStore } from "../store";
import { displayNum, doneRate, handelResp, handleMonth } from "../../../utils";
import DatePicker from "../../../component/datepick";

import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
const { confirm } = Modal;
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);
const option = {
  legend: {
    //   data: ['预算', '实绩'],
    //   bottom: 20
  },
  title: {
    text: "",
  },
  tooltip: {
    trigger: "axis",
    // formatter: '{value}%',
    valueFormatter: (value) => value.toFixed(2) + "%",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  xAxis: {
    type: "category",
    data: [],
  },
  grid: {
    left: "1%",
    top: "10%",
    right: "1%",
    bottom: "10%",
    containLabel: true,
  },
  yAxis: [
    {
      // name: element.subject_name,
      type: "value",
      // offset: index ? (index - 1) * 80 : 0,
      axisLabel: {
        formatter: `{value} %`,
      },
    },
  ],
  series: [],
};
function DoneChart() {
  const [indicatorList, setIndicatorList] = useState([]);
  const [ids, setIds] = useState([]);
  const [opt, setOpt] = useState("a");
  const [eData, seteData] = useState([]);
  const [aData, setaData] = useState([]);
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTags] = useState();
  const [store, _] = useStore();
  const [isW, setIsW] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();
  const [year, setYear] = useState(
    store.a ? parseInt(store.a.year) : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    store.a ? parseInt(store.a.month) : new Date().getMonth() + 1
  );

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("");

  //   const [cols, setCols] = useState([]);

  const getIndicatorList = async () => {
    const resp = await http.get("/indi/selectIndicatorList");
    // console.log(resp);
    setIndicatorList(resp.data.indicatorLists);
  };

  const getTags = async () => {
    const resp = await http.get("/indi/getIndiTags");
    console.log(resp);
    handelResp(resp).then((data) => {
      setTags(data.indicatorTags);
    });
  };

  useEffect(() => {
    getIndicatorList();
    getTags();
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const resp = await http.post("/indi/saveIndiTag", {
      subject_ids: ids,
      tag_name: modalText,
    });
    handelResp(resp).then(() => {
      setVisible(false);
      setConfirmLoading(false);
      getTags();
      setModalText("");
    });
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setVisible(false);
  };
  const getData = async () => {
    setIsLoading(true);
    if (opt === "a") {
      const resp = await http.put("/indi/getIndicatorMonthlyYTM05", {
        co_id: store.coId,
        month: month ? handleMonth(month) : store.a && store.a.month,
        subject_ids: ids,
        year: year ? year.toString() : store.a && store.a.year,
      });
      // console.log(resp)
      handelResp(resp).then((data) => {
        setaData(data.subjectReportData05s);
      });
    }
    if (opt === "e") {
      const resp = await http.put("/indi/getIndicatorMonthlyYTM04", {
        co_id: store.coId,
        month: month ? handleMonth(month) : store.a && store.a.month,
        subject_ids: ids,
        year: year ? year.toString() : store.a && store.a.year,
      });
      // console.log(resp)
      handelResp(resp).then((data) => {
        seteData(data.subjectReportData04s);
      });
    }
    setIsLoading(false);
  };

  const handleChange = async (ids) => {
    setIds(ids);
  };

  const setChart = (res, ref) => {
    const yAxis = [];
    const series = [];
    let nums = [];

    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      const data = [];
      nums = [];
      Array(12)
        .fill(0)
        .forEach((val, i) => {
          const num = (i + 1).toString();
          const month = store.a
            ? parseInt(store.a.month)
            : new Date().getMonth();
          if (month > i) {
            nums.push(num);
            data.push(
              doneRate(
                element[
                  "achievement_value_m" +
                    (num < 10 ? "0" + num.toString() : num.toString())
                ],
                element[
                  "estimate_value_m" +
                    (num < 10 ? "0" + num.toString() : num.toString())
                ]
              ) * 100
            );
          }
        });

      // yAxis.push({
      //   name: element.subject_name,
      //   type: "value",
      //   // offset: index ? (index - 1) * 80 : 0,
      //   axisLabel: {
      //     formatter: `{value} %`,
      //   },
      // });

      series.push({
        name: element.subject_name,
        data,
        type: "line",
        // yAxisIndex: index ? index : undefined,
        smooth: true,
        // color: "#3B7CFF",
      });
    }

    option.xAxis.data = nums;
    // option.title.text = title;
    // option.yAxis = yAxis;
    option.series = series;
    // option.title.text = datas.achievement_data.subject_name
    // console.log(option);
    const chart = echarts.init(ref);
    chart.setOption(option);
    return chart;
  };

  useEffect(() => {
    if (aData.length && ref.current) {
      const chart = setChart(aData, ref.current);
      return () => chart.clear();
    }
  }, [aData, month, year]);

  useEffect(() => {
    if (ids.length) getData();
  }, [ids, month, year, opt, store]);

  return (
    <div className="content tools">
      {/* <div className="select-btn">
        <div
          className={`opt ${opt === "a" ? "opt-active" : ""}`}
          onClick={() => setOpt("a")}
        >
          实绩
        </div>
        <div
          className={`opt ${opt === "e" ? "opt-active" : ""}`}
          onClick={() => setOpt("e")}
        >
          预算
        </div>
      </div> */}
      {/* <Checkbox.Group
        options={[
          { label: "实绩", value: "a" },
          { label: "预算", value: "e" },
        ]}
        value={opt}
        onChange={(vals) => {
            console.log(vals)
            setOpt(vals)
        }}
      /> */}
      <DatePicker
        title={"选择时间"}
        canNotShowUp={true}
        year={store.a ? parseInt(store.a.year) : new Date().getFullYear()}
        // month={
        // opt === "a"
        //   ? store.a
        //     ? parseInt(store.a.month)
        //     : new Date().getMonth() + 1
        //   : null
        // }
        onChange={(e) => {
          setYear(e.year);
          if (e.month) setMonth(e.month);
        }}
      />
      <div
        className="search-filter"
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Select
          showSearch
          mode="multiple"
          autoClearSearchValue={false}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          allowClear
          style={{ width: "93%" }}
          placeholder="请选择指标"
          //   defaultValue={[]}
          value={ids}
          onClear={() => {
            setIds([]);
            seteData([]);
            setaData([]);
            setCurrentTags();
          }}
          onChange={handleChange}
        >
          {indicatorList.map((val, index) => {
            return (
              <Select.Option key={val.id} title={val.ind_describe}>
                {val.ind_name}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          onClick={async () => {
            showModal();
          }}
        >
          保存
        </Button>
      </div>

      <div className="tag-container">
        <div className="tag-label">标签：</div>
        <div className="tags">
          {tags.map((val, index) => {
            return (
              <Tag
                className={`hover ${currentTag === index ? "tag-active" : ""}`}
                key={index}
                closable={val.user_id === "000000" ? false : true}
                onClick={() => {
                  setIds(val.subject_ids);
                  setCurrentTags(index);
                }}
                // color={currentTag === index ? "#1DA57A" : undefined}
                onClose={(e) => {
                  confirm({
                    title: "确定要删除这个标签吗?",
                    icon: <ExclamationCircleOutlined />,
                    content: "",
                    okText: "是的",
                    okType: "danger",
                    cancelText: "不删",
                    async onOk() {
                      const resp = await http.delete(
                        `/indi/deleteIndiTag?id=${val.id}`
                      );
                      handelResp(resp);
                      getTags();
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                  e.preventDefault();
                }}
              >
                {val.tag_name}
              </Tag>
            );
          })}
        </div>
      </div>

      <div style={{ width: "100%", height: "320px" }}>
        <div ref={ref} style={{ width: "100%", height: "380px" }}></div>
      </div>
      <Modal
        title="保存为标签"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Input
          placeholder="请输入保存的标签名"
          value={modalText}
          onChange={(e) => setModalText(e.target.value)}
        ></Input>
      </Modal>
    </div>
  );
}

export default DoneChart;
