import React, {useState, useEffect} from 'react';
import http from '../../../../../utils/http';
import { handelResp } from '../../../../../utils';
import { Select, Radio } from 'antd'


export const handleAnyFields = (item, params) =>{
  const tp = typeof(item)
  // console.log('handleAnyFields item type:', tp)
  // console.log('handleAnyFields item:', item)
  let url = '/analyzer/getAnalyzeIndi'
  if(tp==='string'){
    params.analyze_id = item
  }
  if(tp === 'object'){
    if(item.mode){
      if(item.mode === 'FREE'){
        url = '/analyzer/getAnalyzeFreeIndi'
        params.indi_id = item.ids.join('|')
        params.analyze_id = item.anyFree.id
      }else{
        params.analyze_id = item.any.id
      }
    }else{
      params.analyze_id = item.id
    }
  }
  return {url, params}
}

function AnySelector(props) {

  const [data, setData] = useState([])
  const [dataFree, setDataFree] = useState([])
  const [indicatorList, setIndicatorList] = useState([]);
  const [mode, setMode] = useState(props.value?.mode&&props.value.mode === "FREE" ? "FREE" : "static")
  const [ids, setIds] = useState(props.value?.ids ? props.value.ids : [])
  const [currentAny, setCurrentAny] = useState({})
  const [currentAnyFree, setCurrentAnyFree] = useState({})
  const getData = async ()=>{
    const resp = await http.get('/analyzer/getAnalyzeMethods', {
      analyze_type : props.analyze_type,
    })
    handelResp(resp).then(data=>{
      if(!data.sAnalyzeMethods)return
      setData(data.sAnalyzeMethods)
    })
  }

  const getDataFree = async ()=>{
    const resp = await http.get('/analyzer/getAnalyzeMethods', {
      analyze_type : props.analyze_type,
      mode: 'FREE'
    })
    handelResp(resp).then(data=>{
      if(!data.sAnalyzeMethods)return
      setDataFree(data.sAnalyzeMethods)
    })
  }

  const getIndicatorList = async () => {
    const resp = await http.get("/indi/selectIndicatorList");
    // console.log(resp);
    setIndicatorList(resp.data.indicatorLists);
  };
  
  const onChange = value =>{
    if(value && props.onChange){
      // props.onChange(props.item ? data.filter(val=>val.id === value)[0] :value)
      const current = data.filter(val=>val.id === value)[0]
      setCurrentAny(current)
      props.onChange({
        any: current,
        anyFree: currentAnyFree,
        mode,
        ids
      })
    }
  }

  const onChangeFree = value =>{
    if(value && props.onChange){
      // props.onChange(props.item ? data.filter(val=>val.id === value)[0] :value)
      const current = dataFree.filter(val=>val.id === value)[0]
      setCurrentAnyFree(current)
      props.onChange({
        any: currentAny,
        anyFree: current,
        mode,
        ids
      })
    }
  }

  useEffect(()=>{
    if(mode==="FREE"){
      getDataFree()
    }else{
      getData()
    }
  }, [mode])

  useEffect(()=>{
    getIndicatorList()
    init()
  }, [])

  const handleAnyValue = item=>{
    const tp = typeof(item)

    if(tp === 'string'){
      return item
    }
    // console.log('handleAnyValue:', tp, item)
    if(tp==='object'){
      if(item.mode){
        if(item.mode==="FREE"){
          return item.anyFree.id
        }else{
          return item.any.id
        }
      }else{
        return item.id
      }
    }
  }

  const value = handleAnyValue(props.value)
  
  const init  = ()=>{
    const dts = (mode==="FREE" ? dataFree : data)
    const current = dts.filter(val=>val.id === value)[0]
    console.log('current', value, current, dts)
    if(current){
      mode==="FREE" ? setCurrentAnyFree(current) : setCurrentAny(current)
    }
  }



  useEffect(()=>{
    init()
  }, [data, dataFree])

  const handleChange =  (ids) => {
    setIds(ids);
    props.onChange({
      any: currentAny,
      anyFree: currentAnyFree,
      mode,
      ids
    })
  };

  


  return (
    <div>
      <Radio.Group value={mode} onChange={e=>{
        setMode(e.target.value)
      }}>
          <Radio value="static">固定指标</Radio>
          <Radio value="FREE">自由指标</Radio>
      </Radio.Group>
      <div style={{height: 10}}></div>
      {mode === "FREE" &&<Select
        showSearch
        placeholder="填写分析方法"
        optionFilterProp="children"
        defaultValue={props.value?.anyFree?.id}
        onChange={onChangeFree}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={dataFree.map(val=>({
          label: val.analyze_name,
          value: val.id
        }))}
      /> }
      {mode !== "FREE" &&<Select
        showSearch
        placeholder="填写分析方法"
        optionFilterProp="children"
        defaultValue={handleAnyValue(props.value?.mode ? props.value.any?.id :  props.value?.id)}
        onChange={onChange}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={data.map(val=>({
          label: val.analyze_name,
          value: val.id
        }))}
      />}
      <div style={{height: 10}}></div>
      {
        mode === "FREE" && <Select
        showSearch
        mode="multiple"
        autoClearSearchValue={false}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.includes(input)}
        allowClear
        // style={{ width: "93%" }}
        placeholder="请选择指标"
        defaultValue={ids}
        // value={ids}
        onClear={() => {
          // setIds([]);
          // seteData([]);
          // setaData([]);
          // setCurrentTags();
        }}
        onChange={handleChange}
      >
        {indicatorList.map((val, index) => {
          return (
            <Select.Option key={val.id} title={val.ind_describe}>
              {val.ind_name}
            </Select.Option>
          );
        })}
      </Select>
      }
    </div> 
  );
}

export default AnySelector;
