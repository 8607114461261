import React, { useState, useEffect } from "react";
import SettingImg from "../../assets/setting.png";
import "./alarm.less";
import { useStore } from "./store";
import http from "../../utils/http.js";
import { displayNum, handelResp } from "../../utils";
import { Modal, Button, Select, InputNumber, Switch } from "antd";
function Alarm() {
  const [showSetting, setSetting] = useState(false);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [store, _] = useStore();
  const [codeValues, setCodeValues] = useState({});

  const [settingDatas, setSettingDatas] = useState([{}]);
  const [indicatorList, setIndicatorList] = useState([]);

  const getIndicatorList = async () => {
    const resp = await http.get("/base/selectIndicatorList");
    setIndicatorList(resp.data.indicatorLists);
  };

  const getCodeValues = async () => {
    const resp = await http.get("/base/getCodeValues?code_name=IND_TYPE");
    setCodeValues(resp.data.codeMap);
  };

  const getData = async () => {
    if (!store.a) return;
    const resp = await http.get("/base/getWarnings", {
      co_id: store.coId,
      month: store.a && store.a.month,
      year: store.a && store.a.year,
    });

    handelResp(resp).then((data) => {
      // console.log(data);
      if(data.warningResults){
        setData1(data.warningResults.filter(val=>val.tWarningExt01.ind_group === '01'))
        setData2(data.warningResults.filter(val=>val.tWarningExt01.ind_group === '02'))
        setData3(data.warningResults.filter(val=>val.tWarningExt01.ind_group === '04'))
      }
    });
  };

  const getSettingData = async () => {
    const resp = await http.get("/base/selectTWarningExt01", {
      co_id: store.coId,
    });
    handelResp(resp).then((data) => {
      console.log(data);
      if (data.tWarningExt01s) {
        setSettingDatas(data.tWarningExt01s);
      } else {
        setSettingDatas([{}]);
      }
    });
  };

  useEffect(() => {
    if (showSetting) {
      getSettingData();
      getIndicatorList();
      getCodeValues();
    }
  }, [showSetting]);

  useEffect(() => {
    getData();
  }, [store]);

  useEffect(() => {
    if (!showSetting) {
      getData();
    }
  }, [showSetting]);

  const save = async () => {
    for (let index = 0; index < settingDatas.length; index++) {
      const element = settingDatas[index];
      if (element["id"]) {
        //更新
        if (element['has_change']){
          const resp = await http.put("base/updateTWarning", element);
          handelResp(resp);
        }
      } else {
        //创建
        element['co_id'] = store.coId
        element['enable'] = element['enable'] ? element['enable'] : '0'
        element['w_value'] = element['w_value'] ? element['w_value'] : 0
        const resp = await http.post("base/createTWarning", element);
        handelResp(resp);
      }
    }
    setSetting(false);
  };

  const items = (data)=>{
    return data.map((val, index)=>{
      return <div className="item" key={index}>
      <div className="left">
        <div className="name">{val.tWarningExt01.ind_name}</div>
        <div className="ini">
          {val.tWarningExt01.ind_type_name}
          <span style={{margin: '0 5px 0 12px'}}> {val.tWarningExt01.operate === 'gt'? "≥": '≤'} </span>{displayNum(val.tWarningExt01.w_value, val.tWarningExt01.ind_unit, 
          {
            fontSize: '16px',
            color: '#999999'
          })}
        </div>
      </div>
      <div className="right">
        {displayNum(val.ind_value, val.tWarningExt01.ind_unit, {
          fontSize: '26px',
          color: val.is_warning ? '#FF6167' : '#50E3C2',
          fontFamily: 'Impact'
        })}
      </div>
    </div>
    })
  }
  return (
    <div className="alarm">
      <div className="header">
        <div className="title">{store.co_name}{store.a&&store.a.year}年{store.a&&store.a.month}月执行数据预警</div>
        <div
          className="setting"
          onClick={() => {
            setSetting(true);
          }}
        >
          <img src={SettingImg} alt="" />
          <span>预警设置</span>
        </div>
      </div>

      <div className="container">
        <div className="card">
          <div className="head">销售</div>
          <div className="items">
              {
                items(data1)
              }
            {!data1.length && <div className="none">无预警数据</div>}
          </div>
        </div>
        <div className="card">
          <div className="head">售后</div>
          <div className="items">
              {
                items(data2)
              }
            {!data2.length && <div className="none">无预警数据</div>}
          </div>
        </div>
        <div className="card">
          <div className="head">管理</div>
          <div className="items">
              {
                items(data3)
              }
            {!data3.length && <div className="none">无预警数据</div>}
          </div>
        </div>
      </div>
      {showSetting && (
        <Modal
          wrapClassName="setting-container"
          width={"70vw"}
          visible={showSetting}
          title={
            <div className="setting-header">
              <div className="title">上海圆众2023年2月执行数据预警设置</div>
              <div className="btns">
                <Button
                  onClick={() => {
                    setSetting(false);
                  }}
                >
                  取消
                </Button>
                <Button type="primary" onClick={save}>
                  保存
                </Button>
              </div>
            </div>
          }
          closable={false}
          footer={null}
        >
          <div className="table-container">
            <div className="table">
              <div className="head">
                <div style={{ width: "30%" }} className="title">
                  指标名
                </div>
                <div style={{ width: "20%" }} className="title">
                  指标类型
                </div>
                <div style={{ width: "15%" }} className="title">
                  操作
                </div>
                <div style={{ width: "25%" }} className="title">
                  预警值
                </div>
                <div style={{ width: "10%" }} className="title">
                  启用
                </div>
              </div>
              <div className="body">
                <div
                  style={{ width: "30%", borderRight: "1px solid #1BA57A" }}
                  className="block"
                >
                  {settingDatas.map((val, index) => {
                    return (
                      <Select
                        showSearch
                        key={index}
                        // mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.includes(input)
                        }
                        // allowClear
                        style={{ width: "100%", marginBottom: "10px" }}
                        placeholder="请选择指标"
                        //   defaultValue={[]}
                        value={val["ind_id"]}
                        onClear={() => {}}
                        onChange={(e) => {
                          console.log(e);
                          if (e) {
                            val["ind_id"] = e;
                            val["has_change"] = true;
                          }
                        }}
                      >
                        {indicatorList &&
                          indicatorList.map((val, index) => {
                            return (
                              <Select.Option
                                key={val.id}
                                title={val.ind_describe}
                              >
                                {val.ind_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    );
                  })}
                </div>
                <div
                  style={{ width: "20%", borderRight: "1px solid #1BA57A" }}
                  className="block"
                >
                  {settingDatas.map((val, index) => {
                    return (
                      <Select
                        key={index}
                        value={val["ind_type"]}
                        onChange={(e) => {
                          if (e) {
                            val["ind_type"] = e;
                            val["has_change"] = true;
                          }
                        }}
                        style={{ width: "100%", marginBottom: "10px" }}
                      >
                        {Object.keys(codeValues).map((value) => {
                          return (
                            <Select.Option key={value}>
                              {codeValues[value]}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    );
                  })}
                </div>
                <div
                  style={{ width: "15%", borderRight: "1px solid #1BA57A" }}
                  className="block"
                >
                  {settingDatas.map((val, index) => {
                    return (
                      <div
                        key={index}
                        className="btns"
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          className={
                            val["operate"] === "ge" ? "btn active" : "btn"
                          }
                          onClick={() => {
                            val['operate'] = 'ge'
                            val["has_change"] = true;
                            setSettingDatas([...settingDatas]);
                          }}
                        >
                          ≥
                        </div>
                        <div
                          className={
                            val["operate"] === "le" ? "btn active" : "btn"
                          }
                          onClick={() => {
                            val['operate'] = 'le'
                            val["has_change"] = true;
                            setSettingDatas([...settingDatas]);
                          }}
                        >
                          ≤
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{ width: "25%", borderRight: "1px solid #1BA57A" }}
                  className="block"
                >
                  {settingDatas.map((val, index) => {
                    return (
                      <InputNumber
                        key={index}
                        style={{ width: "100%", marginBottom: "10px" }}
                        value={val["w_value"] ? val["w_value"] : 0}
                        onChange={(e) => {
                          console.log(e);
                          if (e) {
                            val["w_value"] = e;
                            val["has_change"] = true;
                            setSettingDatas([...settingDatas]);
                          }
                        }}
                      />
                    );
                  })}
                </div>
                <div style={{ width: "10%" }} className="block">
                  {settingDatas.map((val, index) => {
                    return (
                      <div key={index} className="switch-container">
                        <Switch
                          checked={val["enable"] === '1' ? true :  false}
                          onChange={(checked) => {
                            console.log(checked);
                            val["enable"] = checked ? '1' : '0';
                            val["has_change"] = true;
                            setSettingDatas([...settingDatas]);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="add-item-btn"
              onClick={() => {
                settingDatas.push({});
                console.log(settingDatas);
                setSettingDatas([...settingDatas]);
              }}
            >
              +
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Alarm;
