import React, { useState } from 'react'
import Chart from '../../component/companys'
import { useParams } from "react-router-dom";
import EaChart from '../../component/eaCharts'
import EaChartYear from '../../component/eaChartsYear'
import { useStore } from './store';
export default () => {
    const params = useParams();
    // const year = (new Date()).getFullYear()
    // const month = (new Date()).getMonth() + 1
    const [store, setStore] = useStore()
    const month = store.a ? store.a.month : (new Date()).getMonth() + 1
    const year = store.a ? store.a.year : (new Date()).getFullYear().toString()
    const isSuper = store.isSuper
    return <div className="bi-a">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', width: '100%', fontSize: 24 }}><h1>{year}年预算</h1></div>
            {!isSuper ? <div style={{ width: '100%', display: 'flex', }}>
                <EaChart id={'1010'} year={year} month={month} />
                <EaChart id={'1010'} cost_id={'2010'} year={year} month={month} />
            </div> : <div style={{ width: '100%', display: 'flex', }}>
                <EaChartYear id={'1010'} year={year} month={month} />
                <EaChartYear id={'1010'} cost_id={'2010'} year={year} month={month} />
            </div>}
            <Chart id={2010} year={year} month={month} type={params.type} />
            <Chart id={1010} cost_id={2010} year={year} month={month} type={params.type} />
        </div>
    </div>
}