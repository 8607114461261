import React from 'react';


const props = [
  {
    label: "高度",
    type: "inputNumber",
    name: "height",
  }
]

function Blank(props) {
  const { height } = props
  return (
    <div className='rg-component-black' style={{height: height ? height : 20}}>
      {/* Blank */}
    </div>
  );
}

export default {Comp: Blank, props};
