import React, { useEffect, useState } from "react";
import { Table, DatePicker, Statistic, Drawer, Popover } from "antd";
import http from "../../utils/http";
import { useParams } from "react-router-dom";
import moment from "moment";
import { 
  // InfoCircleOutlined, EyeOutlined, 
  RightOutlined
} from "@ant-design/icons";
import "./contentRA.less";
import Indicator from "../../component/indicator";
import DatePick from "../../component/datepick";
import { useStore } from "./store";
import { displayNum, doneRate } from "../../utils";
import { TipsContent } from './tips'
import { Tools2 } from './tools'

function fixNum(num, length) {
  return ("" + num).length < length
    ? (new Array(length + 1).join("0") + num).slice(-length)
    : "" + num;
}
export function valueSet(value, origin, current, cal_type, isChild) {
  const vals = value.split("　");
  if (!origin) {
    origin = 0;
  }
  if (!current) {
    current = 0;
  }
  let num = current.length - origin.length;
  if (num < 0) {
    num = 0;
  }
  // console.log(origin, current)
  return (
    <span className="tips-text">
      <span style={{ marginLeft: isChild ? (num / 2) * 20 : 0 }}></span>

      {vals.length > 1 ? vals[vals.length - 1] : vals[0]}
      {isChild ? null :<span className="tips-icon-eye" style={{ marginLeft: "6px" }}>
        <RightOutlined
          style={{ visibility: cal_type === "02" ? "" : "hidden" }}
        />
      </span>}
    </span>
  );
}

export function calc_name(record, index) {
  return "row-" + record.cal_type;
}

const tags = [
  {
    title: "同期数",
    prefix: "past1_value_m",
  },
  {
    title: "预算数",
    prefix: "estimate_value_m",
  },
  {
    title: "实绩数",
    prefix: "achievement_value_m",
  },
];

function ContentR() {
  const params = useParams();
  const [store, setStore] = useStore();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDetail, setLoadingDetail] = useState(false);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [detailData, setDetailData] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [titleSubject, setTitleSubject] = useState("");
  const [subjectData, setSbujectData] = useState({})
  const [showCompDetail, setShowCompDetail] = useState(false)
  const [year, setYear] = useState(
    store.a ? parseInt(store.a.year) : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    store.a ? parseInt(store.a.month) : new Date().getMonth() + 1
  );
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  window.onresize = (e) => {
    setInnerHeight(window.innerHeight);
  };
  const [isMonth, setIsMonth] = useState("month");
  const columns = (year, child, isMonth) => {
    const base = [
      {
        title: <span style={{ whiteSpace: "nowrap" }}>主要指标</span>,
        dataIndex: "subject_name",
        key: "subject_name",
        fixed: "left",
        width: 210,
        ellipsis: true,
        render: (val, row) =>
          child ? (
            <div className="hover" >
            <TipsContent title={val} tags={tags} data={row} onClick={()=>{
              openCompDetail(row)
            }}>
              {/* <span className="tips-text"><span className="tips-icon-eye" style={{ marginRight: '6px' }}><EyeOutlined /></span>{val}</span> */}
              <span style={{ whiteSpace: "nowrap" }} className='hover' >
                {valueSet(
                  val,
                  detailData.length ? detailData[0].subject_id : "",
                  row.subject_id,
                  row.cal_type,
                  child
                )}
              </span>
            </TipsContent>
            </div>
          ) : row.cal_type === "00" ? (
            <h3 className="subject-title">{val}</h3>
          ) : (
            <div className="hover">
            <TipsContent title={val} tags={tags} data={row} onClick={async () => {
              // console.log('=====', row.cal_type)
              if (!(row.cal_type === "02")) {
                openCompDetail(row)
                return
              };
              setShowDetail(true);
              setLoadingDetail(true);
              setDetailData([]);
              setTitle(val);
              const resp = await http.get(
                "/base/selectTPageSubjectExt05b",
                {
                  subject_id: row.subject_id,
                  co_id: store.coId,
                  year,
                  month: (month < 10 ? "0" : "") + month.toString(),
                }
              );
              // console.log(resp);
              if (resp.code === 200) {
                setDetailData(resp.data.tPageSubjectExt05s);
              }
              setLoadingDetail(false);
            }}>
              <span
                style={{ whiteSpace: "nowrap" }}
                // className={`${row.cal_type === "02" ? "hover" : ""}`}
                className="hover"
                
              >
                {/* <span className="tips-text hover"><span className="tips-icon-eye" style={{ marginRight: '6px' }}><EyeOutlined /></span>{val}</span> */}
                {/* {valueSet(val, child ? data[0].subject_id : detailData[0].subject_id, row.subject_id)} */}
                {valueSet(
                  val,
                  data.length ? data[0].subject_id : "",
                  row.subject_id,
                  row.cal_type
                )}
              </span>
            </TipsContent>
            </div>
          ),
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>{`${year}年预算数`}</span>
        ),
        dataIndex: "estimate_value_year",
        key: "estimate_value_year",
        align: "right",
        render: (val, row) =>
          row.cal_type == "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: (
          <span
            style={{ whiteSpace: "nowrap", marginRight: "10px" }}
          >{`${year}年预算达成率`}</span>
        ),
        key: "4",
        align: "right",
        // width: 150,
        render: (val, row) => {
          return row.cal_type == "00" ? (
            ""
          ) : (
            <span style={{ marginRight: "10px" }}>
              {row.estimate_value_year
                ? (
                  doneRate(row.achievement_value_YTM , row.estimate_value_year) *
                    100
                  ).toFixed(2)
                : 0}
              %
            </span>
          );
        },
      },
    ];
    const months = [
      {
        title: <span style={{ whiteSpace: "nowrap" }}>预算数</span>,
        dataIndex: "estimate_value_month",
        key: "estimate_value_month",
        align: "right",
        width: 150,
        render: (val, row) =>
          row.cal_type == "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>实绩数</span>,
        dataIndex: "achievement_value_month",
        key: "achievement_value_month",
        align: "right",
        width: 150,
        render: (val, row) => (
          <span className="import-text">
            {row.cal_type === "00" ? "" : displayNum(val, row.unit)}
          </span>
        ),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>完成率</span>,
        key: "5",
        align: "right",
        width: 150,
        render: (val, row) => {
          const rate = doneRate(
            row.achievement_value_month,
            row.estimate_value_month
          );
          return row.cal_type === "00" ? (
            ""
          ) : (
            <span>
              {row.estimate_value_month ? (rate * 100).toFixed(2) : 0}%
            </span>
          );
        },
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>上年同期</span>,
        dataIndex: "past1_value_month",
        key: "past1_value_month",
        width: 150,
        align: "right",
        render: (val, row) =>
          row.cal_type === "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>同比增长</span>,
        key: "7",
        width: 150,
        align: "right",
        render: (val, row) => {
          return row.cal_type === "00" ? (
            ""
          ) : (
            <Indicator
              value={
                row.past1_value_month
                  ? ((row.achievement_value_month - row.past1_value_month) /
                      Math.abs(row.past1_value_month)) *
                    100
                  : 0
              }
            />
          );
        },
      },
    ];
    const years = [
      {
        title: <span style={{ whiteSpace: "nowrap" }}>预算数</span>,
        dataIndex: "estimate_value_YTM",
        key: "estimate_value_YTM",
        align: "right",
        width: 150,
        render: (val, row) =>
          row.cal_type === "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>实绩数</span>,
        dataIndex: "achievement_value_YTM",
        key: "achievement_value_YTM",
        align: "right",
        width: 150,
        render: (val, row) => (
          <span className="import-text">
            {row.cal_type === "00" ? "" : displayNum(val, row.unit)}
          </span>
        ),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>完成率</span>,
        key: "3",
        align: "right",
        width: 150,
        render: (val, row) => {
          const rate = doneRate(
            row.achievement_value_YTM,
            row.estimate_value_YTM
          );
          return row.cal_type === "00" ? (
            ""
          ) : (
            <span>{row.estimate_value_YTM ? (rate * 100).toFixed(2) : 0}%</span>
          );
        },
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>上年同期</span>,
        // key: '1',
        // render: (val, row)=>{
        //   return <span>{row.estimate_value_YTM ? (row.achievement_value_YTM/row.estimate_value_YTM).toFixed(2) : 0}%</span>
        // }
        dataIndex: "past1_value_YTM",
        key: "past1_value_YTM",
        align: "right",
        width: 150,
        render: (val, row) =>
          row.cal_type == "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>同比增长</span>,
        key: "2",
        align: "right",
        width: 150,
        render: (val, row) => {
          return row.cal_type == "00" ? (
            ""
          ) : (
            <Indicator
              value={
                row.past1_value_YTM
                  ? ((row.achievement_value_YTM - row.past1_value_YTM) /
                      Math.abs(row.past1_value_YTM)) *
                    100
                  : 0
              }
            />
          );
        },
      },
    ];
    months.forEach((val) => {
      val.fixed = "left";
      val.width = 120;
    });
    years.forEach((val) => {
      val.fixed = "left";
      val.width = 120;
    });
    if (isMonth === "month") {
      base.splice(1, 0, ...months);
    } else {
      base.splice(1, 0, ...years);
    }
    return base;
  };
  const getData = async () => {
    setData([]);
    setLoading(true);
    const resp = await http.get("/base/selectTPageSubjectExt05", {
      page_id: params.page_id,
      co_id: store.coId,
      year,
      month: (month < 10 ? "0" : "") + month.toString(),
    });
    // console.log((month < 9 ? "0" : "") + month.toString());
    // console.log(month);
    if (resp.code === 200) {
      setData(resp.data.tPageSubjectExt05s);
    }
    setLoading(false);
  };

  const openCompDetail = row =>{
    if(!store.isSuper)return
    setTitleSubject(row.subject_name)
    setSbujectData({
      id: row.subject_id,
      opt: 'a',
      month,
      year,
      business_cd: row.business_cd
    })
    setShowCompDetail(true)
  }

  const closeCompDetail = row =>{
    setShowCompDetail(false)
  }
  useEffect(() => {
    getData();
  }, [params, year, month, store]);

  // useEffect(()=>{
  //   if(store.a){
  //     setYear(parseInt(store.a.year))
  //     setMonth(parseInt(store.a.month))
  //     console.log(store)
  //   }
  // }, [store])

  // console.log(params);

  const onClose = () => {
    setShowDetail(false);
  };
  // console.log(isMonth, isMonth === 'month', isMonth === 'month' ? ' table-card-title-active' : '')
  return (
    <div className="content contentRA  no-scroll">
      {/* <h1>{params.title}</h1> */}
      <div style={{ marginBottom: 30 }}>
        {/* <DatePicker
          onChange={(e) => {
            setYear(e.year());
          }}
          style={{marginRight: 10}}
          picker="year"
          value={moment(year, "YYYY")}
        /> */}
        {/* <DatePicker
          onChange={(e) => {
            setMonth(fixNum(e.month() + 1, 2));
            setYear(e.year());
          }}
          picker="month"
          value={moment(year + '/' + month, 'YYYY/MM')}
        /> */}
        <DatePick
          title={params.title}
          year={store.a ? parseInt(store.a.year) : new Date().getFullYear()}
          month={store.a ? parseInt(store.a.month) : new Date().getMonth() + 1}
          onChange={(e) => {
            setYear(e.year);
            setMonth(e.month);
          }}
        />
      </div>
      <div className="table-card-container">
        <div className="table-card">
          <div
            className={`table-card-title ${
              isMonth === "month" ? "table-card-title-active" : ""
            }`}
            onClick={() => {
              setIsMonth("month");
            }}
          >
            <span className="table-card-title-text">{`${month}月`}</span>
          </div>
          <div
            className={`table-card-title ${
              isMonth === "year" ? "table-card-title-active" : ""
            }`}
            onClick={() => {
              setIsMonth("year");
            }}
          >
            <span className="table-card-title-text">本年累计数</span>
          </div>
        </div>
        <Table
          dataSource={data}
          loading={isLoading}
          rowClassName={calc_name}
          columns={columns(year, false, isMonth)}
          pagination={false}
          scroll={{ y: innerHeight - 320 }}
          size="small"
        />
      </div>
      <Drawer
        title={title}
        placement="right"
        onClose={onClose}
        visible={showDetail}
        zIndex={500}
        width={window.innerWidth * 0.8}
      >
        <div className="table-card-container" style={{ overflow: "auto" }}>
          <div className="table-card">
            <div
              className={`table-card-title ${
                isMonth === "month" ? "table-card-title-active" : ""
              }`}
              onClick={() => {
                setIsMonth("month");
              }}
            >{`${month}月`}</div>
            <div
              className={`table-card-title ${
                isMonth === "year" ? "table-card-title-active" : ""
              }`}
              onClick={() => {
                setIsMonth("year");
              }}
            >
              本年累计数
            </div>
          </div>
          {detailData && (
            <Table
              rowClassName={calc_name}
              dataSource={detailData}
              loading={isLoadingDetail}
              columns={columns(year, true, isMonth)}
              pagination={false}
              scroll={{ y: innerHeight * 0.75 }}
              size="small"
            />
          )}
        </div>
      </Drawer>
      <Drawer
        title={titleSubject}
        placement="right"
        zIndex={1000}
        onClose={closeCompDetail}
        visible={showCompDetail}
        width={window.innerWidth * 0.8}
      >
        <Tools2 {...subjectData}></Tools2>
      </Drawer>
    </div>
  );
}

export default ContentR;
