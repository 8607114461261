import React, { useEffect, useState } from "react";
import "./biM.less";
import TotalSubject from "./biMChart/totalSubject";
import LineSale from './biMChart/LineSale'
import Income from './biMChart/income'
import Customer from './biMChart/customer'
import TopProfit from './biMChart/TopProfit'
import TopSale from "./biMChart/TopSale";
import TopProfitSelect from "./biMChart/TopProfitSelect";


function BiM() {

  return (
    <div className="bi-m">
      <TotalSubject/>
      <Income/>
      <LineSale/>
      <Customer />
      <TopProfit />
      <TopProfitSelect />
      <TopSale />
    </div>
  );
}

export default BiM;
