
import React, { useReducer, useContext} from 'react'

export const StoreContext = React.createContext({})

export const Reducer = (state, action) => {
    switch (action.type) {
        case 'changeStore':
            return { ...state, ...action.vals };
        default:
            throw new Error();
    }
}


export const useStore = ()=>{
    const {store, dispatch} = useContext(StoreContext)
    const setStore = (vals)=>{
        dispatch({vals, type: 'changeStore'})
    }
    window.store = store    
    return [store, setStore]
}

export default (props) => {

    const isSuper = localStorage.getItem('co_type') === 'HQ' ? true : false
    const [reducer, dispatch] = useReducer(Reducer, {coId: localStorage.getItem('coId'), isSuper})

    return <StoreContext.Provider value={{store: reducer, dispatch}}>
        {props.children}
    </StoreContext.Provider>
}