import { Statistic, message } from "antd";

export const displayNum = (value, unit, style, isW) => {
  if(!value){
    value = 0
  }
  if(!style){
    style = { fontSize: 14, textAlign: "right", minWidth: '100px' }
  }
  if (unit === "元") {
    value = isW ? value/10000 : value
    value = parseFloat(value.toFixed(2))
    return (
      <Statistic valueStyle={style} value={value}
      suffix={isW ? '万': undefined} precision={2}
      />
    );
  }
  // if (unit === "台") {
  //   return (
  //     <Statistic valueStyle={{ fontSize: 14 }} value={value} precision={0} />
  //   );
  // }
  if (unit === "%") {
    return <span style={{...style}}>{value && value !== Infinity ? (value*100).toFixed(2) : 0}%</span>;
  }
  value = parseFloat(value.toFixed(2))
  return <Statistic valueStyle={style} value={value} />;
};

export const increaseRate = (a, b)=>{
  if(!b){
    return 0
  }
  return (a-b) / Math.abs(b)
}

export const handleMonth = month =>{
  return month < 10 ? '0' + month.toString() : month.toString()
} 

export const handelResp = (resp, successMsg, errorMsg)=>{
  return new Promise((resolve, reject)=>{
    if(resp.code === 200){
      if(successMsg){
        message.success(successMsg)
      }
      resolve(resp.data)
    }else{
      if(errorMsg){
        message.error(errorMsg)
      }else{
        if(typeof(resp.msg)==='string'){
          message.error(resp.msg)
        }else{
          message.error(resp.msg.Message)
        }
      }
      reject()
    }
  })
}
// (process - total)/Math.abs(total) + 1 
export const doneRate = (process, total) => {
  // console.log(process, total)
  return total ? (process - total)/Math.abs(total) + 1  : 0
}
