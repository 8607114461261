import React, { useEffect, useState } from "react";
import { Table, Drawer, Popover } from "antd";
import http from "../../utils/http";
import { useParams } from "react-router-dom";
import { EyeOutlined, EditOutlined, RightOutlined } from "@ant-design/icons";
import "./contentRA.less";
import { useStore } from "./store";
import { displayNum, doneRate } from "../../utils";
import { TipsContent } from "./tips";
import Detail from "./rollDetail";

export function valueSet(value, origin, current, cal_type, isChild) {
  const vals = value.split("　");
  if (!origin) {
    origin = 0;
  }
  if (!current) {
    current = 0;
  }
  let num = current.length - origin.length;
  if (num < 0) {
    num = 0;
  }
  // console.log(origin, current)
  return (
    <span className="tips-text">
      <span style={{ marginLeft: isChild ? (num / 2) * 20 : 0 }}></span>

      {vals.length > 1 ? vals[vals.length - 1] : vals[0]}
      <span className="tips-icon-eye" style={{ marginLeft: "6px" }}>
        <RightOutlined
          style={{ visibility: cal_type === "02" ? "" : "hidden" }}
        />
      </span>
    </span>
  );
}

export function calc_name(record, index) {
  return "row-" + record.cal_type;
}

const tags = [
  //   {
  //     title: "同期数",
  //     prefix: "past1_value_m",
  //   },
  {
    title: "预算数",
    prefix: "estimate_value_m",
  },
  {
    title: "实绩/滚动预算",
    prefix: "achievement_value_m",
  },
];

function ContentR() {
  const params = useParams();
  // console.log(params)
  const [store, setStore] = useStore();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingDetail, setLoadingDetail] = useState(false);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [detailData, setDetailData] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [year, setYear] = useState(
    store.a ? parseInt(store.a.year) : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    store.a ? parseInt(store.a.month) : new Date().getMonth() + 1
  );
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [current, setCurrent] = useState();
  window.onresize = (e) => {
    setInnerHeight(window.innerHeight);
  };
  useEffect(() => {
    if (store.a) {
      setYear(store.a.year);
      setMonth(store.a.month);
    }
  }, [store]);
  const [isMonth, setIsMonth] = useState("month");
  const columns = (year, child, isMonth) => {
    const base = [
      {
        title: <span style={{ whiteSpace: "nowrap" }}>科目</span>,
        dataIndex: "subject_name",
        key: "subject_name",
        fixed: "left",
        width: 210,
        ellipsis: true,
        render: (val, row) => {
          const data = {};
          // console.log(store.a.month)
          // console.log(month)
          const m = store.a
            ? parseInt(store.a.month)
            : new Date().getMonth() + 1;
          Array(12)
            .fill(0)
            .forEach((_, index) => {
              data[`estimate_value_m${index < 9 ? "0" : ""}${index + 1}`] =
                row[`estimate_value_m${index < 9 ? "0" : ""}${index + 1}`];
              if (index >= m) {
                data[`achievement_value_m${index < 9 ? "0" : ""}${index + 1}`] =
                  row[`rolling_value_m${index < 9 ? "0" : ""}${index + 1}`];
              } else {
                data[`achievement_value_m${index < 9 ? "0" : ""}${index + 1}`] =
                  row[`achievement_value_m${index < 9 ? "0" : ""}${index + 1}`];
              }
            });
          data["unit"] = row.unit;
          data["m"] = m;
          data["hasMPrefix"] = "achievement_value_m";
          // console.log(data)
          return child ? (
            // <Popover
            //   placement="right"
            //   title={val}
            //   content={getContent(tags, data)}
            // >
            //   <span style={{ whiteSpace: "nowrap" }}>
            //     {valueSet(
            //       val,
            //       detailData.length ? detailData[0].subject_id : "",
            //       row.subject_id,
            //       row.cal_type,
            //       child
            //     )}
            //   </span>
            // </Popover>
            <TipsContent title={val} tags={tags} data={data}>
              {
                <span style={{ whiteSpace: "nowrap" }}>
                  {valueSet(
                    val,
                    detailData.length ? detailData[0].subject_id : "",
                    row.subject_id,
                    row.cal_type,
                    child
                  )}
                </span>
              }
            </TipsContent>
          ) : row.cal_type === "00" ? (
            <h3 className="subject-title">{val}</h3>
          ) : (
            <TipsContent title={val} tags={tags} data={data}>
              <span
                style={{ whiteSpace: "nowrap" }}
                className={`${row.cal_type === "02" ? "hover" : ""}`}
                onClick={async () => {
                  // console.log('=====')
                  if (!(row.cal_type === "02")) return;
                  setShowDetail(true);
                  setLoadingDetail(true);
                  setDetailData([]);
                  setTitle(val);
                  const resp = await http.get(
                    "/base/selectTPageSubjectExt05b",
                    {
                      subject_id: row.subject_id,
                      co_id: store.coId,
                      year,
                      month: (month < 10 ? "0" : "") + month.toString(),
                    }
                  );
                  if (resp.code === 200) {
                    setDetailData(resp.data.tPageSubjectExt05s);
                  }
                  setLoadingDetail(false);
                }}
              >
                {valueSet(
                  val,
                  data.length ? data[0].subject_id : "",
                  row.subject_id,
                  row.cal_type
                )}
              </span>
            </TipsContent>
          );
        },
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>{`${
            parseInt(month) + 1
          }~12月需完成`}</span>
        ),
        dataIndex: "need",
        key: "need",
        align: "right",
        width: "120px",
        render: (val, row) =>
          row.cal_type === "00"
            ? ""
            : displayNum(
                row.estimate_value_year - row.achievement_value_year,
                row.unit
              ),
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap" }}>{`${
            parseInt(month) + 1
          }~12月滚动预算`}</span>
        ),
        dataIndex: "rolling_value_mty",
        key: "rolling_value_mty",
        width: "140px",
        align: "right",
        render: (val, row) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {row.cal_type === "00" ? "" : displayNum(val, row.unit)}
            {row.cal_type === "01" && params.type !== "RR" ? (
              <EditOutlined
                className="hover"
                style={{ marginLeft: "10px" }}
                onClick={() => setCurrent(row)}
              />
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>全年预算</span>,
        dataIndex: "estimate_value_year",
        key: "estimate_value_year",
        align: "right",
        render: (val, row) =>
          row.cal_type === "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>全年预估</span>,
        dataIndex: "all",
        key: "all",
        align: "right",
        render: (val, row) =>
          row.cal_type === "00"
            ? ""
            : displayNum(
                row.achievement_value_YTM + row.rolling_value_mty,
                row.unit
              ),
      },
      {
        title: (
          <span style={{ whiteSpace: "nowrap", marginRight: "10px" }}>
            全年预估完成率
          </span>
        ),
        key: "4",
        align: "right",
        render: (val, row) => {
          return row.cal_type === "00" ? (
            ""
          ) : (
            <span style={{ marginRight: "10px" }}>
              {row.estimate_value_year
                ? (
                    ((row.achievement_value_YTM + row.rolling_value_mty) /
                      row.estimate_value_year) *
                    100
                  ).toFixed(2)
                : 0}
              %
            </span>
          );
        },
      },
    ];
    const months = [
      {
        title: <span style={{ whiteSpace: "nowrap" }}>预算数</span>,
        dataIndex: "estimate_value_month",
        key: "estimate_value_month",
        align: "right",
        width: 150,
        render: (val, row) =>
          row.cal_type == "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>实绩数</span>,
        dataIndex: "achievement_value_month",
        key: "achievement_value_month",
        align: "right",
        width: 150,
        render: (val, row) => (
          <span className="import-text">
            {row.cal_type === "00" ? "" : displayNum(val, row.unit)}
          </span>
        ),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>差额</span>,
        key: "5",
        align: "right",
        width: 150,
        render: (val, row) => {
          const rate = doneRate(
            row.achievement_value_month,
            row.estimate_value_month
          );
          return row.cal_type === "00" ? (
            ""
          ) : (
            <span>
              {displayNum(
                row.achievement_value_month - row.estimate_value_month,
                row.unit
              )}
            </span>
          );
        },
      },
    ];
    const years = [
      {
        title: <span style={{ whiteSpace: "nowrap" }}>预算数</span>,
        dataIndex: "estimate_value_YTM",
        key: "estimate_value_YTM",
        align: "right",
        width: 150,
        render: (val, row) =>
          row.cal_type === "00" ? "" : displayNum(val, row.unit),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>实绩数</span>,
        dataIndex: "achievement_value_YTM",
        key: "achievement_value_YTM",
        align: "right",
        width: 150,
        render: (val, row) => (
          <span className="import-text">
            {row.cal_type === "00" ? "" : displayNum(val, row.unit)}
          </span>
        ),
      },
      {
        title: <span style={{ whiteSpace: "nowrap" }}>差额</span>,
        key: "3",
        align: "right",
        width: 150,
        render: (val, row) => {
          const rate = doneRate(
            row.achievement_value_YTM,
            row.estimate_value_YTM
          );
          return row.cal_type === "00" ? (
            ""
          ) : (
            <span>
              {displayNum(
                row.achievement_value_YTM - row.estimate_value_YTM,
                row.unit
              )}
            </span>
          );
        },
      },
    ];
    months.forEach((val) => {
      val.fixed = "left";
      val.width = 120;
    });
    years.forEach((val) => {
      val.fixed = "left";
      val.width = 120;
    });
    if (isMonth === "month") {
      base.splice(1, 0, ...months);
    } else {
      base.splice(1, 0, ...years);
    }
    return base;
  };
  const getData = async () => {
    setData([]);
    setLoading(true);
    const resp = await http.get("/base/selectTPageSubjectExt05", {
      page_id: params.page_id,
      co_id: store.coId,
      year,
      month: (month < 10 ? "0" : "") + month.toString(),
    });
    // console.log((month < 9 ? "0" : "") + month.toString());
    // console.log(month);
    if (resp.code === 200) {
      setData(resp.data.tPageSubjectExt05s);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [params, year, month, store]);

  const onClose = () => {
    setShowDetail(false);
  };
  if (parseInt(month) === 12) {
    return <div></div>;
  }
  return (
    <div className="content no-scroll contentR">
      <div className="table-card-container">
        <div className="table-card" style={{ width: 360 }}>
          <div
            className={`table-card-title ${
              isMonth === "month" ? "table-card-title-active" : ""
            }`}
            onClick={() => {
              setIsMonth("month");
            }}
          >
            <span className="table-card-title-text">{`${month}月`}</span>
          </div>
          <div
            className={`table-card-title ${
              isMonth === "year" ? "table-card-title-active" : ""
            }`}
            onClick={() => {
              setIsMonth("year");
            }}
          >
            <span className="table-card-title-text">本年累计数</span>
          </div>
        </div>
        <Table
          dataSource={data}
          loading={isLoading}
          rowClassName={calc_name}
          columns={columns(year, false, isMonth)}
          pagination={false}
          scroll={{ y: innerHeight - 250 }}
          size="small"
        />
      </div>
      {current && (
        <Detail
          cancel={() => {
            setCurrent();
            getData();
          }}
          value={current}
          year={year}
          month={month}
        />
      )}
    </div>
  );
}

export default ContentR;
