import { useState, useEffect } from 'react'
import http from "../../utils/http";

export default () => {
    const [infos, setInfos] = useState([])
    const getInfo = async () => {
        // /base/getUserInfo
        const resp = await http.get("/base/getUserInfo");
        if (resp.code === 200) {
            localStorage.setItem('co_type', resp.data.mUserExt01s[0].co_type)
            localStorage.setItem('co_name', resp.data.mUserExt01s[0].co_name)
            localStorage.setItem('display_name', resp.data.mUserExt01s[0].display_name)
            setInfos(resp.data.mUserExt01s)
        }
    }

    useEffect(() => {
        getInfo()
    }, [])
    return <div className="welcome" >
        <h1>登陆者:{localStorage.getItem('displayName')}</h1>
        <p style={{width: '70%', display: 'flex', justifyContent: 'space-between'}}><span>角色</span> <span>角色描述</span></p>
        {infos.map((val, index)=>{
            return <p key={index} style={{width: '70%', display: 'flex', justifyContent: 'space-between'}}>
                <span>{val.role_name}</span> <span>{val.role_detail}</span>
                </p>
        })}
    </div>
}