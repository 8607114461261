import React from 'react';
import './style.less';

const props = [
  {
    label: "总结概括",
    type: "textArea",
    name: "desc",
  }
]

function Desc(props) {
  const {desc} = props
  return (
    <div className='rg-desc' contenteditable="true">
      {desc && desc.split('\n').map((txt,index)=>{
        return <p key={index}>{txt}</p>
      })}
      
    </div>
  );
}

export default {Comp: Desc, props};
