import React, { useEffect, useState } from "react";
import http from "../../../utils/http";
import { useStore } from "../store";
import Top from "./Tops";

function TopProfit() {
  const [store, _] = useStore();

  const [data, setData] = useState([]);
  const getCompData = async () => {
    const dts = [];
    const resp = await http.get("/base/selectMCompanyExt01", {
      page_id: "auditec",
      page_size: 100,
      page: 1,
    });
    if (resp.code === 200) {
      if (resp.data.mCompanyExt01s) {
        const mCompanyExt01s = resp.data.mCompanyExt01s;
        for (let index = 0; index < mCompanyExt01s.length; index++) {
          const element = mCompanyExt01s[index];
          const data = await getData(element.id);
          //   console.log(data);
          dts.push({
            name: element.co_name,
            // e: data.estimate_data.subject_value_ytm,
            value: data.achievement_data.subject_value_ytm,
          });
        }
        const d = dts.sort((a, b) => a.value - b.value)
        setData(d);
      }
    }
  };

  const getData = async (id) => {
    const resp = await http.put("/indi/getOneIndicatorMonthlyYTM", {
      co_id: id,
      month: store && store.a && store.a.month,
      subject_ids: ["704010"],
      year: store && store.a ? store.a.year : new Date().getFullYear(),
    });
    return resp.data.subjectEAReportData;
  };

  useEffect(() => {
    if (store.a) {
      getCompData();
    }
  }, [store]);

    // useEffect(()=>{
    //   console.log(data)
    // }, [data])

  return (
    <div className="top-chart" style={{ }}>
      <h2>各4s店当年累计净利排名</h2>
      <Top data={data} isW={true} />
    </div>
  );
}

export default TopProfit;
