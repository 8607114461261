import React, { useEffect, useState, useRef } from "react";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);

const option = {
  title: {
    // text: 'World Population'
  },
  tooltip: {
    trigger: "axis",

    axisPointer: {
      type: "shadow",
    },
    valueFormatter: (value) => value.toFixed(2),
  },
  //   legend: {},
  grid: {
    top: "1%",
    left: "1%",
    right: "1%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    axisLabel: {
      formatter: "{value} 万",
    },
  },
  yAxis: {
    type: "category",
    data: [],
    splitLine: {
      show: true,
    },
  },
  series: [
    {
      name: "预算",
      type: "bar",
      stack: "total",
      data: [],
      color: "#42E0FC",
    },
    {
      name: "实绩",
      type: "bar",
      stack: "total",
      data: [],
      color: "#9013FE",
    },
  ],
};

export const parseW = (val) => {
  // console.log(val)
  return val / 10000;
};
export default (props) => {
  const { data, isW } = props;
  const ref = useRef();

  const setChart = (eDatas, aDatas, cate) => {
    if (!ref.current) return;
    // console.log("e", eDatas);
    // console.log("a", aDatas);
    option.series[0].data = eDatas;
    option.series[1].data = aDatas;
    option.yAxis.data = cate;
    const chart = echarts.init(ref.current);
    if (!isW) {
      option.xAxis.axisLabel.formatter = "{value}";
    } else {
      option.xAxis.axisLabel.formatter = "{value}万";
    }
    chart.setOption(option);
  };

  const setChartSingle = (data, cate) => {
    if (!ref.current) return;
    // option.series[0].data = eDatas;
    // option.series[1].data = aDatas;
    const opt = { ...option };
    opt.series = [
      {
        name: "",
        type: "bar",
        stack: "total",
        data,
        color: "#9013FE",
      },
    ];
    opt.yAxis.data = cate;
    const chart = echarts.init(ref.current);
    if (!isW) {
        option.xAxis.axisLabel.formatter = "{value}";
      } else {
        option.xAxis.axisLabel.formatter = "{value}万";
      }
    if (!isW) {
      opt.xAxis.axisLabel.formatter = "{value}";
    }
    chart.setOption(opt);
  };

  const handle = () => {
    if (!data) return;
    if (data.length > 10){
        data.splice(data.length-1, data.length - 10)
    }
    const eDatas = [];
    const aDatas = [];
    const cate = [];
    const datas = [];

    for (let index = 0; index < data.length; index++) {
        if(data.length > 10 && index < data.length - 10){
            continue
        }
      const element = data[index];
      const keys = Object.keys(element);

      if (keys.indexOf("a") > -1) {
        eDatas.push(isW ? parseW(element.e) : element.e);
        aDatas.push(isW ? parseW(element.a) : element.a);
      } else {
        datas.push(isW ? parseW(element.value) : element.value);
      }

      cate.push(
        element.name.length > 6 ? element.name.slice(0, 4) : element.name
      );
    }
    //    console.log(eDatas, aDatas, cate)
    if (aDatas.length) {
      setChart(eDatas, aDatas, cate);
    } else {
      setChartSingle(datas, cate);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handle();
    }, 1000);
  }, [data]);

  return (
    <div style={{ width: "100%" }}>
      <div
        // className="chart"
        ref={ref}
        style={{ width: "100%", height: "400px" }}
      ></div>
    </div>
  );
};
