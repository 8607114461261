import { RemoveObserver, DisposeObserver } from '../../utils/observers';
import { CloseOutlined } from '@ant-design/icons';
import './index.less';

// interface IEventCover {
//   children?: any;
//   noMask?: boolean;
//   eventData: TEventData;
// }

export default function Index({ noMask = false, children, eventData }) {
  return (
    <div
      className={`form-sandbox__payground__item ${(!noMask && 'form-sandbox__payground--mask') || 'under-delete'}`}
      onClick={(e) => {
        e.stopPropagation();
        DisposeObserver.notify(eventData);
      }}
    >
      {eventData.current[eventData.idx].compType !== "divide" && <div
        className='form-sandbox__payground--delete'
        onClick={(e) => {
          e.stopPropagation();
          RemoveObserver.notify(eventData);
        }}
      >
        <CloseOutlined />
      </div>}
      {children}
    </div>
  );
}
