import React, { useState, useEffect } from "react";
import { Modal, Input, Form, Select, message } from "antd";
import http from "../../utils/http";
import { handelResp } from "../../utils";

function Change(props) {
  const [comps, setComps] = useState([]);
  const [form] = Form.useForm();
  const userId = localStorage.getItem("userId");
  const isSuper = localStorage.getItem("co_type") === "HQ" ? true : false;
  const getCompData = async () => {
    setComps([]);
    // const resp = await http.get("/base/selectMCompanyExt01", {
    //   page_id: "auditac",
    //   page_size: 100,
    //   page: 1,
    // });
    // handelResp(resp).then(() => {
    //   setComps(resp.data.mCompanyExt01s ? resp.data.mCompanyExt01s : []);
    // })
  };
  useEffect(() => {
    if (isSuper) {
      getCompData();
    }
  }, []);

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        http.put("/base/changePW", {
          // id: isSuper ? values.id : userId,
          id: userId,
          password: values.password,
        }).then(resp => {
          handelResp(resp, '修改成功，请重新登陆').then(() => {
            props.onCancel()
            localStorage.removeItem("token");
            window.location = `//${window.location.host}/login`;
          })
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div>
      <Modal
        visible={true}
        title="修改密码"
        onCancel={() => props.onCancel()}
        onOk={onOk}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          {/* {isSuper && (
            <Form.Item
              label="选择公司"
              name="id"
              rules={[{ required: true }]}
              defaultValue={userId}
              initialValue={userId}
            >
              <Select defaultValue={userId}>
                <Select.Option key={userId} value={userId}>
                  {localStorage.getItem("co_name")}
                </Select.Option>
                {comps.map((val) => {
                  return (
                    <Select.Option key={val.id} value={val.id}>
                      {val.co_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )} */}
          <Form.Item
            label="新密码"
            name="password"
            rules={[{ required: true }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            label="确认新密码"
            name="password2"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("两次输入密码不一致!"));
                },
              }),
            ]}
          >
            <Input type="password" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Change;
