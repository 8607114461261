import React, { useEffect, useState } from "react";
import { displayNum, handelResp, handleMonth } from "../../../utils";
import { Select, Table, Radio, Switch, Button, Modal, Input, Tag } from "antd";
import DatePicker from "../../../component/datepick";
import { useStore } from "../store";
import http from "../../../utils/http";
import Ytm from "./ytm";
// import YtmBar from "./ytmBar";
import MonthChartComp from "../../../component/report_generator/sandbox/components/charts/MonthChart";
const MonthChart = MonthChartComp.Comp
function ToolsChart() {
  const [indicatorList, setIndicatorList] = useState([]);
  const [ids, setIds] = useState(null);
  const [name, setName] = useState(null);
  const [opt, setOpt] = useState("e");
  const [aData, setaData] = useState();
  const [store, _] = useStore();
  const [isW, setIsW] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(
    store.a ? parseInt(store.a.year) : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    store.a ? parseInt(store.a.month) : new Date().getMonth() + 1
  );

  const getIndicatorList = async () => {
    const resp = await http.get("/indi/selectIndicatorList");
    handelResp(resp).then((data) => {
      setIndicatorList(data.indicatorLists);
    });
  };

  useEffect(() => {
    getIndicatorList();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const resp = await http.put("/indi/getOneIndicatorMonthlyYTM", {
      co_id: store.coId,
      month: month ? handleMonth(month) : store.a && store.a.month,
      subject_ids: [ids],
      year: year ? year.toString() : store.a && store.a.year,
    });
    // console.log(resp)
    if (resp.code === 200) {
      handelResp(resp).then((data) => {
        setaData(data.subjectEAReportData);
      })
    }

    setIsLoading(false);
  };
  const handleChange = async (ids) => {
    const items = indicatorList.filter(val=>ids === val.id)
    // console.log(items)
    if(items.length){
      setName(items[0].ind_name)
    }
    setIds(ids);
  };

  // useEffect(() => {
  //   if (ids) getData();
  // }, [ids, month, year, opt, store]);
  const Y = year ? year.toString() : store.a && store.a.year
  const M = month ? handleMonth(month) : store.a && store.a.month
  const co_id = store.coId
  const sid = {id: ids, ind_name: name}
  return (
    <div className="content">
      <DatePicker
        title={"选择时间"}
        canNotShowUp={true}
        year={store.a ? parseInt(store.a.year) : new Date().getFullYear()}
        month={
          opt === "a"
            ? store.a
              ? parseInt(store.a.month)
              : new Date().getMonth() + 1
            : null
        }
        onChange={(e) => {
          setYear(e.year);
          if (e.month) setMonth(e.month);
        }}
      />
      <div
        className="search-filter"
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Select
          showSearch
          // mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          allowClear
          style={{ width: "100%" }}
          placeholder="请选择指标"
          //   defaultValue={[]}
          value={ids}
          onClear={() => {
            setIds([]);
            setaData();
          }}
          onChange={handleChange}
        >
          {indicatorList.map((val, index) => {
            return (
              <Select.Option key={val.id} title={val.ind_describe}>
                {val.ind_name}
              </Select.Option>
            );
          })}
        </Select>
      </div>

      <div
        style={{ display: "flex", justifyContent: "flex-end", margin: "5px 0" }}
      >
        <div>
          万元：
          <Switch
            size="small"
            value={isW}
            onChange={(flag) => {
              console.log(flag);
              setIsW(flag);
            }}
          />
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", flexWrap: "wrap" }}>
        {/* <Ytm data={aData} prefix="subject_value" title="趋势" isW={isW} /> */}
        {/* <Ytm data={aData} prefix="subject_value_ytm" title="累计" isW={isW} /> */}
        {/* <YtmBar data={aData} prefix="subject_value" title="趋势" isW={isW} /> */}
        {name && <div style={{width: '50%'}}><MonthChart year={Y} month={M} co_id={co_id} sid={sid} chartType="line" height={300} isW={isW} /></div>}
        {name && <div style={{width: '50%'}}><MonthChart year={Y} month={M} co_id={co_id} sid={sid} chartType="line" height={300} isYtm isW={isW}/></div>}
        {name && <div style={{width: '50%'}}><MonthChart year={Y} month={M} co_id={co_id} sid={sid} chartType="bar" height={300} isW={isW}/></div>}
        {name && <div style={{width: '50%'}}><MonthChart year={Y} month={M} co_id={co_id} sid={sid} chartType="line" height={300} isF isW={isW}/></div>}
      </div>
    </div>
  );
}

export default ToolsChart;
