import { useEffect, useReducer, useState, useRef } from "react";
import classnames from "classnames";
import { ReactSortable } from "react-sortablejs";
import { Button, DatePicker, Menu, Modal } from "antd";
import renderFormItem from "./components/formItem";
import _cloneDeep from "lodash-es/cloneDeep";
import {
  arrayToTree,
  arraySwap,
  mapSelected,
  flat_page,
  parsePages,
  handleDivPage,
} from "./utils/methods";
// import { ItemType, ICtRdr, EEvt, TEventData } from './types/sandbox';
import { RGStoreContext } from "../store";
import { RemoveObserver, DisposeObserver } from "./utils/observers";
import Dispose from "./components/dispose";
import defaultFormData from "./utils/defaultFormData";
import { component, groupComponent } from "./components";
import "./index.less";
import Preview from "./components/preview";
import { Header, Footer } from "./components/page";
import { useStore } from "../../../pages/content/store";
import dayjs from "dayjs";
import { settingSystem } from "./components/index";
import _uniqueId from "lodash-es/uniqueId";

const EEvt = {
  ON_ADD: "add",
  ON_REMOVE: "remove",
  ON_UPDATE: "update",
  ON_RESET: "reset",
};
const { SubMenu } = Menu;

// const component = [
//   { compId: '1', compName: '栅格', compType: 'wrap' },
//   { compId: '2', compName: '输入框', compType: 'input' },
//   { compId: '3', compName: '下拉选择器', compType: 'selector' },
//   { compId: '4', compName: '日期选择器', compType: 'date-picker' },
// ];

/**
 * 组件嵌套
 * 1、组件itemId随机（form-key-xxx），作为mapKey，确保不重复
 * 2、创建数组（all）收集所有表单，用itemId和parentId关联
 * 3、all数组转树结构
 * 4、all中筛除已存在的表单项，插入新的表单项
 */
/**
 * sortable现象
 * 1、鼠标点击sortable组件会触发setList，影响state逻辑，改用on-event方法
 * 2、event行为
 *    2.1、把子组件抽取到父同级组件，父组件onAdd的调用早于子组件onRemove
 *    2.2、把父同级组件抽取到子组件，子组件onAdd的调用早于父组件onRemove
 */
// interface IInitContainer {
//   value: ItemType[];
//   hash: { [key: string]: ItemType };
// }

// const STORAGE_KEY = "container_data";

const cloneDefaultData = _cloneDeep(defaultFormData);
const initContainer = {
  value: [],
  hash: {},
};
let allSelected = [];

function containerReducer(state, action) {
  let current = null;
  const { eventType = "", selected, toDelete } = action;
  switch (eventType) {
    case EEvt.ON_RESET:
      current = selected;
      break;
    case EEvt.ON_REMOVE:
      const isDeleteItem = (t) =>
        t.itemId === toDelete.itemId && t.parentId === toDelete.parentId;
      current = allSelected.filter((t) => !isDeleteItem(t));
      break;
    default:
      // wrap中子组件的新增操作
      current = [
        // 4、all中筛除已存在的表单，插入新的表单项，避免vdom.key重复渲染
        ...allSelected.filter(
          (t) => selected.findIndex((f) => f.itemId === t.itemId) === -1
        ),
        ...selected,
      ];
  }
  allSelected = current;
  // console.log("current", current);
  const [tree, map] = arrayToTree(current, null);
  console.log("tree map", tree, map);
  return {
    value: tree,
    hash: map,
  };
}

function Index(props) {
  const { current, onSave, exit } = props;
  const [components, setComponents] = useState(component);
  const [pages, setPage] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [containerState, dispatchContainer] = useReducer(
    containerReducer,
    initContainer
  );
  const [showDispose, toggleDispose] = useState(false);
  const [currentDispose, selectDispose] = useState({});
  const [previewData, setPreviewData] = useState();
  const [store, setStore] = useStore();
  const [monthDate, setMonthDate] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const ref = useRef();
  const [theme, setTheme] = useState('')

  useEffect(() => {
    if (current.id) {
      // console.log('current', current)
      const tmpData = JSON.parse(current.templ_json);
      setPage(tmpData);
      setCurrentPage(1);
      dispatchContainer({
        eventType: EEvt.ON_RESET,
        // selected: mapSelected(_cloneDeep(tmpData[0])),
        selected: mapSelected(_cloneDeep(flat_page(tmpData))),
      });
    } else {
      if (!pages.length) addPage();
      setCurrentPage(1);
    }
  }, [current]);

  const diffComponent = (selected, eventType, parent, toDelete) => {
    // 2、创建数组递归收集所有表单，用itemId和parentId关联
    // * 深拷贝select防止对象引用导致dom.key重复
    const cloneParent = (parent && _cloneDeep(parent)) || null;
    console.log("selected", selected);
    const cloneSelected = (selected.length && _cloneDeep(selected)) || [];
    console.log("cloneSelected", cloneSelected);
    const tempSelected = mapSelected(cloneSelected, cloneParent);
    console.log("tempSelected", tempSelected);
    dispatchContainer({
      eventType,
      selected: tempSelected,
      toDelete,
    });
  };

  const findComp = (el) => {
    const compType = el.getAttribute("comp-type");
    const comp = components.find((f) => f.compType === compType);
    return comp;
  };

  const addComponent = (evt, target, parent) => {
    const { item, newDraggableIndex } = evt;
    const comp = findComp(item);
    // 非注册组件返回
    if (!comp) return;
    const selected = target;
    selected.splice(newDraggableIndex, 0, comp);
    diffComponent(selected, EEvt.ON_ADD, parent);
  };

  const updateComponent = (evt, target, parent) => {
    const { newDraggableIndex, oldDraggableIndex } = evt;
    const selected = arraySwap(
      _cloneDeep(target),
      oldDraggableIndex,
      newDraggableIndex
    );
    diffComponent(selected, EEvt.ON_UPDATE, parent);
  };

  const removeComponent = (evt, target, parent) => {
    const { oldDraggableIndex } = evt;
    const selected = target;
    const toDelete = selected[oldDraggableIndex];
    selected.splice(oldDraggableIndex, 1);
    diffComponent(selected, EEvt.ON_REMOVE, parent, toDelete);
  };

  const resetComponent = () => {
    dispatchContainer({
      eventType: EEvt.ON_RESET,
      selected: cloneDefaultData,
    });
  };

  const saveFormData = (isNew) => {
    // sessionStorage.setItem(STORAGE_KEY, JSON.stringify(containerState.value));
    // pages[currentPage - 1] = _cloneDeep(containerState.value);
    if (onSave) {
      // onSave(pages, isNew);
      onSave(parsePages(_cloneDeep(containerState.value)), isNew);
    }
  };

  const preview = () => {
    // setPreviewData([_cloneDeep(containerState.value)]);
    setPreviewData(parsePages(_cloneDeep(containerState.value)))
  };

  const resetFormData = () => {
    // const cfDialog = DialogPlugin.confirm({
    //   header: '提示',
    //   body: '确认重置表单吗？',
    //   theme: 'warning',
    //   onConfirm: () => {
    //     resetComponent();
    //     cfDialog.hide();
    //   },
    //   onClose: () => {
    //     cfDialog.hide();
    //   },
    // });
  };

  const reRender = () => {
    // setCurrentPageData(currentPage - 1);
    dispatchContainer({
      eventType: EEvt.ON_RESET,
      // selected: mapSelected(_cloneDeep(tmpData[0])),
      selected: mapSelected(_cloneDeep(containerState.value)),
    });
  };

  function renderChildContainer(item) {
    return (
      <ReactSortable
        tag={"div"}
        className={classnames(
          `form-sandbox__payground__wrap`,
          `form-sandbox__payground__wrap--direction--${item.formData?.layout}`
        )}
        group={{
          name: "component",
          pull: true,
          put: true,
        }}
        swap
        direction={"horizontal"}
        fallbackOnBody={true}
        swapThreshold={1}
        animation={200}
        list={item.children}
        onUpdate={(e) => {
          console.log("child-onUpdate操作------------------->");
          updateComponent(e, item.children, item);
        }}
        onAdd={(e) => {
          console.log(
            "child-onAdd操作------------------->",
            _cloneDeep(item.children)
          );
          addComponent(e, item.children, item);
        }}
        onRemove={(e) => {
          console.log("child-onRemove操作------------------->");
          removeComponent(e, item.children, item);
        }}
        // 废弃，用on-event代替
        setList={() => {}}
      >
        {(item.children &&
          renderFormItem(item.children, {
            renderChild: renderChildContainer,
            parent: item,
          })) ||
          null}
      </ReactSortable>
    );
  }

  const listionPageScroll = e=>{
    // console.log(e.target.scrollTop/1560)
    setCurrentPage(parseInt(e.target.scrollTop/1560)+1)
  }

  useEffect(() => {
    RemoveObserver.watch((o) => {
      console.log("删除操作来源：", o);
      removeComponent({ oldDraggableIndex: o.idx }, o.current, o.parent);
    });
    DisposeObserver.watch((o) => {
      toggleDispose(true);
      selectDispose(o);
    });
    // 设置默认值
    // const storageData = sessionStorage.getItem(STORAGE_KEY);
    // diffComponent((storageData && JSON.parse(storageData)) || cloneDefaultData);
    // if(!pages.length)addPage();
    return () => {
      allSelected = [];
      diffComponent([]);
      RemoveObserver.destroy();
      DisposeObserver.destroy();
    };
  }, []);

  const scrollTo = num=>{
    ref.current.scrollTop = 1560 * num
  }

  const addPage = () => {
    const page = [];
    // console.log("pages", _cloneDeep(pages));
    // pages[currentPage - 1] = _cloneDeep(containerState.value);
    // pages.push(page);
    const pages = parsePages(_cloneDeep(containerState.value))
    pages.splice(currentPage, 0, page);
    const cur = currentPage + 1;
    setCurrentPage(cur);
    setPage(pages);
    dispatchContainer({
      eventType: EEvt.ON_RESET,
      selected: mapSelected(_cloneDeep(flat_page(pages))),
    });
    scrollTo(currentPage)
  };

  const minusPage = () => {
    const pages = parsePages(_cloneDeep(containerState.value))
    pages.splice(currentPage - 1, 1);
    console.log("pages length", pages.length, "currentPage", currentPage);
    let page = null;
    let pageNum = currentPage - 1;

    if (pageNum > pages.length - 1) {
      page = _cloneDeep(pages[pages.length - 1]);
      pageNum = pages.length - 1;
    } else {
      page = _cloneDeep(pages[pageNum]);
    }

    setPage([...pages]);
    setCurrentPage(pageNum + 1);
    dispatchContainer({
      eventType: EEvt.ON_RESET,
      selected: mapSelected(_cloneDeep(flat_page(pages))),
    });
    scrollTo(pageNum)
  };

  const setCurrentPageData = (page) => {
    // pages[currentPage - 1] = _cloneDeep(containerState.value);
    // setPage(pages);
    setCurrentPage(page + 1);
    // console.log("setCurrentPage", _cloneDeep(pages[page]));
    // dispatchContainer({
    //   eventType: EEvt.ON_RESET,
    //   selected: mapSelected(_cloneDeep(pages[page])),
    // });
    // console.log(ref.current);
    scrollTo(page)
  };
  // console.log(currentPage, containerState.value);
  // console.log(components)
  const monthFormat = "YYYY-MM";
  const getDateDefaultValue = () => {
    if (!store.a) return;
    const mdt = store.a.year + "-" + store.a.month;
    return dayjs(mdt, monthFormat);
  };

  useEffect(() => {
    if (store.a) {
      // console.log('store', store)
      const mdt = store.a.year + "-" + store.a.month;
      setMonthDate(mdt);
    }
  }, [store]);
  useEffect(()=>{
    console.log('ref:', ref)
    handleDivPage(containerState.value, ref.current.lastChild.childNodes)
  }, [containerState])
  const monthValueChange = (date, dateString) => {
    // console.log(dateString, date)
    setMonthDate(dateString);
  };
  return (
    <RGStoreContext.Provider value={{ containerState }}>
      <div className="form-sandbox__main">
        <div className="form-sandbox__components">
          <div className="form-sandbox__components--picker">
            <DatePicker
              style={{ width: "100%" }}
              onChange={monthValueChange}
              defaultValue={getDateDefaultValue()}
              format={monthFormat}
              picker="month"
            />
          </div>
          {/* <p className="form-sandbox__components--title">选择组件</p> */}
          <div className="components-title">素材库</div>
          <Menu
            theme="light"
            mode="inline"
            defaultOpenKeys={["chart-table"]}
            selectedKeys={[]}
            style={{
              // height: "calc(100vh - 112px)",
              overflow: "auto",
              border: 0,
              // backgroundColor: '#FAFAFA'
            }}
          >
            {groupComponent.map((group) => {
              return (
                <SubMenu key={group.key} title={group.name}>
                  <ReactSortable
                    tag={"div"}
                    group={{
                      name: "component",
                      pull: "clone",
                      put: false,
                    }}
                    sort={false}
                    list={group.components}
                    setList={(data) => {
                      // setComponents(data);
                    }}
                  >
                    {group.components.map((item) => {
                      return (
                        <div
                          key={item.compId}
                          comp-type={item.compType}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Menu.Item key={_uniqueId()} selectable={false}>
                            {item.compName}
                          </Menu.Item>
                        </div>
                      );
                    })}
                  </ReactSortable>
                </SubMenu>
              );
            })}
          </Menu>
          {/* <ReactSortable
            tag={"div"}
            group={{
              name: "component",
              pull: "clone",
              put: false,
            }}
            sort={false}
            list={components}
            setList={(data) => {
              setComponents(data);
            }}
          >
            {components.map((item) => (
              <div
                className={`form-sandbox__components__item form-sandbox__components--${item.compType}`}
                key={item.compId}
                comp-type={item.compType}
              >
                {item.compName}
              </div>
            ))}
          </ReactSortable> */}
        </div>
        <div className="form-sandbox__content">
          <div
            className="btn-opt"
            style={{
              width: "100%",
              background: "#FAFAFA",
              borderRadius: "4px",
              marginBottom: 4,
              padding: 15,
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="pages" style={{ display: "flex" }}>
                {/* {pages.map((_, index) => (
                <div className="btn" key={index} style={{ marginRight: 10 }}>
                  <Button
                    type={index + 1 === currentPage ? "primary" : "default"}
                    onClick={() => setCurrentPageData(index)}
                  >
                    {index + 1}
                  </Button>
                </div>
              ))}
              <div className="btn" style={{ marginRight: 10 }}>
                <Button onClick={addPage}>+</Button>
              </div>
              {pages.length > 1 && (
                <div className="btn">
                  <Button onClick={minusPage}>-</Button>
                </div>
              )} */}
              </div>
              <div className="form-sandbox__operation">
                <Button
                  type="primary"
                  style={{ marginRight: 10 }}
                  onClick={reRender}
                >
                  重排
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 10 }}
                  onClick={preview}
                >
                  预览
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => saveFormData(false)}
                >
                  保存
                </Button>
                {current.id && (
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => saveFormData(true)}
                  >
                    另存为
                  </Button>
                )}
                <Button
                  type="primary"
                  danger
                  style={{ marginRight: 0 }}
                  onClick={exit}
                >
                  退出
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div style={{ display: "flex" }}>
                {pages.map((_, index) => (
                  <div className="btn" key={index} style={{ marginRight: 10 }}>
                    <Button
                      type={index + 1 === currentPage ? "primary" : "default"}
                      onClick={() => setCurrentPageData(index)}
                    >
                      {index + 1}
                    </Button>
                  </div>
                ))}
              </div>
              <div style={{ display: "flex" }}>
                <div className="btn" style={{ marginRight: 10 }}>
                  <Button onClick={addPage}>新增</Button>
                </div>
                {pages.length > 1 && (
                  <div className="btn">
                    <Button
                      onClick={() => {
                        modal.confirm({
                          title: "警告",
                          content: "确认删除本页吗？",
                          onOk: () => minusPage(),
                        });
                      }}
                    >
                      删除
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="rg-a4-warp" ref={ref} onScroll={listionPageScroll}>
            {Array(pages.length)
              .fill(0)
              .map((_, index) => {
                return (
                  <div className="rg-a4" key={_uniqueId()}>
                    <Header name={current.tmpl_name} />

                    <div className="rg-footer-pos">
                      <Footer num={index + 1} />
                    </div>
                  </div>
                );
              })}
            <ReactSortable
              tag={"div"}
              className="form-sandbox__payground"
              group={{
                name: "component",
                pull: true,
                put: true,
              }}
              swap
              direction={"horizontal"}
              fallbackOnBody={true}
              swapThreshold={1}
              animation={200}
              list={settingSystem(
                { monthDate, co_id: store.coId },
                containerState.value
              )}
              onUpdate={(e) => {
                console.log(
                  "container-onUpdate操作------------------->",
                  _cloneDeep(containerState.value)
                );
                updateComponent(e, containerState.value, null);
              }}
              onAdd={(e) => {
                console.log("container-onAdd操作------------------->", e);
                addComponent(e, containerState.value, null);
              }}
              onRemove={(e) => {
                console.log("container-onRemove操作------------------->");
                removeComponent(e, containerState.value, null);
              }}
              // 废弃，用on-event代替
              setList={() => {}}
            >
              {renderFormItem(containerState.value, {
                renderChild: renderChildContainer,
              })}
            </ReactSortable>
          </div>
        </div>
        <Dispose
          visible={showDispose}
          data={currentDispose}
          onClose={() => {
            console.log(currentDispose);
            if (currentDispose.current[currentDispose.idx].compType === "wrap")
              reRender();
            toggleDispose(false);
          }}
        />
        <Preview
          name={current.tmpl_name}
          data={previewData}
          onCancel={() => setPreviewData()}
        />
        {contextHolder}
      </div>
    </RGStoreContext.Provider>
  );
}

export default Index;
