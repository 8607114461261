import React, { useEffect, useRef, useState } from "react";
import http from "../../../utils/http";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { useStore } from "../store";

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);
export const option = {
  title: {
    text: "各业务收入占比",
  },
  tooltip: {
    trigger: "item",
    position: function (point, params, dom, rect, size) {
      // 固定在顶部
      return ["20%", "10%"];
    },
  },
  grid: {
    left: "1%",
    top: "10%",
    right: "1%",
    bottom: "0",
  },
  //   legend: {
  //     top: "5%",
  //     left: "center",
  //   },
  series: [
    {
      //   name: "Access From",
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      itemStyle: {
        // borderRadius: 10,
        borderColor: "#fff",
        borderWidth: 2,
      },
      label: {
        show: false,
        position: "center",
      },
      emphasis: {
        label: {
          show: true,
          fontSize: "20",
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
};
function Income() {
  const ref = useRef(null);
  const subjectIds = ["101010", "101020", "101030", "101040"];
  const colors = ["#42E0FC", "#FF8F00", "#9013FE", "#B8E986"];
  const [data, setData] = useState([]);
  const [store, setStore] = useStore();
  const [total, setTotal] = useState(0);

  const getData = async () => {
    let datas = [];
    // for (let index = 0; index < subjectIds.length; index++) {
    //   const element = subjectIds[index];
    //   try {
    //     const resp = await http.put("/indi/getOneIndicatorMonthlyYTM", {
    //       co_id: store.coId,
    //       month: store.a.month,
    //       subject_ids: [element],
    //       year: store && store.a ? store.a.year : new Date().getFullYear(),
    //     });
    //     if (resp.code === 200) {
    //       datas.push(resp.data.subjectEAReportData);
    //     }
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
    try {
      const resp = await http.put("/indi/getIndicatorMonthlyYTM", {
        co_id: store.coId,
        month: store.a.month,
        subject_ids: subjectIds,
        year: store && store.a ? store.a.year : new Date().getFullYear(),
      });
      if (resp.code === 200) {
        datas = resp.data.subjectEAReportDatas;
        // console.log(resp.data)
      }
    } catch (e) {
      console.log(e);
    }
    setData(datas);
    setChart(datas);
  };

  const setChart = (datas) => {
    if (!ref.current || !ref.current) return;
    const chart = echarts.init(ref.current);
    const dts = [];
    let total = 0;
    // option.title.text = params.title
    datas.forEach((element) => {
      dts.push({
        name: element.achievement_data.subject_name,
        value: element.achievement_data.subject_value_ytm,
      });
      total += element.achievement_data.subject_value_ytm;
    });
    // console.log("====");
    option.color = colors;
    option.series[0].data = dts;
    chart.setOption(option);
    setTotal(total);
  };

  useEffect(()=>{
    if(store.a){
      getData()
    }
}, [store])

  return (
    <div className="income-ratio">
      <div
        className=""
        ref={ref}
        style={{ width: "100%", height: 410, margin: "" }}
      ></div>
      {data.map((val, index) => {
        let sum = 0
        data.forEach(vl=>{
          sum += vl.achievement_data.subject_value_ytm
        })
        const ratio = (
          (val.achievement_data.subject_value_ytm /
            sum) *
          100
        ).toFixed(2);
        return (
          <div className="ratio-container" key={index}>
            <div className="ratio-item">
              <div className="ratio-item-title">
                <span className="name">
                  {val.achievement_data.subject_name}
                </span>
                <span className="number">
                  {val.achievement_data.subject_value_ytm}({ratio}%)
                </span>
              </div>
              <div className="ratio-item-progress">
                <div
                  className="ratio-item-progress-on"
                  style={{ width: ratio + "%", background: colors[index] }}
                ></div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Income;
