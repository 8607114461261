import React, {useState, useEffect} from 'react';
import http from '../../../../../utils/http';
import { Select } from 'antd'
import { handelResp } from '../../../../../utils';
function CoSelector(props) {

  const [data, setData] = useState([])
  const getData = async()=>{
    const resp = await http.get('/base/getCompanyList?page_id=auditec&page_size=100&page=1')
    handelResp(resp).then(data=>{
      if(!data.mCompany)return
      setData(data.mCompany)
    })
  }

  const onChange = value =>{
    if(value && props.onChange){
      props.onChange(value)
    }
  }

  useEffect(()=>{
    getData()
  }, [])
  return (
    <Select
      showSearch
      style={{minWidth: 120}}
      placeholder="填写公司"
      defaultValue={props.value}
      onChange={id=> props.item ? onChange({
        co_id: id,
        co_name: data.filter(val=>val.id===id)[0].alias1
      }) : onChange(id)}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={data.map(val=>({
        label: val.co_name,
        value: val.id
      }))}
    />
  );
}

export default CoSelector;
