import React, {useEffect, useState} from 'react';
import http from '../../../../../utils/http';
import { handelResp } from '../../../../../utils';
import { Select} from "antd"
import { props } from '../Text';

function SingleSelector(props) {
  const [indicatorList, setIndicatorList] = useState([]);
  const getIndicatorList = async () => {
    const resp = await http.get("/indi/selectIndicatorList");
    handelResp(resp).then((data) => {
      setIndicatorList(data.indicatorLists ? data.indicatorLists : []);
    });
  };
  useEffect(() => {
    getIndicatorList();
  }, []);

  const onChange = value =>{
    if(value && props.onChange){
      props.onChange(props.item ? indicatorList.filter(val=>val.id === value)[0] : value)
    }
  }
  return (
    <Select
          showSearch
          // mode="multiple"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          // allowClear
          style={{ width: "100%" }}
          placeholder="请选择指标"
          //   defaultValue={[]}
          defaultValue={props.item ? props.value ? props.value.id : null : props.value}
          onChange={onChange}
        >
          {indicatorList.map((val, index) => {
            return (
              <Select.Option key={val.id} title={val.ind_describe}>
                {val.ind_name}
              </Select.Option>
            );
          })}
        </Select>
  );
}

export default SingleSelector;
