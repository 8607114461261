import React, { useEffect, useState, useRef } from "react";
import { Card, Statistic } from "antd";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
  GaugeChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
  
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { doneRate } from "../utils";

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
  GaugeChart
]);

const option = {
    title: { // 标题组件，包含主标题和副标题
        text: '', // 主标题文本，支持使用 \n 换行。
        top: "0", // title 组件离容器上侧的距离。top 的值可以是像 20 这样的具体像素值，可以是像 '20%' 这样相对于容器高宽的百分比，也可以是 'top', 'middle', 'bottom'
        left: "0", // title 组件离容器左侧的距离。 ...同上
        textStyle: { // 主标题文字的设置
            fontSize: 16,
            fontWeight: "normal",
        },
    },
    series: [
        {
            type: 'gauge', // 测量仪
            center: ['50%', '50%'], // 图形坐标位置 左, 右
            radius: '95%', // 控制饼图的大小
            startAngle: 90, // 开始位置
            endAngle: -270, // 结束位置
            pointer: { // 显示仪表盘指针
                show: false // 是否显示仪表盘指针
            },
            progress: { // 展示当前进度。
                show: true, // 是否展示进度条
                // overlap: false, // 是否重叠
                // width: ,
                roundCap: true, // 是否圆顶
                // clip: false, // 是否裁掉超出部分
                itemStyle: {
                    // 设置圆形
                    borderWidth: 2,
                    borderColor: '#1f78b4',
                    color: '#1f78b4', // 设置进度条初期式样
                }
            },
            axisLine: {
                // 设置线条
                lineStyle: {
                    width: 4, // 线条宽度
                    color: [[1, '#E8EBF0']] // 不透明度, 线条设置背景色
                }
            },
            splitLine: { // 分隔线样式。
                show: false, // 是否显示分隔线。
                distance: 0, // 分隔线与轴线的距离。
                length: 1033 // 分隔线线长。支持相对半径的百分比。
            },
            axisTick: { // 刻度样式。
                show: false // 是否显示刻度。
            },
            axisLabel: { //刻度标签。
                show: false, // 是否显示标签。
                distance: 50 // 标签与刻度线的距离。
            },
            data: [
                {
                    value: 64, // 可写变量  此value 对应 formatter: '{value}%' 中的Value  64类型不对请用'64'
                    detail: {  // 仪表盘边框数据详情，用于显示数据。
                        valueAnimation: false, // 是否开启标签的数字动画。
                        offsetCenter: ['0%', '0%'], // 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
                        fontSize: 14 // 文字的字体大小。
                    }
                }
            ],
            detail: { //仪表盘详情，用于显示数据 仪表盘中间数字数据。
                color: '#1f78b4', // 文本颜色，默认取数值所在的区间的颜色
                formatter: '{value}%' // 格式化函数或者字符串  formatter: function (value) { return value.toFixed(0);}
                // width: 20,
                // height: 14,
                // fontSize: 14,
                // borderColor: 'auto',
                // borderRadius: 20,
                // borderWidth: 1,
            },
        }
    ]
}

function Progress(props) {
  const ref = useRef(null);
  const { color, a, e } = props
  useEffect(()=>{
    // option.series[0]['data'] = 23
    const chart = echarts.init(ref.current)
    option.series[0].progress.itemStyle.borderColor = color
    option.series[0].progress.itemStyle.color = color
    // const res = parseInt(a / (e ? e : 1) * 100) 
    const res = parseInt(doneRate(a, e) * 100) 
    // console.log(a,e, res)
    option.series[0].data[0].value = res
    chart.setOption(option)
  }, [a, e])
  return <div ref={ref} style={{ width: "76px", height: "76px" }}></div>;
}

export default Progress;
