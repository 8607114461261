import React, { useEffect, useRef, useState } from "react";
import { Modal, Input, message, Button } from "antd";
import http from "../../utils/http";
// import Clipboard from 'clipboard'
export function EditInput(props) {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <div style={{width: 150}}>
    <Input
      style={{ textAlign: "right" }}
      size="small"
      value={
        isFocus
          ? props.val
          : `${
              props.unit === "元"
                ? props.val
                  ? parseFloat(props.val).toFixed(2)
                  : "0.00"
                : props.val
            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      onChange={(e) => {
        // console.log(e.target.value)
        props.onChange(e.target.value);
      }}
      onFocus={() => {
        setIsFocus(true);
      }}
      onBlur={() => {
        setIsFocus(false);
      }}
    ></Input></div>
  );
}

function Detail(props) {
  const [datas, setDatas] = useState([]);
  const btn1 = useRef();
  const btn2 = useRef();
  //   const [editDatas, setEditDatas] = useState([]);
  // console.log(props)
  const onOk = async () => {
    const params = [];

    datas.forEach((element) => {
      const val = {};
      // console.log(props)
      val.business_cd = props.value.business_cd;
      val.co_id = localStorage.getItem("coId");
      val.e_a_type = props.value.e_a_type;
      val.function_cd = props.value.function_cd;

      params.push({
        ...val,
        e_a_type: "A",
        year: `${parseInt(props.year) - 1}`,
        id: element.achievement_id,
        month: element.month_key,
        subject_co_id: props.value.subject_co_id,
        subject_id: props.value.subject_id,
        subject_value: element.achievement_subject_value
          ? element.achievement_subject_value.toString()
          : "",
        version: element.achievement_version,
      });
      params.push({
        ...val,
        e_a_type: "E",
        year: props.year,
        id: element.estimate_id,
        month: element.month_key,
        subject_co_id: props.value.subject_co_id,
        subject_id: props.value.subject_id,
        subject_value: element.estimate_subject_value
          ? element.estimate_subject_value.toString()
          : "",
        version: element.estimate_version,
      });
    });
    const resp = await http.put("/base/eaUpdate", params);
    if (resp.code === 200) {
      message.success("提交成功");
      props.cancel();
    } else {
      message.success(resp.msg);
    }
  };

  const getData = async () => {
    const resp = await http.post("/base/selectTAchievementExt01", {
      ...props.value,
      year: props.year,
      co_id: localStorage.getItem("coId"),
    });
    console.log(resp);

    if (resp.code === 200) {
      setDatas(resp.data.tAchievementExt01s);
    }
  };

  useEffect(() => {
    getData();
  }, [props]);

  useEffect(() => {}, []);

  return (
    <div>
      <Modal
        title={props.value.subject_name}
        visible={true}
        onOk={onOk}
        onCancel={props.cancel}
        okText="保存"
        cancelText="取消"
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "15%" }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "80%",
            }}
          >
            <h3 style={{textAlign: "right", width: '50%'}}>{parseInt(props.year) - 1}年</h3>
            <h3 style={{textAlign: "right", width: '50%'}}>{props.year}年</h3>
          </div>
        </div>
        {datas.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div style={{ width: "10%" }}>{item.month_display}:</div>
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "80%",
                }}
              >
                 <p style={{ margin: 0, width: 150, textAlign: "right" }}>
                    {item.past_subject_value}
                  </p>
                {/* {index < (now).getMonth() || (parseInt(props.year)-1) < now.getFullYear() ? (
                  <p style={{ margin: 0, width: 150, textAlign: "right" }}>
                    {item.achievement_subject_value}
                  </p>
                ) : (
                  <EditInput
                    val={item.past_subject_value ? item.past_subject_value : ""}
                    unit={props.value.unit}
                    onChange={(e) => {
                      if (e) {
                        item.past_subject_value = e;
                      } else {
                        item.past_subject_value = null;
                      }
                      setDatas([...datas]);
                    }}
                  />
                  // <InputNumber
                  //   defaultValue={0}
                  //   onChange={(e) => {
                  //     if (e) {
                  //       item.achievement_subject_value = e;
                  //     } else {
                  //       item.achievement_subject_value = 0;
                  //     }
                  //   }}
                  //   size="small"
                  // />
                )} */}
                <EditInput
                  val={item.estimate_subject_value ? item.estimate_subject_value : "" }
                  unit={props.value.unit}
                  onChange={(e) => {
                    if (e) {
                      item.estimate_subject_value = e;
                    } else {
                      item.estimate_subject_value = null;
                    }
                    setDatas([...datas]);
                  }}
                />
                {/* <InputNumber
                  size="small"
                  value={item.estimate_subject_value}
                  onChange={(e) => {
                    if (e) {
                      item.estimate_subject_value = e;
                    } else {
                      item.estimate_subject_value = 0;
                    }
                    setDatas([...datas])
                  }} 
                /> */}
              </div>
            </div>
          );
        })}
        <div
          style={{
            display: "flex",
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          <div style={{ width: "10%" }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "80%",
            }}
          >
            {/* <Button type="primary" size='small' ref={btn1} onClick={async ()=>{
                    const text = await navigator.clipboard.readText()
                    const items = text.split(' 	')
                    const dts = datas.map((item,index)=>{
                        item.achievement_subject_value = items[index]
                        return {...item}
                    })
                    console.log(items, dts)
                    setDatas([...dts])
                }}>导入</Button> */}
            <div style={{ margin: 0, width: 90 }}></div>
            {/* <Button
              type="primary"
              size="small"
              ref={btn2}
              onClick={async () => {
                const text = await navigator.clipboard.readText();
                const items = text.trim().split(/[\n\r\t\s\f\v]+/i);
                if (items.length !== 12) {
                  message.warning("粘贴内容格式不正确");
                  return;
                }
                const dts = datas.map((item, index) => {
                  item.estimate_subject_value = parseFloat(items[index]);
                  return { ...item };
                });
                console.log(items, dts);
                setDatas([...dts]);
              }}
            >
              黏贴版粘贴
            </Button> */}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Detail;
