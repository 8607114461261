import React, { useState, useEffect } from "react";
import {
  Button,
  InputNumber,
  Row,
  Col,
  message,
  DatePicker,
  Table,
  Select
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import "./contentDR.less";
import http from "../../utils/http";
import { handelResp, handleMonth } from "../../utils/index";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useStore } from "./store";

const Items = [
  {
    title: "订单",
    items: [
      {
        label: "当日展厅新增订单",
        name: "daily1",
      },
      {
        label: "当日DCC新增订单",
        name: "daily2",
      },
      {
        label: "当日二网新增订单",
        name: "daily3",
      },
    ],
  },
  {
    title: "销量",
    items: [
      {
        label: "当日展厅交付",
        name: "daily4",
      },
      {
        label: "当日DCC交付",
        name: "daily5",
      },
      {
        label: "当日二网交付",
        name: "daily6",
      },
    ],
  },
  {
    title: "流量",
    items: [
      {
        label: "当日展厅客流",
        name: "daily9",
      },
      {
        label: "当日DCC线索",
        name: "daily10",
      },
    ],
  },
];

const Item2 = [
  {
    title: "销量",
    items: [
      {
        label: "当日批售车交付",
        name: "daily7",
      },
      {
        label: "当日9字头交付",
        name: "daily8",
      },
    ],
  },
];
const monthFormat = "YYYY/MM";
const dateFormat = 'YYYY/MM/DD';
function ContentDr1() {
  const [data, setData] = useState({});
  const [date, setDate] = useState(dayjs());
  const [comps, setComps] = useState([])
  const [coId, setCoId] = useState(localStorage.getItem("coId"))
  const [co_type, setco_type] = useState(localStorage.getItem("co_type"))
  const [co_name, setco_name] = useState(localStorage.getItem("co_name"))
  const [loading, setLoading] = useState(false)
  const getD = async ()=>{
    const now = date;
    const resp = await http.get("/tSalesDaily/select", {
      co_id: coId,
      year: now.year().toString(),
      month: handleMonth(now.month() + 1),
      day: now.date().toString(),
      page_size: 1,
      page: 1,
    });
    return resp
  }
  const getData = async () => {
    const resp = await getD()
    handelResp(resp).then((data) => {
      if (data.tSalesDailys && data.tSalesDailys.length) {
        setData(data.tSalesDailys[0]);
      }else{
        setData({})
      }
    });
  };

  const getCopms = async ()=>{
    const resp = await http.get('/base/selectUserCompany')
    handelResp(resp).then(data=>{
      if(data.userCompanys){
        setComps(data.userCompanys)
      }else{
        setComps([])
      }
    })
  }

  useEffect(() => {
    getData();
    getCopms()
  }, []);

  const handleSubmit = async ()=>{
    const now = date;
    const param = {
      co_id: coId,
      year: now.year().toString(),
      month: handleMonth(now.month() + 1),
      day: now.date().toString(),
      ...data,
    };
    if(data.id){
      return await http.put("/tSalesDaily/update", param)
    }else{
      const resp = await getD()
      const data = resp.data
      if (!(data.tSalesDailys && data.tSalesDailys.length)){
        return http.post("/tSalesDaily/create", param)
      }else{
        message.warning('已提交数据刷新后再试')
        return resp
      }
    }
  }
  const submit = async () => {
    // console.log(data);
    
    setLoading(true)
    // const resp = await (data.id
    //   ? http.put("/tSalesDaily/update", param)
    //   : http.post("/tSalesDaily/create", param));
    // console.log(resp);
    const resp = await handleSubmit()
    handelResp(resp).then((data) => {
      // console.log(data);
      message.success("提交成功");
      setLoading(false)
      getData();
    });
  };

  useEffect(() => {
    getData();
  }, [date, coId]);

  const onChange = (datem, dateString) => {
    setDate(datem);
  };

  return (
    <div className="content-dr1">
      <div className="header">
        <DatePicker
          style={{width: 180}}
          onChange={onChange}
          defaultValue={date}
          disabledDate={current=>current>dayjs().endOf('day')}
          // picker="month"
          format={dateFormat}
        />
        <Select style={{width: 200}} onChange={e=>{
          const comp = comps.filter(val=>val.co_id === e)[0]
          setCoId(e)
          setco_name(comp.co_name)
          setco_type(comp.co_type)
        }} value={coId}>
          {comps.map((val)=>{
            return <Select.Option key={val.co_id} >{val.co_name}</Select.Option>
          })}
        </Select>
        <h1>{co_name}日报</h1>
        <Button type="primary" icon={<CheckCircleOutlined />} onClick={submit} loading={loading}>
          提交
        </Button>
      </div>

      {(co_type === "WS" ? Item2 : Items).map(
        (item, index) => {
          return (
            <div className="part" key={index}>
              <div className="title-container">
                <div className="line-left"></div>
                <div className="title">{item.title}</div>
                <div className="line-right"></div>
              </div>
              <div className="input-content">
                <Row>
                  {item.items.map((val, i) => {
                    return (
                      <Col span={8}>
                        <div className="input-item" key={i}>
                          <div className="label">{val.label}</div>
                          <div className="input">
                            <InputNumber
                              value={data[val.name]}
                              onChange={(e) => {
                                data[val.name] = e;
                                setData({ ...data });
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}

function ContentDr2() {
  const [date, setDate] = useState(dayjs());
  const [data, setData] = useState([]);
  const [comps, setComps] = useState([])
  const [coId, setCoId] = useState(localStorage.getItem("coId"))
  // const [co_type, setco_type] = useState(localStorage.getItem("co_type"))
  const [co_name, setco_name] = useState(localStorage.getItem("co_name"))
  const getCopms = async ()=>{
    const resp = await http.get('/base/selectUserCompany')
    handelResp(resp).then(data=>{
      if(data.userCompanys){
        setComps(data.userCompanys)
      }else{
        setComps([])
      }
    })
  }
  useEffect(()=>{
    getCopms()
  }, [])

  const mapping = {
    // 'daily1': '月预算交付目标',
    // 'daily2': '月预算零售交付目标',
    展厅新增订单: "daily1",
    DCC新增订单: "daily2",
    二网新增订单: "daily3",
    展厅交付: "daily4",
    DCC交付: "daily5",
    二网交付: "daily6",
    批售车交付: "daily7",
    "9字头交付": "daily8",
    展厅客流: "daily9",
    DCC线索: "daily10",
  };

  const cols = [
    {
      title: "日期",
      dataIndex: "day",
    },
    ...Object.keys(mapping).map((val) => {
      return {
        title: val,
        dataIndex: mapping[val],
      };
    }),
  ];


  const getData = async () => {
    const resp = await http.get("/tSalesDaily/select", {
      co_id: coId,
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      page_size: 50,
      page: 1,
    });
    handelResp(resp).then((data) => {
      if (data.tSalesDailys && data.tSalesDailys.length) {
        setData(data.tSalesDailys);
      } else {
        setData([]);
      }
    });
  };

  useEffect(() => {
    getData();
  }, [date, coId]);

  const onChange = (datem, dateString) => {
    setDate(datem);
  };
  return (
    <div className="content content-dr2">
      <DatePicker
        onChange={onChange}
        defaultValue={date}
        picker="month"
        disabledDate={current=>current>dayjs().endOf('month')}
        format={monthFormat}
      />
      <Select style={{width: 200}} onChange={e=>{
          const comp = comps.filter(val=>val.co_id === e)[0]
          setCoId(e)
          setco_name(comp.co_name)
          // setco_type(comp.co_type)
        }} value={coId}>
          {comps.map((val)=>{
            return <Select.Option key={val.co_id} >{val.co_name}</Select.Option>
          })}
        </Select>
      {data.length ? (
        <Table
          columns={[
            {
              title: (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    // width: 100,
                    textAlign: "center",
                  }}
                >
                  指标项目
                </div>
              ),
              dataIndex: "name",
              align: "center",
              width: 50,
              fixed: 'left',
            },
            
            ...Array(date.daysInMonth()).fill(0).map((val, index) => {
              return {
                title: handleMonth(index+1),
                dataIndex: handleMonth(index+1),
                align: "center",
                width: 20,
              };
            }),
            {
              title: (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    // width: 100,
                    textAlign: "center",
                  }}
                >
                  合计
                </div>
              ),
              dataIndex: "total",
              align: "center",
              width: 50,
              fixed: 'right',
            },
          ]}
          dataSource={Object.keys(mapping).map((val) => {
            const dts = {
              name: val,
            };
            let total = 0
            data.forEach((v) => {
              if (v[mapping[val]]) {
                dts[v.day] = v[mapping[val]];
                total += v[mapping[val]];
              }
            });
            dts['total'] = total
            // console.log(dts);
            return dts;
          })}
          pagination={false}
          scroll={{x: true}}
        ></Table>
      ) : (
        <h1>暂无提交数据</h1>
      )}
    </div>
  );
}

const Item3 = [
  {
    title: "销量",
    items: [
      {
        label: "月预算交付目标",
        name: "target1",
      },
      {
        label: "月预算零售交付目标",
        name: "target2",
      },
    ],
  },
];
function ContentDr3() {
  const [data, setData] = useState({});
  const [date, setDate] = useState(dayjs());
  // const [store, _] = useStore();
  const [comps, setComps] = useState([])
  const [coId, setCoId] = useState(localStorage.getItem("coId"))
  // const [co_type, setco_type] = useState(localStorage.getItem("co_type"))
  const [co_name, setco_name] = useState(localStorage.getItem("co_name"))
  const [loading, setLoading] = useState(false)
  const getCopms = async ()=>{
    const resp = await http.get('/base/selectUserCompany')
    handelResp(resp).then(data=>{
      if(data.userCompanys){
        setComps(data.userCompanys)
      }else{
        setComps([])
      }
    })
  }
  const getD = async ()=>{
    const resp = await http.get("/tMTarget/select", {
      co_id: coId,
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      // day: now.getDate().toString(),
      page_size: 1,
      page: 1,
    });
    return resp
  }
  const getData = async () => {
    const resp = await getD()
    handelResp(resp).then((data) => {
      if (data.tMTargets && data.tMTargets.length) {
        setData(data.tMTargets[0]);
      }
    });
  };
  useEffect(()=>{
    getCopms()
  }, [])

  useEffect(() => {
    getData();
  }, [date, coId]);

  const handleSubmit = async ()=>{
    const param = {
      co_id: localStorage.getItem("coId"),
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      // day: now.getDate().toString(),
      ...data,
      status: "01",
    };
    if(data.id){
      return http.put("/tMTarget/update", param)
    }else{
      const res = await getD()
      const data = res.data
      if(data.tMTargets && data.tMTargets.length){
        // return http.put("/tMTarget/update", param)
        message.warning('已提交数据刷新后再试')
        return res
      }else{
        http.post("/tMTarget/create", param)
      }
    }
  }
  const submit = async () => {
    // console.log(data);
    setLoading(true)
    const resp = await handleSubmit()
    handelResp(resp).then((data) => {
      // console.log(data);
      message.success("提交成功");
      setLoading(false)
      getData();
    });
  };
  const onChange = (datem, dateString) => {
    setDate(datem);
  };
  return (
    <div className="content-dr1">
      <div className="header">
      <DatePicker
        onChange={onChange}
        defaultValue={date}
        picker="month"
        disabledDate={current=>current>dayjs().endOf('month')}
        format={monthFormat}
      />
      <Select style={{width: 200}} onChange={e=>{
          const comp = comps.filter(val=>val.co_id === e)[0]
          setCoId(e)
          setco_name(comp.co_name)
          // setco_type(comp.co_type)
        }} value={coId}>
          {comps.map((val)=>{
            return <Select.Option key={val.co_id} >{val.co_name}</Select.Option>
          })}
        </Select>
        <h1>{co_name}目标</h1>
        <Button
          // disabled={data.status === "01" || data.status === "03"}
          type="primary"
          icon={<CheckCircleOutlined />}
          onClick={submit}
          loading={loading}
        >
          提交
        </Button>
      </div>

      {Item3.map((item, index) => {
        return (
          <div className="part" key={index}>
            <div className="title-container">
              <div className="line-left"></div>
              <div className="title">{item.title}</div>
              <div className="line-right"></div>
            </div>
            <div className="input-content">
              <Row>
                {item.items.map((val, i) => {
                  return (
                    <Col span={12}>
                      <div className="input-item" key={i}>
                        <div className="label" style={{ width: "150px" }}>
                          {val.label}
                        </div>
                        <div className="input">
                          <InputNumber
                            // disabled={
                            //   data.status === "01" || data.status === "03"
                            // }
                            value={data[val.name] ? data[val.name] : ''}
                            onChange={(e) => {
                              data[val.name] = e;
                              setData({ ...data });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function ContentDr() {
  const params = useParams();
  const mapping = {
    dr001: <ContentDr1 />,
    dr002: <ContentDr2 />,
    dr003: <ContentDr3 />,
  };
  return <div>{mapping[params.page_id]}</div>;
}

export default ContentDr;