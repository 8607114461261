import React, { useEffect, useState } from "react";
import http from "../../utils/http";
import { Select, Table, Radio, Switch, Button, Modal, Input, Tag } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useStore } from "./store";
import {
  displayNum,
  doneRate,
  handelResp,
  handleMonth,
  increaseRate,
} from "../../utils";
import DatePicker from "../../component/datepick";
import { useParams } from "react-router-dom";
import ToolsChart from "./toolsChart";
import DoneChart from "./doneChart";
import "./tools.less";
import { TipsContent } from "./tips";
import ReportGenerator from "./rg";

const { confirm } = Modal;

const TagsA = [
  {
    title: "同期数",
    prefix: "past1_value_m",
  },
  {
    title: "预算数",
    prefix: "estimate_value_m",
  },
  {
    title: "实绩数",
    prefix: "achievement_value_m",
  },
];
const TagsE = (year) => [
  {
    title: `${parseInt(year) - 1}年`,
    prefix: "past1_value_m",
  },
  {
    title: `${parseInt(year)}年`,
    prefix: "estimate_value_m",
  },
];

function Tools1() {
  const [indicatorList, setIndicatorList] = useState([]);
  const [ids, setIds] = useState([]);
  const [opt, setOpt] = useState("a");
  const [eData, seteData] = useState([]);
  const [aData, setaData] = useState([]);
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTags] = useState();
  const [store, _] = useStore();
  const [isW, setIsW] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(
    store.a ? parseInt(store.a.year) : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    store.a ? parseInt(store.a.month) : new Date().getMonth() + 1
  );

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("");

  //   const [cols, setCols] = useState([]);

  const getIndicatorList = async () => {
    const resp = await http.get("/indi/selectIndicatorList");
    // console.log(resp);
    setIndicatorList(resp.data.indicatorLists);
  };

  const getTags = async () => {
    const resp = await http.get("/indi/getIndiTags");
    console.log(resp);
    handelResp(resp).then((data) => {
      setTags(data.indicatorTags);
    });
  };

  useEffect(() => {
    getIndicatorList();
    getTags();
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    const resp = await http.post("/indi/saveIndiTag", {
      subject_ids: ids,
      tag_name: modalText,
    });
    handelResp(resp).then(() => {
      setVisible(false);
      setConfirmLoading(false);
      getTags();
      setModalText("");
    });
  };

  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setVisible(false);
  };
  const getData = async () => {
    setIsLoading(true);
    if (opt === "a") {
      const resp = await http.put("/indi/getIndicatorMonthlyYTM05", {
        co_id: store.coId,
        month: month ? handleMonth(month) : store.a && store.a.month,
        subject_ids: ids,
        year: year ? year.toString() : store.a && store.a.year,
      });
      // console.log(resp)
      handelResp(resp).then((data) => {
        setaData(data.subjectReportData05s);
      });
    }
    if (opt === "e") {
      const resp = await http.put("/indi/getIndicatorMonthlyYTM04", {
        co_id: store.coId,
        month: month ? handleMonth(month) : store.a && store.a.month,
        subject_ids: ids,
        year: year ? year.toString() : store.a && store.a.year,
      });
      // console.log(resp)
      handelResp(resp).then((data) => {
        seteData(data.subjectReportData04s);
      });
    }
    setIsLoading(false);
  };
  const handleColsA = () => {
    const mStr = month < 10 ? `0` + month.toString() : month.toString();
    const a = [
      {
        title: "科目",
        dataIndex: "subject_name",
        // width: 200,
        // fixed: true,
        key: "subject_name",
        ellipsis: true,
        render: (val, row) => (
          <TipsContent isW={isW} title={val} tags={TagsA} data={row} />
        ),
        // align: "right",
      },
      {
        title: `${month}月`,
        key: `${month}月`,
        className: "t-month",
        children: [
          {
            title: "预算数",
            className: "t-month",
            dataIndex: `estimate_value_m${mStr}`,
            key: `estimate_value_m${mStr}`,
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
          },
          {
            title: "实际数",
            dataIndex: `achievement_value_m${mStr}`,
            key: `achievement_value_m${mStr}`,
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
          },
          {
            title: "完成率",
            align: "right",
            render: (val, row) =>
              displayNum(
                doneRate(
                  row[`achievement_value_m${mStr}`],
                  row[`estimate_value_m${mStr}`]
                ),
                "%"
              ),
          },
          {
            title: "上年同期",
            dataIndex: "past1_value_month",
            key: "past1_value_month",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
          },
          {
            title: "同比增长",
            align: "right",
            render: (val, row) =>
              displayNum(
                (row[`achievement_value_m${mStr}`] - row[`past1_value_month`]) /
                  Math.abs(row[`past1_value_month`]),
                "%"
              ),
          },
        ],
      },
      {
        title: "本年累计数",
        key: "本年累计数",
        className: "t-year",
        children: [
          {
            title: "预算数",
            className: "t-year",
            dataIndex: "estimate_value_YTM",
            key: "estimate_value_YTM",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
          },
          {
            title: "实际数",
            dataIndex: "achievement_value_YTM",
            key: "achievement_value_YTM",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
          },
          {
            title: "完成率",
            align: "right",
            render: (val, row) =>
              displayNum(
                doneRate(
                  row[`achievement_value_YTM`],
                  row[`estimate_value_YTM`]
                ),
                "%"
              ),
          },
          {
            title: "上年同期",
            dataIndex: "past1_value_YTM",
            key: "past1_value_YTM",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
          },
          {
            title: "同比增长",
            align: "right",
            render: (val, row) =>
              displayNum(
                (row[`achievement_value_YTM`] - row[`past1_value_YTM`]) /
                  Math.abs(row[`past1_value_YTM`]),
                "%"
              ),
          },
        ],
      },
      {
        title: `年预算数`,
        align: "right",
        className: "t-year",
        render: (val, row) =>
          displayNum(
            row.estimate_value_year,
            row.unit,
            null,
            row.unit === "元" && isW
          ),
      },
      {
        title: `年达成率`,
        align: "right",
        render: (val, row) =>
          displayNum(doneRate(row.achievement_value_YTM , row.estimate_value_year) , "%"),
      },
    ];

    return a;
  };

  const handleColsE = () => {
    const e = [
      {
        title: "科目",
        dataIndex: "subject_name",
        key: "subject_name",
        render: (val, row) => (
          <TipsContent isW={isW} title={val} tags={TagsE(year)} data={row} />
        ),
        // align: "right",
      },
      {
        title: `${parseInt(year - 3)}年实际数`,
        dataIndex: "past3_value_year",
        key: "past3_value_year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
      },
      {
        title: `${parseInt(year - 2)}年实际数`,
        dataIndex: "past2_value_year",
        key: "past2_value_year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
      },
      {
        title: `${parseInt(year - 1)}年总数`,
        dataIndex: "past1_value_year",
        key: "past1_value_year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
      },
      {
        title: `${parseInt(year)}年预算数`,
        dataIndex: "estimate_value_Year",
        key: "estimate_value_Year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
      },
      {
        title: `${parseInt(year)}/${parseInt(year - 1)}实际增减幅度`,
        align: "right",
        render: (val, row) =>
          displayNum(
            (row["estimate_value_Year"] - row["past1_value_year"]) /
              Math.abs(row["past1_value_year"]),
            "%"
          ),
      },
      {
        title: `${parseInt(year - 1)}/${parseInt(year - 2)}实际增减幅度`,
        align: "right",
        render: (val, row) =>
          displayNum(
            (row["past1_value_year"] - row["past2_value_year"]) /
              Math.abs(row["past2_value_year"]),
            "%"
          ),
      },
    ];

    return e;
  };

  const handleChange = async (ids) => {
    setIds(ids);
  };

  useEffect(() => {
    if (ids.length) getData();
  }, [ids, month, year, opt, store]);

  return (
    <div className="content tools">
      <div className="select-btn">
        <div
          className={`opt ${opt === "a" ? "opt-active" : ""}`}
          onClick={() => setOpt("a")}
        >
          实绩
        </div>
        <div
          className={`opt ${opt === "e" ? "opt-active" : ""}`}
          onClick={() => setOpt("e")}
        >
          预算
        </div>
      </div>
      {/* <Checkbox.Group
        options={[
          { label: "实绩", value: "a" },
          { label: "预算", value: "e" },
        ]}
        value={opt}
        onChange={(vals) => {
            console.log(vals)
            setOpt(vals)
        }}
      /> */}
      <DatePicker
        title={"选择时间"}
        canNotShowUp={true}
        year={store.a ? parseInt(store.a.year) : new Date().getFullYear()}
        month={
          opt === "a"
            ? store.a
              ? parseInt(store.a.month)
              : new Date().getMonth() + 1
            : null
        }
        onChange={(e) => {
          setYear(e.year);
          if (e.month) setMonth(e.month);
        }}
      />
      <div
        className="search-filter"
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Select
          showSearch
          mode="multiple"
          autoClearSearchValue={false}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          allowClear
          style={{ width: "93%" }}
          placeholder="请选择指标"
          //   defaultValue={[]}
          value={ids}
          onClear={() => {
            setIds([]);
            seteData([]);
            setaData([]);
            setCurrentTags();
          }}
          onChange={handleChange}
        >
          {indicatorList.map((val, index) => {
            return (
              <Select.Option key={val.id} title={val.ind_describe}>
                {val.ind_name}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          onClick={async () => {
            showModal();
          }}
        >
          保存
        </Button>
      </div>

      <div className="tag-container">
        <div className="tag-label">标签：</div>
        <div className="tags">
          {tags.map((val, index) => {
            return (
              <Tag
                className={`hover ${currentTag === index ? "tag-active" : ""}`}
                key={index}
                closable={val.user_id === "000000" ? false : true}
                onClick={() => {
                  setIds(val.subject_ids);
                  setCurrentTags(index);
                }}
                // color={currentTag === index ? "#1DA57A" : undefined}
                onClose={(e) => {
                  confirm({
                    title: "确定要删除这个标签吗?",
                    icon: <ExclamationCircleOutlined />,
                    content: "",
                    okText: "是的",
                    okType: "danger",
                    cancelText: "不删",
                    async onOk() {
                      const resp = await http.delete(
                        `/indi/deleteIndiTag?id=${val.id}`
                      );
                      handelResp(resp);
                      getTags();
                    },
                    onCancel() {
                      console.log("Cancel");
                    },
                  });
                  e.preventDefault();
                }}
              >
                {val.tag_name}
              </Tag>
            );
          })}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", margin: "5px 0" }}
      >
        <div>
          万元：
          <Switch
            size="small"
            value={isW}
            onChange={(flag) => {
              console.log(flag);
              setIsW(flag);
            }}
          />
        </div>
      </div>
      <div className={opt === "a" ? "a-t" : "e-t"}>
        <Table
          size="small"
          scroll={{ x: "auto" }}
          loading={isLoading}
          columns={opt === "a" ? handleColsA() : handleColsE()}
          bordered
          dataSource={opt === "a" ? aData : eData}
          pagination={false}
        />
      </div>
      <Modal
        title="保存为标签"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Input
          placeholder="请输入保存的标签名"
          value={modalText}
          onChange={(e) => setModalText(e.target.value)}
        ></Input>
      </Modal>
    </div>
  );
}

export function Tools2(props) {
  const [indicatorList, setIndicatorList] = useState([]);
  const [ids, setIds] = useState(null);
  const [opt, setOpt] = useState(props.opt ? props.opt : "a");
  const [eData, seteData] = useState([]);
  const [aData, setaData] = useState([]);
  const [store, _] = useStore();
  const [year, setYear] = useState(
    store.a ? parseInt(store.a.year) : new Date().getFullYear()
  );
  const [month, setMonth] = useState(
    store.a ? parseInt(store.a.month) : new Date().getMonth() + 1
  );
  const [isW, setIsW] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMonth, setIsMonth] = useState("month");
  

  const getIndicatorList = async () => {
    const resp = await http.get("/indi/selectIndicatorList");
    setIndicatorList(resp.data.indicatorLists);
  };

  useEffect(() => {
    getIndicatorList();
  }, []);
  const getData = async () => {
    setIsLoading(true);
    if(!ids)return
    if (opt === "a") {
      const resp = await http.put("/indi/getOneIndicatorMonthlyYTMCorp05", {
        co_id: store.coId,
        month: props.month ? handleMonth(props.month) : month ? handleMonth(month) : store.a && store.a.month,
        subject_ids: [ids],
        year: props.year ? props.year.toString() : year ? year.toString() : store.a && store.a.year,
        business_cd: props.business_cd
      });
      // console.log(resp)
      handelResp(resp).then((data) => {
        setaData(data.subjectEAReportData05s);
      }).catch(e=>{
        setaData([])
      });
    }
    if (opt === "e") {
      const resp = await http.put("/indi/getOneIndicatorMonthlyYTMCorp04", {
        co_id: store.coId,
        month: props.month ? handleMonth(props.month) : month ? handleMonth(month) : store.a && store.a.month,
        subject_ids: [ids],
        year: props.year ? props.year.toString() : year ? year.toString() : store.a && store.a.year,
      });
      // console.log(resp)
      handelResp(resp).then((data) => {
        seteData(data.subjectEAReportData04s);
      });
    }
    setIsLoading(false);
  };
  const handleChange = async (ids) => {
    setIds(ids);
  };

  useEffect(() => {
    if (ids) getData();
  }, [ids, month, year, opt, store]);

  useEffect(() => {
    if (props.id) {
      setIds(props.id);
    }
  }, [props.id]);
  useEffect(()=>{
    if (props.opt) {
      setOpt(props.opt);
    }
  }, [props.opt])
  useEffect(()=>{
    if (props.month) {
      setMonth(props.month)
      getData()
    }
  }, [props.month])
  
  useEffect(()=>{
    if (props.year) {
      getData()
    }
  }, [props.year])

  const handleColsA = () => {
    const mStr = month < 10 ? `0` + month.toString() : month.toString();
    const a = [
      {
        title: "公司",
        dataIndex: "co_name",
        // width: 200,
        // fixed: true,
        key: "co_name",
        ellipsis: true,
        render: (val, row) => (
          <TipsContent isW={isW} title={val} tags={TagsA} data={row} />
        ),
        // align: "right",
      },
      {
        title: `${month}月`,
        key: `${month}月`,
        className: "t-month",
        children: [
          {
            title: "预算数",
            className: "t-month",
            dataIndex: `estimate_value_m${mStr}`,
            key: `estimate_value_m${mStr}`,
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
            sorter: (a, b) =>
              b[`estimate_value_m${mStr}`] - a[`estimate_value_m${mStr}`],
          },
          {
            title: "实际数",
            dataIndex: `achievement_value_m${mStr}`,
            key: `achievement_value_m${mStr}`,
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
            sorter: (a, b) =>
              b[`achievement_value_m${mStr}`] - a[`achievement_value_m${mStr}`],
          },
          {
            title: "完成率",
            align: "right",
            key: "完成率121",
            render: (val, row) =>
              displayNum(
                doneRate(
                  row[`achievement_value_m${mStr}`],
                  row[`estimate_value_m${mStr}`]
                ),
                "%"
              ),
            sorter: (a, b) =>
              doneRate(
                b[`achievement_value_m${mStr}`],
                b[`estimate_value_m${mStr}`]
              ) -
              doneRate(
                a[`achievement_value_m${mStr}`],
                a[`estimate_value_m${mStr}`]
              ),
          },
          {
            title: "上年同期",
            dataIndex: "past1_value_month",
            key: "past1_value_month",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
            sorter: (a, b) => {
              if (!a) {
                a.past1_value_month = 0;
              }
              if (!b) {
                b.past1_value_month = 0;
              }
              return b["past1_value_month"] - a["past1_value_month"];
            },
          },
          {
            title: "同比增长",
            align: "right",
            key: "同比增长ss",
            render: (val, row) =>
              displayNum(
                increaseRate(
                  row[`achievement_value_m${mStr}`],
                  row[`past1_value_month`]
                ),
                "%"
              ),
            sorter: (a, b) =>
              (increaseRate(
                b[`achievement_value_m${mStr}`] , b[`past1_value_month`]
              ) -
              increaseRate(
                a[`achievement_value_m${mStr}`] , a[`past1_value_month`]
              ))
          },
        ],
      },
      {
        title: "本年累计数",
        key: "本年累计数",
        className: "t-year",
        children: [
          {
            title: "预算数",
            className: "t-year",
            dataIndex: "estimate_value_YTM",
            key: "estimate_value_YTM",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
            sorter: (a, b) => b["estimate_value_YTM"] - a["estimate_value_YTM"],
          },
          {
            title: "实际数",
            dataIndex: "achievement_value_YTM",
            key: "achievement_value_YTM",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
            sorter: (a, b) =>
              b["achievement_value_YTM"] - a["achievement_value_YTM"],
          },
          {
            title: "完成率",
            align: "right",
            key: "完成率313",
            render: (val, row) =>
              displayNum(
                doneRate(
                  row[`achievement_value_YTM`],
                  row[`estimate_value_YTM`]
                ),
                "%"
              ),
            sorter: (a, b) =>
              doneRate(b[`achievement_value_YTM`], b[`estimate_value_YTM`]) -
              doneRate(a[`achievement_value_YTM`], a[`estimate_value_YTM`]),
          },
          {
            title: "上年同期",
            dataIndex: "past1_value_YTM",
            key: "past1_value_YTM",
            align: "right",
            render: (val, row) =>
              displayNum(val, row.unit, null, row.unit === "元" && isW),
            sorter: (a, b) => (b["past1_value_YTM"] - a["past1_value_YTM"]),
          },
          {
            title: "同比增长",
            align: "right",
            key: "同比增长wew23",
            render: (val, row) =>
              displayNum(
                increaseRate(
                  row[`achievement_value_YTM`],
                  row[`past1_value_YTM`]
                ),
                "%"
              ),
            sorter: (a, b) =>
              increaseRate(b[`achievement_value_YTM`] , b[`past1_value_YTM`]) -
              increaseRate(a[`achievement_value_YTM`] , a[`past1_value_YTM`]),
          },
        ],
      },
      {
        title: `年预算数`,
        align: "right",
        className: "t-year",
        key: `年预算数3321d`,
        render: (val, row) =>
          displayNum(
            row.estimate_value_year,
            row.unit,
            null,
            row.unit === "元" && isW
          ),
        sorter: (a, b) => {
          if (!a.estimate_value_year) {
            a.estimate_value_year = 0;
          }
          if (!b) {
            b.estimate_value_year = 0;
          }
          return b.estimate_value_year - a.estimate_value_year;
        },
      },
      {
        title: `年达成率`,
        align: "right",
        key: `年达成率3321d`,
        render: (val, row) =>
          displayNum(doneRate(row.achievement_value_YTM, row.estimate_value_year), "%"),
        sorter: (a, b) => {
          if (!a.achievement_value_YTM) {
            a.achievement_value_YTM = 0;
          }
          if (!b.achievement_value_YTM) {
            b.achievement_value_YTM = 0;
          }
          if (!a.estimate_value_year) {
            a.estimate_value_year = 0;
          }
          if (!b.estimate_value_year) {
            b.estimate_value_year = 0;
          }
          return (
            b.achievement_value_YTM / b.estimate_value_year -
            a.achievement_value_YTM / a.estimate_value_year
          );
        },
      },
    ];

    return a;
  };

  const handleDetailA = ()=>{
    const mStr = month < 10 ? `0` + month.toString() : month.toString();
    const base = [
      {
        title: "公司",
        dataIndex: "co_name",
        fixed: "left",
        width: 210,
        ellipsis: true,
        key: "co_name",
        render: (val, row) => (
          <TipsContent isW={isW} title={val} tags={TagsA} data={row} />
        ),
        // align: "right",
      },
      {
        title: `年预算数`,
        align: "right",
        className: "t-year",
        key: `年预算数3321d`,
        render: (val, row) =>
          displayNum(
            row.estimate_value_year,
            row.unit,
            null,
            row.unit === "元" && isW
          ),
        sorter: (a, b) => {
          if (!a.estimate_value_year) {
            a.estimate_value_year = 0;
          }
          if (!b) {
            b.estimate_value_year = 0;
          }
          return b.estimate_value_year - a.estimate_value_year;
        },
      },
      {
        title: `年达成率`,
        align: "right",
        key: `年达成率3321d`,
        render: (val, row) =>
          displayNum(doneRate(row.achievement_value_YTM , row.estimate_value_year), "%"),
        sorter: (a, b) => {
          if (!a.achievement_value_YTM) {
            a.achievement_value_YTM = 0;
          }
          if (!b.achievement_value_YTM) {
            b.achievement_value_YTM = 0;
          }
          if (!a.estimate_value_year) {
            a.estimate_value_year = 0;
          }
          if (!b.estimate_value_year) {
            b.estimate_value_year = 0;
          }
          return (
            b.achievement_value_YTM / b.estimate_value_year -
            a.achievement_value_YTM / a.estimate_value_year
          );
        },
      },
    ]
    const months = [
      {
        title: "预算数",
        className: "t-month",
        dataIndex: `estimate_value_m${mStr}`,
        key: `estimate_value_m${mStr}`,
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) =>
          b[`estimate_value_m${mStr}`] - a[`estimate_value_m${mStr}`],
      },
      {
        title: "实际数",
        dataIndex: `achievement_value_m${mStr}`,
        key: `achievement_value_m${mStr}`,
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) =>
          b[`achievement_value_m${mStr}`] - a[`achievement_value_m${mStr}`],
      },
      {
        title: "完成率",
        align: "right",
        key: "完成率121",
        render: (val, row) =>
          displayNum(
            doneRate(
              row[`achievement_value_m${mStr}`],
              row[`estimate_value_m${mStr}`]
            ),
            "%"
          ),
        sorter: (a, b) =>
          doneRate(
            b[`achievement_value_m${mStr}`],
            b[`estimate_value_m${mStr}`]
          ) -
          doneRate(
            a[`achievement_value_m${mStr}`],
            a[`estimate_value_m${mStr}`]
          ),
      },
      {
        title: "上年同期",
        dataIndex: "past1_value_month",
        key: "past1_value_month",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) => {
          if (!a) {
            a.past1_value_month = 0;
          }
          if (!b) {
            b.past1_value_month = 0;
          }
          return b["past1_value_month"] - a["past1_value_month"];
        },
      },
      {
        title: "同比增长",
        align: "right",
        key: "同比增长ss",
        render: (val, row) =>
          displayNum(
            increaseRate(
              row[`achievement_value_m${mStr}`],
              row[`past1_value_month`]
            ),
            "%"
          ),
        sorter: (a, b) =>
          increaseRate(
            b[`achievement_value_m${mStr}`] - b[`past1_value_month`]
          ) -
          increaseRate(
            a[`achievement_value_m${mStr}`] - a[`past1_value_month`]
          ),
      },
    ]
    const years = [
      {
        title: "预算数",
        className: "t-year",
        dataIndex: "estimate_value_YTM",
        key: "estimate_value_YTM",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) => b["estimate_value_YTM"] - a["estimate_value_YTM"],
      },
      {
        title: "实际数",
        dataIndex: "achievement_value_YTM",
        key: "achievement_value_YTM",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) =>
          b["achievement_value_YTM"] - a["achievement_value_YTM"],
      },
      {
        title: "完成率",
        align: "right",
        key: "完成率313",
        render: (val, row) =>
          displayNum(
            doneRate(
              row[`achievement_value_YTM`],
              row[`estimate_value_YTM`]
            ),
            "%"
          ),
        sorter: (a, b) =>
          doneRate(b[`achievement_value_YTM`], b[`estimate_value_YTM`]) -
          doneRate(a[`achievement_value_YTM`], a[`estimate_value_YTM`]),
      },
      {
        title: "上年同期",
        dataIndex: "past1_value_YTM",
        key: "past1_value_YTM",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) => b["past1_value_YTM"] - a["past1_value_YTM"],
      },
      {
        title: "同比增长",
        align: "right",
        key: "同比增长wew23",
        render: (val, row) =>
          displayNum(
            increaseRate(
              row[`achievement_value_YTM`],
              row[`past1_value_YTM`]
            ),
            "%"
          ),
        sorter: (a, b) =>
          increaseRate(b[`achievement_value_YTM`] , b[`past1_value_YTM`]) -
          increaseRate(a[`achievement_value_YTM`] , a[`past1_value_YTM`]),
      },
    ]
    months.forEach((val) => {
      val.fixed = "left";
      val.width = 120;
    });
    years.forEach((val) => {
      val.fixed = "left";
      val.width = 120;
    });
    if (isMonth === "month") {
      base.splice(1, 0, ...months);
    } else {
      base.splice(1, 0, ...years);
    }
    return base;
  }
  const handleColsE = () => {
    const e = [
      {
        title: "公司",
        dataIndex: "co_name",
        key: "co_name",
        render: (val, row) => (
          <TipsContent title={val} tags={TagsE(year)} data={row} isW={isW} />
        ),
        // align: "right",
      },
      {
        title: `${parseInt(year - 3)}年实际数`,
        dataIndex: "past3_value_year",
        key: "past3_value_year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) => b.past3_value_year - a.past3_value_year,
      },
      {
        title: `${parseInt(year - 2)}年实际数`,
        dataIndex: "past2_value_year",
        key: "past2_value_year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) => b.past2_value_year - a.past2_value_year,
      },
      {
        title: `${parseInt(year - 1)}年总数`,
        dataIndex: "past1_value_year",
        key: "past1_value_year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) => b.past1_value_year - a.past1_value_year,
      },
      {
        title: `${parseInt(year)}年预算数`,
        dataIndex: "estimate_value_Year",
        key: "estimate_value_Year",
        align: "right",
        render: (val, row) =>
          displayNum(val, row.unit, null, row.unit === "元" && isW),
        sorter: (a, b) => b.estimate_value_Year - a.estimate_value_Year,
      },
      {
        title: `${parseInt(year)}/${parseInt(year - 1)}实际增减幅度`,
        align: "right",
        render: (val, row) =>
          displayNum(
            increaseRate(row["estimate_value_Year"], row["past1_value_year"]),
            "%"
          ),
        sorter: (a, b) =>
          increaseRate(b["estimate_value_Year"], b["past1_value_year"]) -
          increaseRate(a["estimate_value_Year"], a["past1_value_year"]),
      },
      {
        title: `${parseInt(year - 1)}/${parseInt(year - 2)}实际增减幅度`,
        align: "right",
        render: (val, row) =>
          displayNum(
            increaseRate(row["past1_value_year"], row["past2_value_year"]),
            "%"
          ),
        sorter: (a, b) =>
          increaseRate(b["past1_value_year"], b["past2_value_year"]) -
          increaseRate(a["past1_value_year"], a["past2_value_year"]),
      },
    ];

    return e;
  };

  return (
    <div className="content tools">
      {props.id ? null : (
        <div className="select-btn">
          <div
            className={`opt ${opt === "a" ? "opt-active" : ""}`}
            onClick={() => setOpt("a")}
          >
            实绩
          </div>
          <div
            className={`opt ${opt === "e" ? "opt-active" : ""}`}
            onClick={() => setOpt("e")}
          >
            预算
          </div>
        </div>
      )}
      {props.id ? null : (
        <DatePicker
          title={"选择时间"}
          canNotShowUp={true}
          year={store.a ? parseInt(store.a.year) : new Date().getFullYear()}
          month={
            opt === "a"
              ? store.a
                ? parseInt(store.a.month)
                : new Date().getMonth() + 1
              : null
          }
          onChange={(e) => {
            setYear(e.year);
            if (e.month) setMonth(e.month);
          }}
        />
      )}
      {props.id ? null : (
        <div
          className="search-filter"
          style={{
            marginTop: 10,
            marginBottom: 10,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Select
            showSearch
            // mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.includes(input)}
            allowClear
            style={{ width: "100%" }}
            placeholder="请选择指标"
            //   defaultValue={[]}
            value={ids}
            onClear={() => {
              setIds([]);
              seteData([]);
              setaData([]);
            }}
            onChange={handleChange}
          >
            {indicatorList.map((val, index) => {
              return (
                <Select.Option key={val.id} title={val.ind_describe}>
                  {val.ind_name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      )}

      <div
        style={{ display: "flex", justifyContent: "flex-end", margin: "5px 0" }}
      >
        <div>
          万元：
          <Switch
            size="small"
            value={isW}
            onChange={(flag) => {
              console.log(flag);
              setIsW(flag);
            }}
          />
        </div>
      </div>
      {props.id ? (
        <div className="detail-table">
          {opt === 'a' && <div className="table-card">
            <div
              className={`table-card-title ${
                isMonth === "month" ? "table-card-title-active" : ""
              }`}
              onClick={() => {
                setIsMonth("month");
              }}
            >{`${props.month ? props.month : month}月`}</div>
            <div
              className={`table-card-title ${
                isMonth === "year" ? "table-card-title-active" : ""
              }`}
              onClick={() => {
                setIsMonth("year");
              }}
            >
              本年累计数
            </div>
          </div>}
          <div className={opt === "a" ? "a-d-t" : "e-d-t"}>
            <Table
              size="small"
              scroll={{ x: "auto" }}
              loading={isLoading}
              columns={opt === "a" ? handleDetailA() : handleColsE()}
              // bordered
              dataSource={opt === "a" ? aData : eData}
              pagination={false}
            />
          </div>
        </div>
      ) : (
        <div className={opt === "a" ? "a-t" : "e-t"}>
          <Table
            size="small"
            scroll={{ x: "auto" }}
            loading={isLoading}
            columns={opt === "a" ? handleColsA() : handleColsE()}
            bordered
            dataSource={opt === "a" ? aData : eData}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
}

function Tools() {
  const { page_id } = useParams();
  const pages = {
    "indi-search": <Tools1 />,
    "indi-corp-summary": <Tools2 />,
    "indi-chart": <ToolsChart />,
    "indi-completion": <DoneChart />,
    "report_generator": <ReportGenerator />,
  };
  return pages[page_id];
}

export default Tools;
