import React, { useState, useEffect, useRef } from "react";
import { Upload, Spin, message, Modal } from "antd";
import http from "../../../utils/http";
import { useStore } from "../store";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import UploadImg from "../../../assets/icon／上传@2x.png";
import DownloadImg from "../../../assets/icon／下载@2x.png";
import "./style.less";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  BarChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { displayNum, handleMonth } from "../../../utils";
import { handelResp } from "../../../utils";

echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  CanvasRenderer,
  LegendComponent,
  TooltipComponent,
  ToolboxComponent,
]);

const option = {
  legend: {
    //   data: ['预算', '实绩'],
    //   bottom: 20
  },
  title: {
    text: "",
  },
  tooltip: {
    trigger: "axis",
    // formatter: '{a}月'
    valueFormatter: (value) => value.toFixed(2),
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  xAxis: {
    type: "category",
    data: Array(12)
      .fill(0)
      .map((_, index) => {
        return handleMonth(index + 1) + "月";
      }),
  },
  yAxis: {
    type: "value",
  },
  grid: {
    left: "3%",
    top: "10%",
    right: "3%",
    bottom: "10%",
    containLabel: true,
  },
  series: [],
};

const types = [
  {
    name: "商品车",
    type: "CAR",
  },
  {
    name: "配件",
    type: "PARTS",
  },
  {
    name: "附件",
    type: "ACC",
  },
];
const typeMapping = {
  CAR: "商品车",
  PARTS: "配件",
  ACC: "附件",
};
const color = ["#B8E986", "#78DEFF", "#FFD35B", "#FF9F61", "#FF6167"];

const toStringNum = (num) => {
  num = num.toFixed(2);
  num = parseFloat(num);
  num = num.toLocaleString();
  return num;
};
function StockReport() {
  const currentDate = new Date();
  const [type, setType] = useState("CAR");
  const [store, _] = useStore();
  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const ref = useRef();
  const [data, setData] = useState([]);
  const [isDetail, setShowDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const resp = await http.get("/stock/selectStockReport", {
      co_id: store.coId ? store.coId : localStorage.getItem("coId"),
      year: year.toString(),
      item_type: type,
    });
    handelResp(resp).then((data) => {
      setLoading(false);
      if (!data.stockReports) {
        setData([]);
        const chart = echarts.init(ref.current);
        chart.clear();
        return;
      }
      setChart(data.stockReports);
      setData(data.stockReports);
    });
  };

  const setChart = (datas) => {
    if (!ref.current) return;
    const series = [];
    const chart = echarts.init(ref.current);
    datas.forEach((element, index) => {
      if (!index) return;
      series.push({
        name: element.item_group.split(". ")[1],
        type: "bar",
        stack: "total",
        label: {
          show: false,
        },
        emphasis: {
          focus: "series",
        },
        color: color[index - 1],
        data: Array(12)
          .fill(0)
          .map((_, index) => {
            return element["value" + handleMonth(index + 1)];
          }),
      });
    });
    option.series = series;
    chart.clear();
    chart.setOption(option);
  };

  useEffect(() => {
    getData();
  }, [year, store, type]);

  const exportFile = async () => {
    const response = await http.download("/stock/exportStockReportExcel", {
      co_id: store.coId ? store.coId : localStorage.getItem("coId"),
      year: year.toString(),
    });
    handelResp(response);
    var blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      decodeURI(
        // `${store.co_name}-${year}年-${month}月-${
        //   type.length === 1 ? menusMapping[type] : ""
        // }${name}` + ".xlsx"
        response.headers["content-disposition"].split("''")[1]
      )
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const genUploadProps = () => {
    const token = localStorage.getItem("token");
    const url =
      http.baseUrl +
      "/stock/uploadStock" +
      `?co_id=${
        store.coId ? store.coId : localStorage.getItem("coId")
      }&year=${year.toString()}&month=${handleMonth(month)}`;
    return {
      name: "file",
      method: "put",
      action: url,
      headers: {
        authorization: "Bearer " + token,
      },
      showUploadList: false,
      beforeUpload: (file, list) => {
        return new Promise((resolve, reject) => {
          Modal.confirm({
            title: `确定上传${
              store.co_name
            }${year.toString()}年${month}月的数据吗?`,
            icon: <ExclamationCircleFilled />,
            content: file.name,
            okText: "上传",
            cancelText: "取消",
            onOk() {
              // console.log('OK');
              resolve();
            },
            onCancel() {
              // console.log('Cancel');
              reject();
            },
          });
        });
      },
      onChange(info) {
        console.log(info);
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(`${info.file.name} 文件上传成功`);
          // window.location.reload();
          getData();
        } else if (info.file.status === "error") {
          // message.error(`${info.file.name} 文件上传失败`);
          message.error(info.file.response.msg);
        }
      },
    };
  };

  return (
    <Spin spinning={loading}>
      <div className="stock-report">
        <div className="tabs">
          {types.map((val, index) => {
            return (
              <div
                key={index}
                className={`tab ${val.type === type && "tab-active"}`}
                onClick={() => {
                  setType(val.type);
                }}
              >
                {val.name}
              </div>
            );
          })}
        </div>

        <div className="date-block">
          <div className="label">选择时间</div>
          <div className="date-container">
            <div
              className="icon-arrow allow"
              onClick={() => {
                setYear(year - 1);
                setMonth(12)
              }}
            >
              <DoubleLeftOutlined />
            </div>
            <div className="year">{year}</div>
            <div
              className={`icon-arrow ${
                year === currentDate.getFullYear() ? "no-click" : "allow"
              }`}
              onClick={() => {
                if (year < currentDate.getFullYear()) {
                    setYear(year + 1);
                  if((year + 1) === currentDate.getFullYear()){
                    setMonth(currentDate.getMonth() + 1)
                  }
                }
              }}
            >
              <DoubleRightOutlined />
            </div>
            {Array(12)
              .fill(0)
              .map((_, index) => {
                return (
                  <div
                    key={index}
                    className={`month ${
                      index > currentDate.getMonth() && year === currentDate.getFullYear() ? "no-click" : "allow"
                    } ${index + 1 === month ? "active-month" : ""}`}
                    onClick={() => {
                      if (!(index > currentDate.getMonth() && year === currentDate.getFullYear())) {
                        setMonth(index + 1);
                      }
                    }}
                  >
                    {index + 1}
                  </div>
                );
              })}
          </div>
          {!store.isSuper && (
            <div
              className="btn-item"
              onClick={() => {
                const link = document.createElement("a");
                link.style.display = "none";
                link.href = window.location.origin + "/库存填报模板.xlsx";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
            >
              <img src={DownloadImg} alt="" />
              模版下载
            </div>
          )}
          {!store.isSuper && (
            <Upload {...genUploadProps()}>
              <div className="btn-item">
                <img src={UploadImg} alt="" />
                上传数据
              </div>
            </Upload>
          )}
          <div className="btn-item" onClick={exportFile}>
            <img src={DownloadImg} alt="" />
            下载报表
          </div>
        </div>

        <div className="stock-report-container">
          <div className="chart" ref={ref}></div>
          {!data.length && <h1 style={{ textAlign: "center" }}>没有数据</h1>}
          <div className="card-list">
            <div className="card" style={{ width: "100%" }}>
              <div
                className="title"
                style={{ backgroundColor: "#1BA57A", color: "#FFFFFF" }}
              >
                {store.co_name}
                {handleMonth(month)}月{typeMapping[type]}库存
              </div>
              <div className="num">
                {data.length &&
                  toStringNum(data[0]["value" + handleMonth(month)])}
              </div>
            </div>
          </div>
          <div className="card-list">
            {data.map((item, index) => {
              if (!index) return;
              return (
                <div
                  className="card"
                  key={index}
                  style={{ width: "32%" }}
                  onClick={() => {
                    // if(item["value" + handleMonth(month)]){
                    //   setShowDetail(!isDetail)
                    // }
                  }}
                >
                  <div
                    className="title"
                    style={{ backgroundColor: color[index - 1] }}
                  >
                    {item.item_group.split(". ")[1]}
                  </div>
                  {!isDetail && (
                    <div className="num">
                      {" "}
                      {toStringNum(item["value" + handleMonth(month)])}{" "}
                    </div>
                  )}
                  {!isDetail && (
                    <div className="detail">
                      {item.SubItems &&
                        item.SubItems.map((val, index) => {
                          if (!val["value" + handleMonth(month)]) return;
                          return (
                            <div className="item" key={index}>
                              <div className="label">
                                {val.item_name
                                  ? val.item_name
                                  : item.item_group.split(". ")[1]}
                              </div>
                              <div className="number">
                                {toStringNum(val["value" + handleMonth(month)])}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              );
            })}
            {Array(3 - (data.length % 3))
              .fill(0)
              .map((val, index) => {
                return (
                  <div
                    className="card"
                    key={index}
                    style={{ width: "32%", visibility: "hidden" }}
                  ></div>
                );
              })}
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default StockReport;
