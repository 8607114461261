import React, { useRef, useState } from "react";
import "./index.less";
import { mapping } from "../index";
import { Modal, DatePicker, Form, Select, Checkbox, Input, message } from "antd";
// import printJS from 'print-js'
import html2pdf from "html2pdf.js";
import { Header, Footer } from "../page";
import { useForm } from "antd/es/form/Form";
import CoSelector from "../coSelector";
import locale from "antd/es/date-picker/locale/zh_CN";
import quarterOfYear from "dayjs/plugin/quarterOfYear"; //获取季度需要
import dayjs from "dayjs";
import { handelResp, handleMonth } from "../../../../../utils";
import http from "../../../../../utils/http";
dayjs.extend(quarterOfYear);


export const opts = [
  {
    label: "上半年",
    value: "1H",
  },
  {
    label: "下半年",
    value: "2H",
  },
  {
    label: "一季度",
    value: "1Q",
  },
  {
    label: "二季度",
    value: "2Q",
  },
  {
    label: "三季度",
    value: "3Q",
  },
  {
    label: "四季度",
    value: "4Q",
  },
  {
    label: "全年",
    value: "Y",
  },
]
const optMap = {}
opts.forEach(item=>{
  optMap[item.value] = item.label
})
const Preview = (props) => {
  const { data, global, onCancel } = props;
  const [meta, setMeta] = useState();
  const ref = useRef();
  const [form] = useForm();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("")
  const [loading, setLoading] = useState(false)
  const [isUpload, setIsUpload] = useState(true)

  const print = () => {
    // printJS('print', 'html')
    const filename = title ? title+props.name : props.name + ".pdf"
    var opt = {
      width: "210mm",
      // margin:       1,
      filename,
      image: { type: "jpeg", quality: 0.5 },
      html2canvas: { scale: 2, width: 1080 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    setLoading(true)
    html2pdf().set(opt).from(ref.current).outputPdf('blob').then(async pdfData=>{
      console.log(isUpload)
      if(!isUpload)return
      // console.log(typeof(pdfData), pdfData)
      // const blob = new Blob([pdfData])
      const blob = pdfData
      const formData = new FormData();
      formData.append('file', 
      blob, 
      filename+'.pdf');
      const vals = form.getFieldsValue()
      

      // // 创建Blob URL
      // const blobUrl = URL.createObjectURL(blob);

      // // 创建一个下载链接
      // const downloadLink = document.createElement('a');
      // downloadLink.href = blobUrl;
      // downloadLink.download = 'myFile.pdf'; // 文件名

      // // 添加链接到页面
      // document.body.appendChild(downloadLink);

      // // 模拟用户点击下载链接
      // downloadLink.click();

      // // 释放Blob URL
      // URL.revokeObjectURL(blobUrl);

    const params = {
      co_id: vals.co.co_id,
      year: vals.year.year().toString(),
      month: vals.quarter ? vals.quarter : handleMonth(vals.month.month() + 1),
    }

      const resp = await http.upload('/analyzer/uploadReportFile', params,formData)
      handelResp(resp).then(()=>{
        message.success('上传成功!!')
        setLoading(false)
      })
    }).save().then(()=>{
      setLoading(false)
    });
    
  };

  const renderItem = (items) => {
    return items.map((item, key) => {
      if (item.compType === "wrap") {
        const style = {};
        if (item.formData?.layout === "multi") {
          style.display = "flex";
          style.flexDirection = "row";
          style.justifyContent = "stretch";
          style.alignItems = "stretch";
          style.minHeight = 56;
          style.gap = 10;
          // style.paddingTop = 20
        }
        return (
          <div className="pv-item" style={style}>
            {item.children && renderItem(item.children)}
          </div>
        );
      }
      const info = mapping[item.compType];
      const Comp = info.Comp;
      const props = item.formData ? item.formData : {};
      if (global) {
        const propsInfo = info.props;
        Object.keys(propsInfo).forEach((name) => {
          if (propsInfo[name].isSystem && global[name]) {
            props[name] = global["name"];
          }
        });
      }
      return (
        <div className="pv-item">
          <Comp key={key} {...props} />
        </div>
      );
    });
  };
  const changeSYS = (dts, value) => {
    const {
      year,
      month,
      quarter,
      ytm,
      co: { co_id, co_name },
    } = value;
    // console.log(co_name);
    // const {co_id , co_name} = co
    const data = {
      year: year ? year.year().toString() : null,
      month: month ?  `${ytm ? 'YTM' : ''}${handleMonth(month.month() + 1)}` : null,
      quarter,
      co_id,
      co_name,
    };
    
    // console.log('dts', dts)
    dts.forEach((val) => {
      // console.log('compType', val)
      if(val.compType === 'wrap'){
        changeSYS(val.children, value)
      }
      mapping[val.compType].props.forEach((prop) => {
        if (prop.isSystem && data[prop.name]) {
          val.formData[prop.name] = data[prop.name];
          // if (prop.name === "month") {
            // handleMonth()
          // }
          if (quarter) {
            val.formData.month = quarter;
          }
        }
      });
    });
  };

  const onValueChange = (value, values) => {
    // console.log(values)
    const { year, month, quarter, co } = values;
    if (!(year && (month || quarter) && co)) return;
    const { co_name } = co
    setTitle(
      `${co_name}${year ? year.year().toString() : ""}年${
        quarter
          ? optMap[quarter]
          : `${values.ytm ? "01~" : ""}${handleMonth(month.month() + 1) + "月"}`
      }`
    );
    data.forEach((dts) => {
      changeSYS(dts, values);
    });
    setMeta([...data]);
  };

  const changeDesc = (dts, txt) =>{
    dts.forEach(item=>{
      if(item['compType'] === 'desc'){
        item.formData['desc'] = txt
      }
      if(item['compType'] === 'wrap'){
        changeDesc(item.children, txt)
      }
    })
  }

  const onDescChange = e =>{
    const txt = e.target.value
    data.forEach((dts) => {
      changeDesc(dts, txt);
    });
    setDesc(txt)
  }

  // console.log('data', data)

  return data ? (
    <Modal
      title="预览"
      okText={loading ? '导出中' :"导出PDF"}
      width={1120}
      open={data ? true : false}
      onOk={print}
      onCancel={() => {
        if (onCancel) onCancel();
      }}
      confirmLoading={loading}
    >
      {props.showForm && (
        <div className="pv-form">
          <Form form={form} layout="inline" onValuesChange={onValueChange}>
            <Form.Item label="年" name="year">
              <DatePicker picker="year" />
            </Form.Item>
            <Form.Item label="季度" name="quarter">
              {/* <DatePicker picker="quarter" /> */}
              <Select
                allowClear
                style={{ minWidth: 120 }}
                placeholder="填写季度"
                options={opts}
              ></Select>
            </Form.Item>
            <Form.Item label="月" name="month">
              <DatePicker picker="month" locale={locale} />
            </Form.Item>
            <Form.Item
              name="ytm"
              valuePropName="checked"
              // wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox>累计</Checkbox>
            </Form.Item>
            <Form.Item label="公司" name="co">
              <CoSelector item />
            </Form.Item>
          </Form>
          
        </div>
      )}
      {/* <div className="pv-form">
      <Input.TextArea rows={4} placeholder="总结" onChange={onDescChange}></Input.TextArea>
      </div> */}
      <div id="print">
        <div className="preview" ref={ref}>
          {(meta ? meta : data).map((page, index) => {
            return (
              <div className="pw-content" index={index}>
                <Header name={title + (props.name ? props.name : "")} />
                <div className="pc-content">{renderItem(page)}</div>
                <Footer num={index + 1} />
              </div>
            );
          })}
        </div>
      </div>
      <div style={{display: 'flex', justifyContent:'flex-end', marginTop: 20}}>
        <Checkbox checked={isUpload} onChange={e =>{
          setIsUpload(e.target.checked)
        }} >是否上传</Checkbox>
      </div>
    </Modal>
  ) : null;
};

export default Preview;
