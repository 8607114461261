import React, { useState, useEffect } from "react";
import http from "../../../../../utils/http";
import { handelResp } from "../../../../../utils";
import { handleAnyFields } from "../anySelector";

export const props = [
  {
    label: "内容前",
    type: "input",
    name: "content",
  },
  {
    label: "内容后",
    type: "input",
    name: "end_content",
  },
  {
    label: "文字大小",
    type: "inputNumber",
    name: "fontSize",
  },
  {
    label: "年",
    type: "input",
    name: "year",
    isSystem: true,
  },
  {
    label: "月",
    type: "input",
    name: "month",
    isSystem: true,
  },
  {
    label: "公司名",
    type: "coSelect",
    name: "co_id",
    isSystem: true,
  },
  {
    label: "指标",
    type: "anySelect",
    name: "analyze_id",
    // isSystem: true
    prop:{
      analyze_type: 'T'
    }
  },
];

function Text(props) {
  const { content, end_content, fontSize, year, month, co_id, analyze_id } =
    props;
  const [text, setText] = useState([]);
  const getData = async () => {
    if (!(year && month && co_id && analyze_id)) return;
    const {url, params} = handleAnyFields(analyze_id, {
      co_id,
      year,
      month,
    })
    const resp = await http.get(url, params);
    handelResp(resp).then((data) => {
      setText(data.resultStr ? data.resultStr : []);
    });
  };

  useEffect(() => {
    getData();
  }, [year, month, co_id, analyze_id]);
  return (
    <div className="rg-text" style={{ fontSize }} contenteditable="true">
      {content}
      {text.map((txt, key) => {
            return (
              txt.trim() ? <p key={key} style={{ margin: 0 }}>
                {txt}
              </p> : null
            );
          })
        }
      {end_content}
      {(content||text.length||end_content) ? null : "点击选择需要显示的文字"}
    </div>
  );
}

export default { Comp: Text, props };
