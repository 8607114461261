import React, { useEffect, useState, useRef } from "react";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);

const option = {
  legend: {
    //   data: ['预算', '实绩'],
    //   bottom: 20
  },
  title: {
    text: ''
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // axis: 'y'
    },
    // formatter: '{a}月'
    valueFormatter: (value) => value.toFixed(2),
  },
  xAxis: {
    type: "category",
    data: [],
  },
  grid: {
    left: "1%",
    top: "20%",
    right: "2%",
    bottom: "3%",
    containLabel: true,
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: "{value} 万",
    },
  },
  series: [
    {
      name: "预算",
      data: [],
      type: "line",
      smooth: true,
      color: "#B8E986",
    },
    {
      name: "实绩",
      data: [],
      type: "line",
      smooth: true,
      color: "#3B7CFF",
    },
  ],
};

export const parseW = (val) => {
  // console.log(val)
  return val / 10000;
};
export default (props) => {
  const { data } = props;
  const ref = useRef(null);

  const setChart = (datas) => {
    if (!ref.current) return;
    const eDatas = [];
    const aDatas = [];
    const nums = [];
    Array(12)
      .fill(0)
      .forEach((val, index) => {
        const num = (index + 1).toString();
        nums.push(num);
        eDatas.push(parseW(datas.estimate_data["subject_value_ytm" + num]));
        const month = new Date().getMonth();
        if (month > index) {
          aDatas.push(
            parseW(datas.achievement_data["subject_value_ytm" + num])
          );
        }
      });
    option.xAxis.data = nums;
    option.series[0].data = eDatas;
    option.series[1].data = aDatas;
    option.title.text = datas.achievement_data.subject_name
    const chart = echarts.init(ref.current);
    chart.setOption(option);
  };

  useEffect(() => {
    if(data){
        setChart(data)
    }
  }, [data]);

  return (
    <div style={{width: '60%'}}>
      <div ref={ref} style={{ width: "100%", height: '320px' }}></div>
    </div>
  );
};
