import React, { useState, useEffect } from "react";
import http from "../../utils/http";
import dayjs from "dayjs";
import { useStore } from "./store";
import { doneRate, handelResp, handleMonth } from "../../utils";
import {
  DatePicker,
  Row,
  Col,
  InputNumber,
  Input,
  Select,
  Button,
  message,
} from "antd";
import { CheckCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./weekly.less";

export const cols = [
  "当月目标数",
  "当月累计数",
  "累计完成率",
  "第一周完成数",
  "第二周完成数",
  "第三周完成数",
  "第四周完成数",
];
export const mapping = {
  当月目标数: "m_target",
  当月累计数: "m_total",
  累计完成率: "m_complete_rate",
  第一周完成数: "w1",
  第二周完成数: "w2",
  第三周完成数: "w3",
  第四周完成数: "w4",
};

export function WeeklyForm() {
  const [items, setItems] = useState([]);
  const [datas, setDatas] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [store, _] = useStore();
  const [comps, setComps] = useState([]);
  const [coId, setCoId] = useState(localStorage.getItem("coId"));
  const [loading, setLoading] = useState(false);
  // const [co_type, setco_type] = useState(localStorage.getItem("co_type"))
  const [co_name, setco_name] = useState(localStorage.getItem("co_name"));
  const getItems = async () => {
    const resp = await http.get("/weekly/getWeeklyInputPage", {
      co_id: coId,
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
    });
    handelResp(resp).then((resp) => {
      setItems(resp.weeklyDatas ? resp.weeklyDatas : []);
    });
  };
  const getCopms = async () => {
    const resp = await http.get("/base/selectUserCompany");
    handelResp(resp).then((data) => {
      if (data.userCompanys) {
        setComps(data.userCompanys);
      } else {
        setComps([]);
      }
    });
  };
  const getDatas = async () => {
    const resp = await http.get("/tEnhance/select", {
      co_id: coId,
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      page: 1,
      pageSize: 100,
    });
    handelResp(resp).then((data) => {
      if (data.tEnhances) {
        setDatas(data.tEnhances);
      } else {
        setDatas([]);
      }
    });
  };

  useEffect(() => {
    getItems();
    getCopms();
    getDatas();
  }, [store, coId, date]);

  const btm = 5;
  const disables = ["当月累计数", "累计完成率"];
  const monthFormat = "YYYY/MM";

  const submit = async () => {
    // const dts = []
    // items.forEach(val=>{
    //   const its = val.subitems
    //   its.forEach(it=>{
    //     dts.push(it)
    //   })
    // })
    setLoading(true);
    const resp = await http.put("/weekly/updateWeeklyItems", items);
    handelResp(resp).then(async (data) => {
      await Promise.all(
        datas.map(async (val) => {
          if (!val.id) {
            val.co_id = coId;
            val.year = date.year().toString();
            val.month = handleMonth(date.month() + 1);
          }
          const url = val.id ? "/tEnhance/update" : "/tEnhance/create";
          const resp = await (val.id
            ? http.put(url, val)
            : http.post(url, val));
          handelResp(resp);
        })
      );
      setLoading(false);
      message.success("更新成功");
      getItems();
      getDatas();
    });
  };

  const names = ["项目名", "单位", "目标", "累计完成", "累计完成率", "备注"];
  const units = ["元", "万元", "%", "台", "次", "人"];

  return (
    <div className="weekly">
      <div className="header">
        <div>
          <DatePicker
            style={{ width: 180 }}
            onChange={(datem, dateString) => {
              setDate(datem);
            }}
            defaultValue={date}
            disabledDate={(current) => current > dayjs().endOf("day")}
            picker="month"
            format={monthFormat}
          />
          <Select
            style={{ width: 200 }}
            onChange={(e) => {
              const comp = comps.filter((val) => val.co_id === e)[0];
              setCoId(e);
              setco_name(comp.co_name);
              // setco_type(comp.co_type);
            }}
            value={coId}
          >
            {comps.map((val) => {
              return (
                <Select.Option key={val.co_id}>{val.co_name}</Select.Option>
              );
            })}
          </Select>
        </div>
        <h1>{co_name}日报</h1>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          onClick={submit}
          loading={loading}
        >
          提交
        </Button>
        {/* <div></div> */}
      </div>
      <div className="header-item">
        <Row align="middle" gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col span={3}>
            <div></div>
          </Col>
          {cols.map((col, key) => {
            return (
              <Col key={key} span={3}>
                <h1 style={{ textAlign: "center" }}>{col}</h1>
              </Col>
            );
          })}
        </Row>
      </div>
      {items.map((val, index) => {
        const isT = val.item_type === "01";
        return (
          <React.Fragment key={index}>
            {isT && (
              <div className="item-header">
                <Row
                  style={{ marginBottom: btm }}
                  align="middle"
                  gutter={{ xs: 8, sm: 16, md: 24 }}
                >
                  <Col span={3}>
                    <h1 style={{ textAlign: "left" }}>{val.item_name}</h1>
                  </Col>
                  {cols.map((col, key) => {
                    let value = 0;
                    if (col === "累计完成率" && val.m_target) {
                      value = (
                        ((val.m_total - val.m_target) / Math.abs(val.m_target) +
                          1) *
                        100
                      ).toFixed(2);
                      value = parseFloat(value);
                    } else if (col === "当月累计数") {
                      value = val.w1 + val.w2 + val.w3 + val.w4;
                    } else {
                      val.subitems.forEach((element) => {
                        value += element[mapping[col]];
                      });
                      val[mapping[col]] = value;
                    }
                    value = parseFloat(value.toFixed(2));
                    return (
                      <Col key={key} span={3}>
                        <div style={{ textAlign: "center" }}>
                          {/* <InputNumber
                          value={value}
                          readOnly
                          bordered={false}
                          size="small"
                          formatter={
                            col === "累计完成率" ? (value) => `${value}%` : null
                          }
                        /> */}
                          <p className="number">
                            {value}
                            {col === "累计完成率" ? "%" : null}
                          </p>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            )}
            {val.subitems && (
              <div className="items">
                {val.subitems.map((sub) => {
                  return (
                    <Row
                      style={{ marginBottom: btm }}
                      align="middle"
                      key={sub.id}
                      gutter={{ xs: 8, sm: 16, md: 24 }}
                    >
                      <Col span={3}>
                        {isT ? (
                          <div style={{ textAlign: "right" }}>
                            {sub.subitem_name}
                          </div>
                        ) : (
                          <h1 style={{ textAlign: "left" }}>{val.item_name}</h1>
                        )}
                      </Col>
                      {cols.map((col, key) => {
                        let value = 0;
                        if (col === "当月累计数") {
                          value = sub.w1 + sub.w2 + sub.w3 + sub.w4;
                        } else if (col === "累计完成率" && sub.m_target) {
                          value = (
                            ((sub.w1 + sub.w2 + sub.w3 + sub.w4) /
                              sub.m_target) *
                            100
                          ).toFixed(2);
                          value = parseFloat(value);
                        } else {
                          value = sub[mapping[col]];
                        }
                        return (
                          <Col span={3} key={key}>
                            <div style={{ textAlign: "center" }}>
                              {disables.indexOf(col) > -1 ? (
                                <p className="number">
                                  {value}
                                  {col === "累计完成率" ? "%" : null}
                                </p>
                              ) : (
                                <InputNumber
                                  size="small"
                                  value={value}
                                  onChange={(e) => {
                                    if (e) {
                                      sub[mapping[col]] = e;
                                    } else {
                                      sub[mapping[col]] = 0;
                                    }
                                    setItems([...items]);
                                  }}
                                  formatter={
                                    col === "累计完成率"
                                      ? (value) => `${value}%`
                                      : null
                                  }
                                />
                              )}
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  );
                })}
              </div>
            )}
          </React.Fragment>
        );
      })}

      <div className="line"></div>
      <div
        // className="header"
        style={
          {
            // display: "flex",
            // justifyContent: "space-between",
            // marginBottom: 10,
            // alignItems: "center",
          }
        }
      >
        <h1 style={{ fontSize: 16 }}>整改项目</h1>
      </div>

      <Row style={{ marginBottom: btm }} gutter={16}>
        {names.map((val, index) => {
          return (
            <Col key={index} span={4}>
              <div style={{ textAlign: "left" }}>{val}</div>
            </Col>
          );
        })}
      </Row>
      {datas.map((val, index) => {
        return (
          <Row style={{ marginBottom: btm }} key={index} gutter={16}>
            {names.map((name, index) => {
              let item = <div />;
              switch (name) {
                case "项目名":
                  item = (
                    <Input
                      value={val.item_name}
                      onChange={(e) => {
                        val.item_name = e.target.value;
                        setDatas([...datas]);
                      }}
                    />
                  );
                  break;
                case "单位":
                  item = (
                    <Select
                      value={val.unit}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        // console.log(e);
                        val.unit = e;
                        setDatas([...datas]);
                      }}
                    >
                      {units.map((val) => {
                        return (
                          <Select.Option key={val} value={val}>
                            {val}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  );
                  break;
                case "目标":
                  item = (
                    <InputNumber
                      style={{ width: "100%" }}
                      value={val.target}
                      onChange={(e) => {
                        val.target = e;
                        setDatas([...datas]);
                      }}
                    />
                  );
                  break;
                case "累计完成":
                  item = (
                    <InputNumber
                      style={{ width: "100%" }}
                      value={val.achievement}
                      onChange={(e) => {
                        val.achievement = e;
                        setDatas([...datas]);
                      }}
                    />
                  );
                  break;
                case "累计完成率":
                  item = <div>{(doneRate(val.achievement, val.target)* 100).toFixed(2)}%</div>;
                  break;
                case "备注":
                  item = (
                    <Input
                      value={val.memo}
                      onChange={(e) => {
                        val.memo = e.target.value;
                        setDatas([...datas]);
                      }}
                    />
                  );
                  break;
              }
              return (
                <Col key={index} span={4}>
                  {item}
                </Col>
              );
              // return (
              //   <Col key={index} span={4}>
              //     <div style={{ display: "flex", justifyContent: "center" }}>
              //       {index > 1 ? (
              //         <InputNumber
              //           style={{ width: '100%' }}
              //           value={index === 2 ? val.target : val.achievement}
              //           onChange={(e) => {
              //             if (index === 2) {
              //               val.target = e;
              //             } else {
              //               val.achievement = e;
              //             }
              //             setDatas([...datas])
              //           }}
              //         />
              //       ) : index === 0 ? (
              //         <Input
              //           value={val.item_name}
              //           onChange={(e) => {
              //             val.item_name = e.target.value;
              //             setDatas([...datas])
              //           }}
              //         />
              //       ) : (
              //         <Select
              //           value={val.unit}
              //           style={{ width: '100%' }}
              //           onChange={(e) => {
              //             // console.log(e);
              //             val.unit = e;
              //             setDatas([...datas])
              //           }}
              //         >
              //           {units.map((val) => {
              //             return (
              //               <Select.Option key={val} value={val}>
              //                 {val}
              //               </Select.Option>
              //             );
              //           })}
              //         </Select>
              //       )}
              //     </div>
              //   </Col>
              // );
            })}
          </Row>
        );
      })}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            datas.push({});
            setDatas([...datas]);
          }}
        >
          <PlusOutlined />
        </Button>
      </div>
    </div>
  );
}
