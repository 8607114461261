class Observer {
  
    constructor() {
      this.subs = [];
    }
  
    watch(fn) {
      this.subs.push(fn);
    }
  
    notify(params) {
      this.subs.forEach((fn) => {
        fn(params);
        // console.log('@!#@!#!@#@!', params)
      });
    }
  
    destroy() {
      this.subs = [];
    }
  }
export const RemoveObserver = new Observer();
export const DisposeObserver = new Observer();
