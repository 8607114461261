import React from "react";
import "./style.less";
import Index from './sandbox/index'

function ReportGenerator(props) {
  // const {current, onSave, } = props
  return (
      <div className="report-generator">
        <Index {...props} />
      </div>
  );
}

export default ReportGenerator;
