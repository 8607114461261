import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import http from "../../utils/http";
import { Table, Button, Input, message, Statistic, Upload } from "antd";
import Detail from "./detail";
import Tips from "./tips";
import {
  CheckCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "./contentA.less";
import BtnIcon from "../../assets/icon／审核@2x.png";
import { displayNum } from "../../utils";

export function EditInput(props) {
  const [isFocus, setIsFocus] = useState(false);
  return (
    <Input
      style={{ textAlign: "right" }}
      value={
        isFocus
          ? props.val
          : `${
              props.unit === "元"
                ? props.val
                  ? parseFloat(props.val).toFixed(2)
                  : "0.00"
                : props.val
            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      onChange={(e) => {
        console.log(e.target.value);
        props.onChange(e.target.value);
      }}
      onFocus={() => {
        setIsFocus(true);
      }}
      onPressEnter={(e) => {
        const inputs = window.document.getElementsByClassName("ant-input");
        for (let index = 0; index < inputs.length; index++) {
          const element = inputs[index];
          if (e.target === element && index < inputs.length - 1) {
            console.log(inputs[index + 1].focus());
          }
        }
      }}
    ></Input>
  );
}

function Content() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [datas, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [auth, setAuth] = useState({});
  const [current, setCurrent] = useState();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight)
  window.onresize = (e)=>{
    setInnerHeight(window.innerHeight)
  }
  const [editData, setEditData] = useState({});
  const getData = async () => {
    const month = new Date().getMonth() + 1;
    setLoading(true);
    const resp = await http.get("/base/selectTPageSubjectExt03", {
      co_id: localStorage.getItem("coId"),
      page_id: params.page_id,
      // year: (new Date()).getFullYear(),
      // month:
    });
    // console.log(resp)
    if (resp.code === 200) {
      setMeta(resp.data.tBudgetFlowExt01);
      setAuth(resp.data.pageControlAuth);
      setData(resp.data.tPageSubjectExt03s);
    } else {
      setData([]);
      setMeta({});
      setAuth({});
    }
    setLoading(false);
  };
  useEffect(() => {
    setData([]);
    getData();
  }, [params]);
  const columns = () => [
    {
      title: "科目名称",
      dataIndex: "subject_name",
      key: "subject_name",
      render: (val, row) => {
        // console.log(row)
        // return row.cal_type === '01' ? <span>&nbsp;&nbsp;&nbsp;&nbsp;{val}</span> : row.cal_type === '02' ? <h3 style={{margin: 0}}>{val}</h3> : '-'
        return row.cal_type === "01" ? (
          <Tips
            value={row}
            year={meta.budget_flow_year}
            isA={true}
            unit={row.unit}
          />
        ) : (
          <h3 className="subject-title" style={{ margin: 0 }}>
            {val}
          </h3>
        );
      },
    },
    {
      title: "上年同期",
      dataIndex: "yoy_value",
      key: "yoy_value",
      align: "right",
      render: (val, row) => {
        // console.log(row)
        return row.cal_type === "01" ? displayNum(val, row.unit) : "";
      },
    },
    {
      title: "预算",
      dataIndex: "estimate_value",
      key: "estimate_value",
      align: "right",
      render: (val, row) => {
        // console.log(row)
        return row.cal_type === "01" ? displayNum(val, row.unit) : "";
      },
    },
    {
      title: <span style={{ marginRight: "10px" }}>实绩</span>,
      dataIndex: "achievement_value",
      key: "achievement_value",
      align: "right",
      render: (val, row) => {
        // console.log(row)
        return row.cal_type === "01" ? (
          <div style={{ width: 100, float: "right", marginRight: "10px" }}>
            {/* <InputNumber style={{textAlign: 'right'}} value={val} onChange={e=>{
          console.log(e)
          // console.log(e.currentTarget.value)
          row.achievement_value = e
          const data = {...row}
          data.achievement_value = e? e: 0
          editData[row.subject_id] = data
          setData([...datas])
        }}
        onFocus={()=>{

        }}
        onBlur={()=>{

        }}
        // formatter={value => `${row.unit === '元' ? parseFloat(value).toFixed(2) : value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        ></InputNumber> */}
            {auth.can_write ? (
              <EditInput
                onChange={(e) => {
                  // console.log(e)
                  // console.log(e.currentTarget.value)
                  row.achievement_value = e;
                  const data = { ...row };
                  data.achievement_value = e ? e : 0;
                  editData[row.subject_id] = data;
                  setData([...datas]);
                }}
                unit={row.unit}
                val={val}
              />
            ) : (
              displayNum(val)
            )}
          </div>
        ) : (
          ""
        );
      },
    },
  ];
  const cols = columns();
  const token = localStorage.getItem("token");
  const uploadProps = {
    name: "file",
    method: "put",
    action:
      http.baseUrl +
      `/base/uploadAchievementExcel?page_id=${
        params.page_id
      }&co_id=${localStorage.getItem("coId")}`,
    headers: {
      authorization: "Bearer " + token,
    },
    showUploadList: false,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} 文件上传成功`);
        getData();
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} 文件上传失败`);
        message.error(info.response.msg);
      }
    },
  };
  // console.log(cols)
  return (
    <div className="content no-scroll">
      <div style={{ display: "flex" }} className="content-btns-e">
        <div className="btns">
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={async () => {
              const response = await http.download(
                "/base/exportAchievementExcel",
                {
                  page_id: params.page_id,
                  co_id: localStorage.getItem("coId"),
                }
              );
              var blob = new Blob([response], {
                type: "application/octet-stream",
              });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              link.setAttribute(
                "download",
                decodeURI(params.title + "模版.xlsx")
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}
          >
            下载数据
          </Button>
          {auth.can_write && (
            <div style={{ marginLeft: 20 }}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />} type="primary" ghost>
                  上传数据
                </Button>
              </Upload>
            </div>
          )}
        </div>
        <div className="btns">
          {auth.can_write && (
            <Button
              type="primary"
              icon={<CheckCircleOutlined />}
              className={`save ${
                !(meta.page_status === "00" || meta.page_status === "02") &&
                "not-save"
              }`}
              onClick={async () => {
                // console.log(editData);
                if (!(meta.page_status === "00" || meta.page_status === "02"))
                  return;
                const dts = Object.values(editData).map((val) => {
                  // console.log(val);
                  return {
                    // ...val,
                    co_id: localStorage.getItem("coId"),
                    e_a_type: "A",
                    year: `${parseInt(meta.budget_flow_year)}`,
                    subject_co_id: val.subject_co_id,
                    subject_id: val.subject_id,
                    subject_value: val.achievement_value ? val.achievement_value : '',
                    month: meta.budget_flow_month,
                    id: val.achievement_id,
                    version: val.achievement_version,
                    function_cd: val.function_cd,
                    business_cd: val.business_cd,
                  };
                });
                const resp = await http.put("/base/eaUpdate", dts);
                if (resp.code === 200) {
                  message.success("保存成功");
                  setEditData({});
                } else {
                  message.success(resp.msg);
                }
              }}
            >
              保存
            </Button>
          )}
          <div style={{ marginLeft: 20 }}>
            <Button
              type="primary"
              icon={<img src={BtnIcon} />}
              className={`submit ${
                !(meta.page_status === "00" || meta.page_status === "02") &&
                "not-submit"
              }`}
              onClick={async () => {
                if (!(meta.page_status === "00" || meta.page_status === "02"))
                  return;
                if (!auth.can_submit) return;
                const resp = await http.put(
                  `/base/pageSubmit?page_flow_id=${meta.page_flow_id}`
                );
                if (resp.code === 200) {
                  message.success("提交成功");
                  getData();
                } else {
                  message.error(resp.msg);
                }
              }}
            >
              {!(meta.page_status === "00" || meta.page_status === "02")
                ? "审核中"
                : "提交审核"}
            </Button>
          </div>
        </div>
      </div>
      <h1 style={{ textAlign: "center" }} className="content-title">
        {meta.budget_flow_year}年{meta.budget_flow_month}月
        {params.title}
      </h1>
      <Table
        columns={cols}
        dataSource={datas}
        size="small"
        loading={isLoading}
        pagination={false}
        scroll={{ y: innerHeight - 235 }}
      />
      {current && (
        <Detail
          cancel={() => setCurrent()}
          value={current}
          year={meta.budget_flow_year}
        />
      )}
    </div>
  );
}

export default Content;
