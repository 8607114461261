import React, { useState, useEffect, useRef } from "react";
import http from "../../../../../../utils/http";
import { handelResp, handleMonth } from "../../../../../../utils";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { useStore } from "../../../../../../pages/content/store";
import { Select, Switch, Checkbox } from "antd";
import { parseW } from "../../../../../../pages/content/TipChart";
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);

const props = [
  {
    label: "年",
    type: "input",
    name: "year",
    isSystem: true,
  },
  {
    label: "月",
    type: "input",
    name: "month",
    isSystem: true,
  },
  {
    label: "公司名",
    type: "coSelect",
    name: "co_id",
    isSystem: true,
  },
  {
    label: "图表高度",
    type: "inputNumber",
    name: "height",
  },
  {
    label: "预测",
    type: "customer",
    name: "isF",
    valuePropName: 'checked',
    Comp: <Switch/>
  },
  {
    label: "万元",
    type: "customer",
    name: "isW",
    valuePropName: 'checked',
    Comp: <Switch/>
  },
  {
    label: "累计",
    type: "customer",
    name: "isYtm",
    valuePropName: 'checked',
    Comp: <Checkbox/>
  },
  {
    label: "指标",
    type: "singleSelect",
    name: "sid",
    prop: {
      item: true,
    },
    // isSystem: true
  },
  {
    label: "图表类型",
    type: "customer",
    name: "chartType",
    Comp: <Select>
      <Select.Option key="line">折线图</Select.Option>
      <Select.Option key="bar">柱状图</Select.Option>
    </Select>
  },
];

export const monthMapping = {
  '1H': [0, 6],
  '2H': [6, 12],
  '1Q': [0, 3],
  '2Q': [3, 6],
  '3Q': [6, 9],
  '4Q': [9, 12],
  'Y': [0, 12],
}


function MonthChart(props) {
  const { year, month, co_id, sid, chartType, height, isF, isYtm, isW } = props;
  const [opt, setOpt] = useState();
  const ref = useRef();
  const [store, setStore] = useStore();
  const getData = async () => {
    // const resp = await http.put("/indi/getIndicatorMonthlyYTM05", {
    const resp = await http.put("/forecast/getIndiForecast", {
      co_id: co_id,
      month: month,
      // subject_ids: sid ? [sid.id] : [],
      indis: sid ? [sid.id] : [],
      year: year,
    });
    // console.log(resp)
    if (resp.code === 200) {
      handelResp(resp).then((data) => {
        if (!data.forecasts.length) return;
        // setChart(data.subjectReportData05s[0]);
        setChart(data.forecasts[0]);
      });
    }
  };

  const setChart = (datas) => {
    // console.log(datas)

    const eDatas = [];
    let eYtm = 0
    const aDatas = [];
    let aYtm = 0
    const pData = [];
    let pYtm = 0
    const fData = [];
    let fYtm = 0
    const nums = [];
    const isQuter = monthMapping[month]
    console.log('isQuter', isQuter, parseInt(month))
    // const gap = isQuter ? isQuter[1] - isQuter[0] : parseInt(month)
    Array(12)
      .fill(0)
      .forEach((val, index) => {
        const offset = isQuter ? isQuter[0] : 0
        const num = (index + 1 + offset).toString();
        nums.push(num);
        eYtm += datas["estimate_value_m" + handleMonth(num)]
        eDatas.push(isW ? parseW(eYtm) :eYtm);
        pYtm += datas["past1_value_m" + handleMonth(num)]
        pData.push(isW ? parseW(pYtm) :pYtm);
        fYtm += datas["forecast_value_m" + handleMonth(num)]
        fData.push(isW ? parseW(fYtm) :fYtm);
        const mt = store.a ? store.a.month : new Date().getMonth();
        const yr = store.a ? store.a.year : new Date().getFullYear();
        if(parseInt(year) < parseInt(yr)){
          aYtm += datas["achievement_value_m" + handleMonth(num)]
          aDatas.push(isW ? parseW(aYtm) :aYtm);
        }else{
          if(index < parseInt(mt)){
            aYtm += datas["achievement_value_m" + handleMonth(num)]
            aDatas.push(isW ? parseW(aYtm) :aYtm);
          }
        }
        if(!isYtm){
          eYtm = 0
          aYtm = 0
          fYtm = 0
          pYtm = 0
        }
      });
    const option = {
      legend: {
        //   data: ['预算', '实绩'],
          top: '10%'
      },
      title: {
        text: sid?.ind_name + (isYtm ? "累计" : ''),
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // axis: 'y'
        },
        // formatter: '{a}月',
        valueFormatter: (value) => value.toFixed(2) + (isW ? '万': ''),
      },
      xAxis: {
        type: "category",
        data: [],
      },
      grid: {
        left: "1%",
        top: "20%",
        right: "2%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: isW ? "{value}万" : "{value}",
        },
      },
      series: [
        {
          name: "预算",
          data: [],
          type: chartType ? chartType : "line",
          smooth: true,
          color: "#B8E986",
        },
        {
          name: "实绩",
          data: [],
          type: chartType ? chartType : "line",
          smooth: true,
          color: "#3B7CFF",
        },
        {
          name: "同期",
          data: [],
          type: chartType ? chartType : "line",
          smooth: true,
          color: "#F7B500",
        },
        
      ],
    };
    option.xAxis.data = nums;
    // option.yAxis.axisLabel.formatter = "{value}";
    option.series[2].data = pData;
    // option.series[3].data = fData;
    option.series[0].data = eDatas;
    option.series[1].data = aDatas;
    if(isF){
      option.series.push({
        name: "预测",
        data: fData,
        type: chartType ? chartType : "line",
        smooth: true,
        color: "#9e9e9e",
      })
    }
    // option.title.text = datas.co_name + datas.subject_name;
    setOpt(option);
  };

  useEffect(() => {
    if (!ref.current) return;
    if (!opt) return;
    const chart = echarts.init(ref.current);
    chart.setOption(opt);
    return () => {
      chart.clear();
      chart.dispose();
    };
  }, [opt, month, co_id, sid]);

  useEffect(() => {
    // console.log(year, month, co_id, sid)
    // console.log(year & month & co_id & sid)
    if (year && month && co_id && sid) {
      getData();
    }
  }, [year, month, co_id, sid]);

  return <div className="rg-chart" style={{height: height ? height : null}} ref={ref}></div>;
}

export default { Comp: MonthChart, props };
