import React, { useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import http from "../../utils/http";
import { EditInput } from "./detail";

function Detail(props) {
  const [datas, setDatas] = useState([]);

  const onOk = async () => {
    const params = [];

    datas.forEach((element) => {
      const val = {};
      if(!element.is_edit)return
      val.business_cd = props.value.business_cd;
      val.co_id = localStorage.getItem("coId");
      val.e_a_type = props.value.e_a_type;
      val.function_cd = props.value.function_cd;
      params.push({
        ...val,
        e_a_type: "R",
        year: props.year,
        id: element.rolling_id,
        month: element.month_key,
        subject_co_id: props.value.subject_co_id,
        subject_id: props.value.subject_id,
        subject_value: element.rolling_subject_value
          ? element.rolling_subject_value.toString()
          : "",
        version: element.rolling_version,
      });
    });
    const resp = await http.put("/base/eaUpdate", params);
    if (resp.code === 200) {
      message.success("提交成功");
      props.cancel();
    } else {
      message.success(resp.msg);
    }
  };

  const getData = async () => {
    const resp = await http.post("/base/selectTAchievementExt01", {
      ...props.value,
      year: props.year,
      co_id: localStorage.getItem("coId"),
    });
    console.log(resp);

    if (resp.code === 200) {
      setDatas(resp.data.tAchievementExt01s);
    }
  };

  useEffect(() => {
    getData();
  }, [props]);

  return (
    <div>
      <Modal
        title={props.value.subject_name}
        visible={true}
        onOk={onOk}
        onCancel={props.cancel}
        okText="保存"
        cancelText="取消"
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "15%" }}></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "80%",
            }}
          >
            <h3 style={{ textAlign: "right", width: "50%" }}>
              {parseInt(props.year)}年预算
            </h3>
            <h3 style={{ textAlign: "right", width: "50%" }}>{props.year}年</h3>
          </div>
        </div>

        {datas.map((item, index) => {
          const month = parseInt(props.month);
          return (
            <div
              style={{
                display: "flex",
                marginBottom: 10,
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div style={{ width: "10%" }}>{item.month_display}:</div>
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "80%",
                }}
              >
                <p style={{ margin: 0, width: 150, textAlign: "right" }}>
                  {item.estimate_subject_value}
                </p>
                
                {month >= index + 1 ? (
                  <p style={{ margin: 0, width: 150, textAlign: "right" }}>
                    {item.achievement_subject_value}
                  </p>
                ) : (
                  <EditInput
                    val={item.rolling_subject_value}
                    unit={props.value.unit}
                    onChange={(e) => {
                      if (e) {
                        item.rolling_subject_value = e;
                      } else {
                        item.rolling_subject_value = null;
                      }
                      item.is_edit = true
                      setDatas([...datas]);
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </Modal>
    </div>
  );
}

export default Detail;
