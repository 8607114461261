import React, {useState} from "react";
import UploadImg from "../../assets/icon／上传@2x.png";
import DownloadImg from "../../assets/icon／下载@2x.png";
import VerifyImg from "../../assets/icon／审核@2x.png";
import "./uploadExport.less";
import http from "../../utils/http";
import { useStore } from "../content/store";
import { Upload, message, Modal } from "antd";
import { handelResp, handleMonth } from "../../utils";
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';


const menusMapping = {
  A: "实绩",
  E: "预算",
  RE: "预算报表",
  RA: "实绩报表",
  M: "管理",
};

const { confirm } = Modal

function UploadExport(props) {
  const { type, business_cd, year, month } = props;
  //   console.log(type, business_cd);
  const [store, setStore] = useStore();
  const [exporting, setExporting] = useState(false)
  //   console.log(store);
  const exportFile = async () => {
    const urls = {
      RA: "/base/exportAchievementReportExcel",
      A: "/base/exportBusinessAchievementExcel",
      E: "/base/exportBusinessEstimateExcel",
      RE: "/base/exportEstimateReportExcel",
    };
    // const month = new Date().getMonth() + 1;
    // const year = new Date().getFullYear();
    const path = urls[type];
    // return http.baseUrl + path + `?co_id=${store.coId}&business_cd${business_cd}`;
    const data = {
      co_id: store.coId,
      business_cd,
      //   year,
      //   month:
      //     type.indexOf("E") > -1
      //       ? undefined
      //       : (month < 9 ? "0" : "") + month.toString(),
    };

    if (type == "RA") {
      data["month"] = handleMonth(month);
      data["year"] = year;
    }
    if (type === "RE") {
      data["year"] = year;
    }
    setExporting(true)
    const response = await http.download(path, data);
    handelResp(response)
    var blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      decodeURI(
        // `${store.co_name}-${year}年-${month}月-${
        //   type.length === 1 ? menusMapping[type] : ""
        // }${name}` + ".xlsx"
        response.headers["content-disposition"].split("''")[1]
      )
    );
    document.body.appendChild(link);
    link.click();
    setExporting(false)
    document.body.removeChild(link);
  };

  const genUploadProps = () => {
    const token = localStorage.getItem("token");
    const paths = {
      A: "uploadBusinessAchievementFromExcel",
      E: "uploadBusinessEstimateFromExcel",
    };
    let url =
      http.baseUrl +
      `/base/${paths[type]}?business_cd=${business_cd}&co_id=${store.coId}`;
    // if (type === "A") {
    //   url += `&month=${new Date().getMonth + 1}`;
    // }
    return {
      name: "file",
      method: "put",
      action: url,
      headers: {
        authorization: "Bearer " + token,
      },
      showUploadList: false,
      onChange(info) {
        console.log(info)
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
          message.success(
            `${localStorage.getItem("co_name")}-${info.file.name} 文件上传成功`
          );
          window.location.reload();
        } else if (info.file.status === "error") {
          // message.error(`${info.file.name} 文件上传失败`);
          message.error(info.file.response.msg);
        }
      },
    };
  };
  return (
    <div className="upload-export" style={{backgroundColor: exporting ? '#1ba57a6e' : ''}}>
      <div className="btn-item" onClick={exportFile} >
        {exporting ? <LoadingOutlined /> :<img src={DownloadImg} alt="" />}
        {type.length === 1 ? '' : exporting ? '导出中...':"导出数据"}
      </div>
      {type.length === 1 && (
        <>
          <div className="line"></div>
          <Upload {...genUploadProps()}>
            <div className="btn-item">
              <img src={UploadImg} alt="" />
              {type.length === 1 ? '' : "上传数据"}
            </div>
          </Upload>
        </>
      )}
      {type.length === 1 && (
        <>
          <div className="line"></div>
          <div className="btn-item" onClick={async ()=>{
            confirm({
              title: '确认提交审核吗',
              icon: <ExclamationCircleOutlined />,
              onOk: async () => {
                const resp = await http.put(`/base/pageBusinessSubmit?business_cd=${business_cd}&page_type=${type}`)
                if(resp.code===200){
                  message.success('提交成功')
                  // window.location.reload()
                  if(props.onSubmit){
                    props.onSubmit()
                  }
                }else{
                  message.error(resp.msg)
                }
              },
              onCancel() {

              }
            })
            
          }}>
            <img src={VerifyImg} alt="" />
          </div>
        </>
      )}
    </div>
  );
}

export default UploadExport;
