import React, {useEffect, useRef} from 'react';
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
]);

const option = {
  // legend: {
  //   //   data: ['预算', '实绩'],
  //     bottom: 20
  // },
  // title: {
  //   text: ''
  // },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // axis: 'y'
    },
    // formatter: '{b0}: {c0}<br />{b1}: {c1}',
    position: function (pos, params, dom, rect, size) {
      // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
      var obj = {top: pos[1]};
      // obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = pos[0];
      if(pos[0] < size.viewSize[0] / 2){
        obj['right'] = size.viewSize[0] / 2 - pos[0] + 60
      }else{
        obj['left'] =  pos[0] - size.viewSize[0] / 2 + 60
      }
      return obj;
    },
    valueFormatter: (value) => value ? value.toFixed(2) : 0,
  },
  xAxis: {
    // type: "category",
    data: [],
    axisTick:{
      show: false
    }
  },
  grid: {
    left: "1%",
    top: "10%",
    right: "2%",
    bottom: "3%",
    containLabel: true,
  },
  yAxis: {
    type: "value",
    axisLabel: {
      formatter: "{value} 万",
    },
  },
  series: [],
};

export const parseW = (val) => {
  // console.log(val)
  return val / 10000;
};

function TipChart(props) {

  const { data, tags, colors } = props
  const ref = useRef(null);
  const setChart = ()=>{
    option.xAxis.data = []
    option.series = []
    if (!ref.current) return;
    const tag = {}
    tags.forEach(element => {
      tag[element['title']] = []
    });
    const xs = []
    const isW = data.unit === '元'
    Array(12).fill(0).forEach((_, index)=>{
      const current = (index + 1)
      xs.push(current.toString()+ '月')
      const num = (current < 10 ? "0": "") + (index + 1).toString();
      tags.forEach(element => {
        const prefix = element['prefix']
        if (data[prefix + num]){
          tag[element['title']].push(isW ? parseW(data[prefix + num]) : data[prefix + num])
        }else{
          tag[element['title']].push(null)
        }
      });
    })
    option.xAxis.data = xs;
    option.yAxis.axisLabel.formatter = isW ? "{value} 万" : "{value}";
    tags.forEach((element, index) => {
      option.series.push({
        name: element['title'],
        data: tag[element['title']],
        type: "line",
        smooth: true,
        color: colors[index],
      })
    });
    
    const chart = echarts.init(ref.current);
    chart.setOption(option);
  }

  useEffect(() => {
    if(Object.keys(data).length){
        setChart(data)
    }
  }, [data]);

  return (
    <div className='tip-chart' style={{width: '100%', height: '120px'}}>
      <div ref={ref} style={{ width: "100%", height: '120px' }}></div>
    </div>
  );
}

export function TipTableChart(props){

} 

export default TipChart;
