import React, { useState, useEffect } from "react";
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    MinusOutlined,
} from '@ant-design/icons';
import './index.less'
import top from './icon／升高@2x.png'
import low from './icon／降低@2x.png'
import nor from './icon／持平@2x.png'

export default function indicator(props) {

    const value = props.value
    // const unit = props.value
    // console.log(value)
    return <div className="indicato r">
        <span className={value > 0 ? 'add' : value < 0 ? 'min' : 'zero'}>
            {value > 0 ? '+' : ''}
            {value ? value.toFixed(2) : 0}%
            <span style={{ marginRight: 5 }} />{value > 0 ? 
            <img src={top} alt="" style={{height: 14, width: 11}}/> : 
            value < 0 ? <img src={low} alt=""  style={{height: 14, width: 11}}/> : 
            <img src={nor} alt=""  style={{height: 3, width: 12}} />}</span>
    </div>
}