import React, {useState, useEffect} from 'react';
import http from '../../utils/http';
import { useStore } from './store';
import dayjs from 'dayjs';
import { DatePicker, Button, Table, message } from 'antd'
import { handelResp } from '../../utils';
import ButtonGroup from 'antd/es/button/button-group';
import { useParams } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
function RpList() {
  const [list, setList] = useState([])
  const [year, setYear] = useState(dayjs().year())
  const [store , _] = useStore()
  const params = useParams()
  const page_id = params.page_id;

  const getList = async ()=>{
    const resp = await http.get('/base/getReportFileList', {
      co_id: store.coId,
      year
    })
    handelResp(resp).then(data=>{
      if(data.tReportFiles){
        setList(data.tReportFiles)
      }else{
        setList([])
      }
    })
  }

  const onChange = (date, dateString)=>{
    // console.log(dateString)
    setYear(dateString)
  }


  useEffect(()=>{
    getList()
  }, [store, year])


  const onDownLoad = async row =>{
    const response = await http.download(`/base/downloadReportFile?file_id=${row.id}`)
    console.log(response)
    var blob = new Blob([response.data], {
      type: "application/octet-stream",
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      decodeURI(row.report_name)
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const deleteRow = async row =>{
    const resp = await http.delete(`/analyzer/deleteReportFile?id=${row.id}`)
    handelResp(resp).then(data=>{
      message.success('删除成功')
      getList()
    })
  }
  return (
    <div className='content-rp-list'>
      <div className="rl-list-head" style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className="head-left">
          {/* <Button type="primary">上传</Button> */}
        </div>
        <div className="head-right">
          <DatePicker defaultValue={dayjs()} onChange={onChange} picker="year" />
        </div>
      </div>
      <Table dataSource={list} columns={[
        {
          title: '报告名称',
          dataIndex: 'report_name',
          // key: 'report_name'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          // key: 'create_time',
          render: val =>{
            return dayjs(val).utcOffset(0).format('YYYY-MM-DD HH:mm')
          }
        },
        {
          title: '更新时间',
          dataIndex: 'update_time',
          // key: 'update_time',
          render: val =>{
            return dayjs(val).utcOffset(0).format('YYYY-MM-DD HH:mm')
          }
        },
        {
          title: '操作',
          key: 'opt',
          render: row =>{
            return <ButtonGroup>
                <Button type="primary" size="small" onClick={()=>onDownLoad(row)}>下载</Button>
                { store.auths && store.auths[page_id]&2 === 2 ? <Button type="primary" danger size="small" onClick={()=>deleteRow(row)}>删除</Button> : null}
            </ButtonGroup>
          }
        },
      ]} pagination={false} size="small"/>
    </div>
  );
}

export default RpList;
