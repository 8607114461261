import React, { useEffect, useState, useRef } from 'react'
import http from '../../utils/http'
import { Card } from 'antd'
import './style.less'
import * as echarts from 'echarts/core';
import {
    // 系列类型的定义后缀都为 SeriesOption
    LineChart,
    BarChart,
    PieChart,
} from 'echarts/charts';
import {
    TitleComponent,
    // 组件类型的定义后缀都为 ComponentOption
    GridComponent, LegendComponent,
    ToolboxComponent, TooltipComponent,
    DataZoomComponent,
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import { parseW } from '../eaCharts'
import { useStore } from '../../pages/content/store';
import { handelResp } from '../../utils';

// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use(
    [TitleComponent, GridComponent, BarChart, DataZoomComponent,
        LineChart, CanvasRenderer, LegendComponent, PieChart,
        TooltipComponent, ToolboxComponent]
);

const currentYear = (new Date()).getFullYear()

const option = {
    legend: {
        data: [`${currentYear - 3}年实绩`, `${currentYear - 2}年实绩`, `${currentYear - 1}年实绩`, `${currentYear}年实绩`]
    },
    grid: {
        left: '1%',
        // right: '4%',
        bottom: '3%',
        containLabel: true
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            // axis: 'y'
        },
        // formatter: '{a}月'
        valueFormatter: (value) => value.toFixed(2)
    },
    xAxis: [
        {
            type: 'category',
            // axisTick: { show: false },
            data: [],
            splitLine:{
                show:true
            }
        },
    ],
    yAxis: [
        {
            type: 'value',
            axisLabel: {
                formatter: '{value} 万'
            }
        }
    ],
    series: [
        {
            name: `${currentYear - 3}年实绩`,
            type: 'bar',
            data: []
        },
        {
            name: `${currentYear - 2}年实绩`,
            type: 'bar',
            data: []
        },
        {
            name: `${currentYear - 1}年实绩`,
            type: 'bar',
            data: []
        },
        {
            name: `${currentYear}年实绩`,
            type: 'bar',
            data: []
        }
    ]
};

export default props => {

    const { id, year, month, cost_id } = props
    const [datas, setDatas] = useState()
    const ref = useRef(null)
    const [store, setStore] = useStore()
    const isSuper = store.isSuper

    const setChart = (datas) => {
        if (!ref.current) return
        const achievement_past3year = []
        const achievement_past2year = []
        const achievement_past1year = []
        const achievement_thisyear = []
        const cate = []
        datas.forEach(val => {
            if(isSuper){
                cate.push(val.co_name)
            }else{
                cate.push(val.business_name)
            }
            
            achievement_past3year.push(parseW(val.achievement_past3year))
            achievement_past2year.push(parseW(val.achievement_past2year))
            achievement_past1year.push(parseW(val.achievement_past1year))
            achievement_thisyear.push(parseW(val.achievement_thisyear))
        })
        if(!isSuper){
            option.series.forEach(val=>{
                val.stack = 'Ad'
            })
        }
        
        option.series.forEach(val=>{
            val.itemStyle = {
                borderRadius: 3,
              }
        })
        option.xAxis[0].data = cate
        option.series[0].data = achievement_past3year
        option.series[1].data = achievement_past2year
        option.series[1].data = achievement_past1year
        option.series[1].data = achievement_thisyear
        const chart = echarts.init(ref.current)
        chart.setOption(option)

    }
    const getData = async () => {
        let cost_url = '/base/getCompanyProfitYearly'
        let url = '/base/getCompanySubjectYearly'
        
        if(!isSuper){
            url = '/base/getBusinessSubjectYearly'
            cost_url = '/base/getBusinessProfiteYearly'
        }

        const resp = await http.get(cost_id ? cost_url : url, {
            subject_id: cost_id ? null : id,
            // co_id: localStorage.getItem("coId").split('-')[0],
            co_id: store.coId,
            year,
            month: cost_id ? null : '02',
            income_subject_id: cost_id ? id : null,
            cost_subject_id: cost_id
        })
        handelResp(resp).then(()=>{
            if(isSuper){
                setDatas(resp.data.companySubjectYearlys)
                setChart(resp.data.companySubjectYearlys)
            }else{
                setDatas(resp.data.businessSubjectYearlys)
                setChart(resp.data.businessSubjectYearlys)
            }
        })
    }



    useEffect(() => {
        getData()
    }, [store])

    return <div className="companys-chart">
        <Card>
            <div className="chart" ref={ref} style={{ width: '100%', height: 400 }}></div>
        </Card>
    </div>
}