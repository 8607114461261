import React, { useEffect, useRef, useState } from "react";
import "./style.less";
import * as echarts from "echarts/core";
import {
  // 系列类型的定义后缀都为 SeriesOption
  LineChart,
  BarChart,
  PieChart,
  RadarChart,
} from "echarts/charts";
import {
  TitleComponent,
  // 组件类型的定义后缀都为 ComponentOption
  GridComponent,
  LegendComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { handelResp } from "../../../../../utils";
import http from "../../../../../utils/http";
import { handleAnyFields } from "../anySelector";

// 注册必须的组件
echarts.use([
  TitleComponent,
  GridComponent,
  BarChart,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  TooltipComponent,
  ToolboxComponent,
  RadarChart,
]);

const option = {
  title: {
    // text: 'World Population'
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    valueFormatter: (value) => value.toFixed(2),
  },
  legend: {},
  grid: {
    left: "1%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    axisLabel: {
      // formatter: '{value} 万'
    },
  },
};
function format(num) {
  return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
}

function Charts(props) {
  const { option, year, month, co_id, analyze_id } = props;
  const ref = useRef();

  const setChart = () => {
    if (!ref.current) return;
    if (!option) return;
    const chart = echarts.init(ref.current);
    chart.setOption(option);
    // console.log('reRender', option.title)
    return () => {
      chart.clear();
      chart.dispose();
    };
  };

  useEffect(() => {
    return setChart();
  }, [option, year, month, co_id, analyze_id]);

  return <div className="rg-chart" ref={ref} style={props.style}></div>;
}

const props = [
  // {
  //   label: "公司名",
  //   type: "input",
  //   name: "co_name",
  //   isSystem: true
  // },
  {
    label: "年",
    type: "input",
    name: "year",
    isSystem: true,
  },
  {
    label: "月",
    type: "input",
    name: "month",
    isSystem: true,
  },
  {
    label: "公司名",
    type: "coSelect",
    name: "co_id",
    isSystem: true,
  },
  
];

const anySelect = {
  label: "指标",
  type: "anySelect",
  name: "analyze_id",
  // isSystem: true
}
const prop = {
  item: true,
}

const getPartData = async (year, month, co_id, analyze_id) => {
  // console.log('getPartData:', year, month, co_id, analyze_id)
  if (!(year && month && co_id && analyze_id)) return;
  const {url, params} = handleAnyFields(analyze_id, {
    co_id,
    year,
    month,
    // analyze_id,
  })
  const resp = await http.get(url, params);
  // console.log('getAnalyzeIndi', resp)
  return new Promise((resolve, reject) => {
    handelResp(resp)
      .then((data) => {
        if (!data.resultData) return resolve([]);
        resolve(data.resultData);
      })
      .catch((e) => reject(e));
  });
};

const useOpt = () => {
  const [options, setOption] = useState();

  // const setOpt = (props, func)=>{

  // }
  const setOpt = async (props, func) => {
    
    const { year, month, co_id, analyze_id } = props;
    const datas = await getPartData(year, month, co_id, analyze_id);
    // console.log(!(year && month && co_id && analyze_id), year, month, co_id, analyze_id, 'setting data', datas)
    let isSubject = true;
    if (!datas) return;
    // console.log('isSubject', datas)
    if (datas.length > 1) {
      if (datas[0].subject_name === datas[1].subject_name) {
        isSubject = false;
      }
    }
    // console.log('isSubject', isSubject)
    // console.log('isSubject——datas', datas)
    const tmp_data = []
    datas.forEach(val=>{
      if(val.subject_name !== '合计'){
        tmp_data.push(val)
      }
    })

    let title = ""

    if(analyze_id.mode){
      if(analyze_id.mode === "FREE"){
        title = analyze_id.anyFree.analyze_name
      }else{
        title = analyze_id.any.analyze_name
      }
    }else{
      title = analyze_id.analyze_name
    }

    const opt = func(tmp_data, isSubject, title);
    // console.log('setting', opt.title)
    setOption(opt);
  };

  return [options, setOpt];
};

const Piec = (props) => {
  const { year, month, co_id, analyze_id } = props;
  const [opt, setOpt] = useOpt();

  const renderOpt = (datas, isSubject, title) => {
    const opt = {
      // tooltip: {
      //   trigger: 'item'
      // },
      title: {
        text: title
      },
      // legend: {
      //   bottom: 0,
      // },
      grid: {
        left: "1%",
        top: "10%",
        right: "2%",
        bottom: "3%",
        containLabel: true,
      },
    };
    const dt = {
      // name: '',
      type: "pie",
      radius: ["40%", "70%"],
      avoidLabelOverlap: false,
      label: {
        show: true,
        // position: "center",
        formatter(param) {
          // correct the percentage
          return param.name + ' (' + param.percent + '%)';
        }
      },
      // emphasis: {
      //   label: {
      //     show: true,
      //     fontSize: 40,
      //     fontWeight: "bold",
      //   },
      // },
      labelLine: {
        show: true,
      },
      data: datas.map((val) => {
        return {
          value: val.achievement_value,
          name: isSubject ? val.subject_name : val.co_name,
        };
      }).filter(val=>val.value !== 0 ? true : false),
    };
    opt.series = [dt];
    // console.log("getOption", opt);
    return opt;
  };

  useEffect(() => {
    setOpt(props, renderOpt);
  }, [year, month, co_id, analyze_id]);

  return <Charts option={opt} {...props} />;
};

const Piea = (props) => {
  const { year, month, co_id, analyze_id } = props;
  const [opt, setOpt] = useOpt();

  const renderOpt = (datas, isSubject, title) => {
    const opt = {
      // tooltip: {
      //   trigger: 'item'
      // },
      title: {
        text: title,
      },
      // legend: {
      //   bottom: 0,
      // },
      // label: {
      //   show: true,
      //   // position: "center",
      // },
      grid: {
        left: "1%",
        top: "10%",
        right: "2%",
        bottom: "3%",
        containLabel: true,
      },
    };
    const dt = {
      type: "pie",
      radius: "50%",
      label: {
        show: true,
        formatter(param) {
          // correct the percentage
          return param.name + ' (' + param.percent + '%)';
        }
      },
      // emphasis: {
      //   itemStyle: {
      //     shadowBlur: 10,
      //     shadowOffsetX: 0,
      //     shadowColor: "rgba(0, 0, 0, 0.5)",
      //   },
      // },
      data: datas.map((val) => {
        return {
          value: val.achievement_value,
          name: isSubject ? val.subject_name : val.co_name,
        };
      }).filter(val=>val.value !== 0 ? true : false),
    };
    opt.series = [dt];
    console.log("getOption", opt);
    return opt;
  };

  useEffect(() => {
    setOpt(props, renderOpt);
  }, [year, month, co_id, analyze_id]);

  return <Charts option={opt} {...props} />;
};

const Process = (props) => {
  const { year, month, co_id, analyze_id } = props;
  const [opt, setOpt] = useOpt();

  const renderOpt = (datas, isSubject) => {
    const opt = {
      title: {
        // 标题组件，包含主标题和副标题
        text: "", // 主标题文本，支持使用 \n 换行。
        top: "0", // title 组件离容器上侧的距离。top 的值可以是像 20 这样的具体像素值，可以是像 '20%' 这样相对于容器高宽的百分比，也可以是 'top', 'middle', 'bottom'
        left: "0", // title 组件离容器左侧的距离。 ...同上
        textStyle: {
          // 主标题文字的设置
          fontSize: 16,
          fontWeight: "normal",
        },
      },
      series: [
        {
          type: "gauge", // 测量仪
          center: ["50%", "50%"], // 图形坐标位置 左, 右
          radius: "95%", // 控制饼图的大小
          startAngle: 90, // 开始位置
          endAngle: -270, // 结束位置
          pointer: {
            // 显示仪表盘指针
            show: false, // 是否显示仪表盘指针
          },
          progress: {
            // 展示当前进度。
            show: true, // 是否展示进度条
            // overlap: false, // 是否重叠
            // width: ,
            roundCap: true, // 是否圆顶
            // clip: false, // 是否裁掉超出部分
            itemStyle: {
              // 设置圆形
              borderWidth: 2,
              borderColor: "#1f78b4",
              color: "#1f78b4", // 设置进度条初期式样
            },
          },
          axisLine: {
            // 设置线条
            lineStyle: {
              width: 4, // 线条宽度
              color: [[1, "#E8EBF0"]], // 不透明度, 线条设置背景色
            },
          },
          splitLine: {
            // 分隔线样式。
            show: false, // 是否显示分隔线。
            distance: 0, // 分隔线与轴线的距离。
            length: 1033, // 分隔线线长。支持相对半径的百分比。
          },
          axisTick: {
            // 刻度样式。
            show: false, // 是否显示刻度。
          },
          axisLabel: {
            //刻度标签。
            show: false, // 是否显示标签。
            distance: 50, // 标签与刻度线的距离。
          },
          label: {
            show: true,
            formatter(param) {
              // correct the percentage
              return param.name + ' (' + param.percent  + '%)';
            }
          },
          data: [
            {
              value: 64, // 可写变量  此value 对应 formatter: '{value}%' 中的Value  64类型不对请用'64'
              detail: {
                // 仪表盘边框数据详情，用于显示数据。
                valueAnimation: false, // 是否开启标签的数字动画。
                offsetCenter: ["0%", "0%"], // 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
                fontSize: 14, // 文字的字体大小。
              },
            },
          ],
          detail: {
            //仪表盘详情，用于显示数据 仪表盘中间数字数据。
            color: "#1f78b4", // 文本颜色，默认取数值所在的区间的颜色
            formatter: "{value}%", // 格式化函数或者字符串  formatter: function (value) { return value.toFixed(0);}
            // width: 20,
            // height: 14,
            // fontSize: 14,
            // borderColor: 'auto',
            // borderRadius: 20,
            // borderWidth: 1,
          },
        },
      ],
    };
    // const dt = {
    //   type: 'pie',
    //   radius: '50%',
    //   emphasis: {
    //     itemStyle: {
    //       shadowBlur: 10,
    //       shadowOffsetX: 0,
    //       shadowColor: 'rgba(0, 0, 0, 0.5)'
    //     }
    //   },
    //   data: datas.map(val=>{
    //     return {
    //       value: val.achievement_value,
    //       name: isSubject ? val.subject_name: val.co_name
    //     }
    //   })
    // }
    // opt.series = [dt]
    option.series[0].data[0].value = 21;
    console.log("getOption", opt);
    return opt;
  };

  useEffect(() => {
    setOpt(props, renderOpt);
  }, [year, month, co_id, analyze_id]);

  return <Charts option={opt} />;
};

const Bar = (props) => {
  const { year, month, co_id, analyze_id } = props;
  const [opt, setOpt] = useOpt();

  const renderOpt = (datas, isSubject, title) => {
    const option = {
      title: {
        text: title,
      },
      legend: {
        data: ["预算", "实绩", "同期数"],
        top: 0,
        right: 0,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          // axis: 'y'
        },
        // formatter: '{a}月'
        valueFormatter: (value) => value.toFixed(2),
      },
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 30
        },
        data: [],
      },
      grid: {
        left: "1%",
        top: "20%",
        right: "2%",
        bottom: "3%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "{value} ",
        },
      },
      series: [
        {
          name: "预算",
          data: [],
          // label: {
          //   show: true
          // },
          type: "bar",
          color: "#B8E986",
        },
        {
          name: "实绩",
          data: [],
          type: "bar",
          color: "#3B7CFF",
        },
        {
          name: "同期数",
          data: [],
          type: "bar",
          color: "#F7B500",
        },
      ],
    };
    datas.forEach((data) => {
      option.xAxis.data.push(isSubject ? data.subject_name : data.co_name);
      option.series[0].data.push(data.estimate_value);
      option.series[1].data.push(data.achievement_value);
      option.series[2].data.push(data.sply_value);
    });
    return option;
  };
  useEffect(() => {
    // console.log('useEffect:', props.analyze_id)
    setOpt(props, renderOpt);
  }, [year, month, co_id, analyze_id]);

  return <Charts option={opt} {...props} />;
};

const Radar = (props) => {
  const { year, month, co_id, analyze_id } = props;
  const [opt, setOpt] = useOpt();

  const renderOpt = (datas, isSubject, title) => {
      const e = []
      const a = []
      const maxs = e.sort()
      datas.forEach((data) => {
        // option.xAxis.data.push(isSubject ? data.subject_name : data.co_name);
        e.push(data.estimate_value);
        a.push(data.achievement_value);
      });
      const ind = datas.map((data, index)=>{
        // const max = (e[index] > a[index] ? e[index] : a[index]) 
        return {
          text: isSubject ? data.subject_name : data.co_name,
          max: data.unit === '%' ? 1 : 5
        }
      })
    const option = {
      title: {
        text: title,
      },
      color: ["#B8E986", "#3B7CFF"],
      legend: {},
      radar: {
          indicator: ind,
          // [
            // { text: "Indicator1", max: 150 },
            // { text: "Indicator2", max: 150 },
            // { text: "Indicator3", max: 150 },
            // { text: "Indicator4", max: 120 },
            // { text: "Indicator5", max: 108 },
            // { text: "Indicator6", max: 72 },
          // ],
          // center: ["75%", "50%"],
          radius: 90,
          axisName: {
            color: "#fff",
            backgroundColor: "#666",
            borderRadius: 3,
            padding: [3, 5],
          },
        },
      series: [
        {
          type: "radar",
          data: [
            {
              value: a,
              // name: "综合评分",
              symbol: "rect",
              symbolSize: 12,
              lineStyle: {
                type: "dashed",
              },
              label: {
                show: false,
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            // {
            //   value: a,
            //   name: "实绩",
            //   areaStyle: {
            //     color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
            //       {
            //         color: "rgba(255, 145, 124, 0.1)",
            //         offset: 0,
            //       },
            //       {
            //         color: "rgba(255, 145, 124, 0.9)",
            //         offset: 1,
            //       },
            //     ]),
            //   },
            // },
          ],
        },
      ],
    };
    
    console.log('option', option)
    return option;
  };
  useEffect(() => {
    setOpt(props, renderOpt);
  }, [year, month, co_id, analyze_id]);

  return <Charts option={opt} {...props} style={{height: 300}} />;
};

export const PieC = {
  Comp: Piec,
  props: [...props, {...anySelect, prop: {...prop, analyze_type: 'D'}}],
};

export const PieA = {
  Comp: Piea,
  props: [...props, {...anySelect, prop: {...prop, analyze_type: 'D'}}],
};

export const BarA = {
  Comp: Bar,
  props: [...props, {...anySelect, prop: {...prop, analyze_type: 'D'}}],
};

export const RadarA = {
  Comp: Radar,
  props: [...props, {...anySelect, prop: {...prop, analyze_type: 'S'}}],
};
