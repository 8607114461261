import "./App.less";
import zhCN from "antd/locale/zh_CN";
import Admin from "./pages/admin";
import Login from "./pages/login";
import LoginSuper from "./pages/login/indexSuper";
import PageContent from "./pages/content";
import PageContentA from "./pages/content/contentA";
import PageContentM from "./pages/content/contentM";
import PageContentRE from "./pages/content/contentRE";
import PageContentRA from "./pages/content/contentRA";
import PageContentManage from "./pages/content/manage";
import PageContentBIA from "./pages/content/biA";
import PageContentBIE from "./pages/content/biE";
import PageContentBIEA from "./pages/content/biRA";
import PageContentBIRE from "./pages/content/biRE";
import PageContentT from './pages/content/tools'
import PageContentR from './pages/content/contentR'
import WelCome from "./pages/content/welcome";
import PageContentDr from './pages/content/contentDR'
import Plan from './pages/plan'
import { ConfigProvider } from "antd";
import Store from "./pages/content/store";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import moment from "moment";
import "moment/locale/zh-cn";
import 'dayjs/locale/zh-cn';

moment.locale("zh-cn");

function Content() {
  const params = useParams();
  const mapping = {
    A: <PageContentA />,
    E: <PageContent />,
    M: <PageContentM />,
    RE: <PageContentRE />,
    RA: <PageContentRA />,
    T: <PageContentT />,
    OP: <Plan />,
    R: <PageContentR />,
    RR: <PageContentR />,
    DR: <PageContentDr />,
  };
  const optPages = {
    M: <PageContentManage />,
    A: <PageContentBIA />,
    E: <PageContentBIE />,
    RA: <PageContentBIEA />,
    RE: <PageContentBIRE />
  }
  return params.page_id === "-" || params.title === "-" ? (
    optPages[params.type] ? optPages[params.type] : <h1 style={{ lineHeight: "30vh", textAlign: "center", fontSize: 60, color: '#dddddd' }}>
      {params.typeTitle}
    </h1>
  ) : (
    mapping[params.type] ? mapping[params.type] : <h1 style={{ lineHeight: "30vh", textAlign: "center", fontSize: 60, color: '#dddddd' }}>
    {params.title}
  </h1>
  );
}
function App() {
  return (
    <div className="App">
      <Store>
        <ConfigProvider locale={zhCN} theme={{ token: { colorPrimary: '#1DA57A' } }}>
          <BrowserRouter>
            <Routes>
              <Route path="/admin" element={<Admin />}>
                <Route
                  path="/admin" element={<WelCome />} />
                <Route
                  path="/admin/content/:type/:typeTitle/:page_id/:title/*"
                  element={<Content />}
                />
                {/* <Route
                path="/admin/opt/:type/:typeTitle/:page_id/:title"
                element={<PageContentManage />}
              /> */}

              </Route>
              <Route path="/loginSuper" element={<LoginSuper />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </Store>
    </div>
  );
}

export default App;
