import React, { useState } from 'react'
import Chart from '../../component/eaChartsYear'
import { useParams } from "react-router-dom";
import { subjects_cost, subjects_income} from './biA'
import { useStore } from './store';

export default () => {
    const params = useParams();
    // const year = (new Date()).getFullYear()
    const [store, _] = useStore()
    const month = store.a ? store.a.month : (new Date()).getMonth() + 1
    const year = store.a ? store.a.year : (new Date()).getFullYear().toString()
    return <div className="bi-e" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}} >
        <div style={{display: 'flex', width: '100%', fontSize: 20}}><h1>{year}年</h1>
            </div>
        {
            subjects_income.map(val => {
                return <Chart id={val.id} year={year}   />
            })
        }
        {
            subjects_cost.map((val, index) => {
                return <Chart id={subjects_income[index].id} year={year} cost_id={val.id} />
            })
        }
    </div>
}