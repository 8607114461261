import React, { useState, useEffect } from "react";
import ReportGenerator from "../../component/report_generator";
import http from "../../utils/http";
import { handelResp } from "../../utils";
import { Table, Space, Button, message, Modal, Form, Input } from "antd";
import Preview from "../../component/report_generator/sandbox/components/preview";

function Rg() {
  const [list, setList] = useState([]);
  const [current, setCurrent] = useState();
  const [currentName, setCurrentName] = useState('')
  const [isShowEdit, setShowEdit] = useState(false);
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState()
  const [loadingSave, setLoadingSave] = useState(false)
  const [isNew, setIsNew] = useState(false)

  const getDatail = async (id) => {
    const resp = await http.get("/analyzer/selectArTmplByPK", {
      id,
    });
    return new Promise((resolve, reject) => {
      handelResp(resp)
        .then((data) => {
          resolve(data.tArTmpl);
        })
        .catch((err) => {
          reject();
        });
    });
  };
  const getList = async () => {
    const resp = await http.get("/analyzer/getArTmplList", {
      co_id: localStorage.getItem("coId"),
      user_id: localStorage.getItem("userName"),
    });
    handelResp(resp).then((data) => {
      if (!data.arTmplList) return;
      setList(data.arTmplList);
    });
  };
  useEffect(() => {
    getList();
  }, []);

  const onEdit = (id) => {
    if(form){
      form.resetFields()
    }
    getDatail(id).then((data) => {
      setCurrent(data);
    });
  };

  const onExport = (id) => {
    getDatail(id).then((data) => {
      // setCurrent(data);
      setCurrentName(data.tmpl_name)
      const jsonData = JSON.parse(data.templ_json)
      setPreviewData(jsonData)
    });
  };

  const onDelete = async (id) => {
    Modal.confirm({
      title: '警告',
      content: '确认要删除吗',
      onOk: async ()=>{
        const resp = await http.delete(`/analyzer/deleteArTmpl?id=${id}`)
        handelResp(resp).then(data=>{
          message.success('删除成功')
          getList()
        })
      }
    })
    
  };

  const onSave = async (tml, isNew) => {
    delete tml[-1];
    // console.log("current", current);
    // console.log("tml", tml);
    current.templ_json = JSON.stringify(tml);
    setShowEdit(true);
    setIsNew(isNew)
  };

  const save = async () => {
    // const isV = await form.validateFields(['tmpl_name', 'tmpl_describe'])
    // console.log(isV)
    const values = form.getFieldsValue();
    // console.log("values", values);
    // console.log("current", current);
    current.tmpl_name = values.tmpl_name
    current.tmpl_describe = values.tmpl_describe
    if (current.id && !isNew) {
      setLoadingSave(true)
      const resp = await http.put("/analyzer/updateArTmpl", { ...current });
      handelResp(resp).then((data) => {
        message.success("保存成功");
        setShowEdit(false)
        setLoadingSave(false)
        setCurrent();
      });
    }else{
      setLoadingSave(true)
      if(isNew){
        delete current['id'] 
      }
      const resp = await http.post('/analyzer/createArTmpl', {...current, 
        co_id: localStorage.getItem("coId"),
        user_id: localStorage.getItem("userName"),
        release_type: '03'
      })
      handelResp(resp).then(data=>{
        message.success("保存成功");
        setShowEdit(false)
        setLoadingSave(false)
        setIsNew(false)
        setCurrent();
      })
    }
    getList()
  };


  const exit = ()=>{
    setCurrent()
  }

  return (
    <div className="content-rg-manage">
      {current && <ReportGenerator current={current} onSave={onSave} exit={exit}/>}
      {!current && (
        <div className="rg-lists">
          <Button type="primary" onClick={()=>setCurrent({})}>新建模版</Button>
          <Table
            dataSource={list}
            columns={[
              {
                title: "模版名称",
                dataIndex: "tmpl_name",
                key: "tmpl_name",
              },
              {
                title: "模版描述",
                dataIndex: "tmpl_describe",
                key: "tmpl_describe",
              },
              {
                title: "操作",
                key: "opt",
                render: (_, record) => {
                  return (
                    <Space wrap>
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          onEdit(record.id);
                        }}
                      >
                        编辑
                      </Button>
                      <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                          onExport(record.id);
                        }}
                      >
                        导出
                      </Button>
                      <Button
                        type="dashed"
                        danger
                        size="small"
                        onClick={() => {
                          onDelete(record.id);
                        }}
                      >
                        删除
                      </Button>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      )}
      <Preview data={previewData} name={currentName} onCancel={()=>setPreviewData()} showForm />
      {isShowEdit && current && (
        <Modal
          open={isShowEdit}
          onCancel={() => {
            setShowEdit(false)
            setIsNew(false)
          }}
          onOk={save}
          confirmLoading={loadingSave}
          title={`${current.id ? isNew ? '另存为' : "更新" : "创建"}模版`}
        >
          <Form form={form}>
            <Form.Item label="模版名称" 
            name="tmpl_name"
            initialValue={current.tmpl_name} required rules={[{required: true, message: '不能为空'}]}>
              <Input></Input>
            </Form.Item>
            <Form.Item label="模版描述" 
            name="tmpl_describe"
            initialValue={current.tmpl_describe} required rules={[{required: true, message: '不能为空'}]}>
              <Input></Input>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
}

export default Rg;
