import React, { useEffect, useState, useRef } from 'react'
import http from '../../utils/http'
import { Card, Statistic } from 'antd'
// import './style.less'
import * as echarts from 'echarts/core';
import {
    // 系列类型的定义后缀都为 SeriesOption
    LineChart,
    BarChart,
    PieChart,
} from 'echarts/charts';
import {
    TitleComponent,
    // 组件类型的定义后缀都为 ComponentOption
    GridComponent, LegendComponent,
    ToolboxComponent, TooltipComponent,
    DataZoomComponent,
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import { parseW } from '../eaCharts'
import { useStore } from '../../pages/content/store';
import { handelResp } from '../../utils';
// 通过 ComposeOption 来组合出一个只有必须组件和图表的 Option 类型
// type ECOption = echarts.ComposeOption<
//   BarSeriesOption | LineSeriesOption | TitleComponentOption | GridComponentOption
// >;

// 注册必须的组件
echarts.use(
    [TitleComponent, GridComponent, BarChart, DataZoomComponent,
        LineChart, CanvasRenderer, LegendComponent, PieChart,
        TooltipComponent, ToolboxComponent]
);


const option = {
    // legend: {
    //     data: ['预算', '实绩'],
    //     bottom: 20
    // },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            // axis: 'y'
        },
        // formatter: '{a}月'
        valueFormatter: (value) => value.toFixed(2)
    },
    xAxis: {
        type: 'category',
        data: [],
        axisTick:{
            show:true
        },
        splitLine:{
            show:true
        }
    },
    grid: {
        left: '1%',
        top: '10%',
        right: '2%',
        bottom: '3%',
        containLabel: true
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            formatter: '{value} 万'
        }
    },
    series: [
        {
            name: '预算',
            data: [],
            type: 'bar',
            color: '#B8E986',
        },
        {
            name: '实绩',
            data: [],
            type: 'bar',
            color: '#3B7CFF'
        }
    ]
};

export default props => {

    const { id, year, cost_id } = props
    const [datas, setDatas] = useState()
    const ref = useRef(null)

    const setChart = (datas) => {
        if (!ref.current) return
        const eDatas = []
        const aDatas = []
        const nums = [year - 3, year - 2, year - 1, year]
        Array(3)
            .fill(0).forEach((val, index) => {
                eDatas.push(parseW(datas.estimate_data[`subject_value_past${3 - index}year`]))
                aDatas.push(parseW(datas.achievement_data[`subject_value_past${3 - index}year`]))
            })
        eDatas.push(parseW(datas.estimate_data.subject_value_thisyear))
        option.xAxis.data = nums
        option.series[0].data = eDatas
        option.series[1].data = aDatas
        option.series.forEach(val => {
            val.itemStyle = {
                normal: {
                    // barBorderRadius: [5, 5, 0, 0]
                }
            }
        })
        const chart = echarts.init(ref.current)
        chart.setOption(option)
    }
    const [store, setStore] = useStore()
    const getData = async () => {
        const income_url = '/base/getSubjectEAYearlyData'
        const cost_url = '/base/getProfitEAYearlyData'
        const resp = await http.get(cost_id ? cost_url : income_url, {
            subject_id: cost_id ? null : id,
            co_id: store.co_id ? store.co_id : localStorage.getItem("coId"),
            year,
            income_subject_id: cost_id ? id : null,
            cost_subject_id: cost_id
        })
       handelResp(resp).then(()=>{
            setDatas(resp.data.subjectEAYearlyData)
            setChart(resp.data.subjectEAYearlyData)
       })
    }



    useEffect(() => {
        getData()
    }, [store])

    return <div className="ea-chart">
            {datas && <div>
                <div className="line">
                    <div className='text'><span>{datas.achievement_data.subject_name}</span><span><Statistic value={datas.estimate_data.subject_value_thisyear} precision={2} /></span></div>
                    <div className='text-1'><span>增长率</span><span>{datas.achievement_data.subject_value_past1year ? ((datas.estimate_data.subject_value_thisyear - datas.achievement_data.subject_value_past1year) / Math.abs(datas.achievement_data.subject_value_past1year) * 100).toFixed(2): 0} %</span></div>
                </div>
            </div>}
            <div className="chart" ref={ref} style={{ width: '100%', height: 300 }}></div>
    </div>
}