import React from 'react';
import './style.less'

const props = [
  {
    label: "标题",
    type: "input",
    name: "title",
  },
]

function Title(props) {
  const { title } = props
  return (
    <div className='rg-title'>
      <div className="dol"></div>
      <div className="text">
        {title ? title: '标题'}
      </div>
    </div>
  );
}

export default {
  Comp: Title,
  props
};
