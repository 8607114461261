import React, { useState } from 'react'
import Chart from '../../component/eaCharts'
import { useParams } from "react-router-dom";
import { useStore } from './store';

export const subjects_income = [
    {
        id: '1010',
        name: '主营业务收入'
    },
    {
        id: '101010',
        name: '整车销售收入'
    },
    {
        id: '101020',
        name: '综合业务收入'
    },
    {
        id: '101030',
        name: '二手车销售收入'
    },
    {
        id: '101040',
        name: '售后收入'
    },
]

export const subjects_cost = [
    {
        id: '2010',
        name: '主营业务收入'
    },
    {
        id: '201010',
        name: '整车销售收入'
    },
    {
        id: '201020',
        name: '综合业务收入'
    },
    {
        id: '201030',
        name: '二手车销售收入'
    },
    {
        id: '201040',
        name: '售后收入'
    },
]

export default () => {
    const params = useParams();
    const [store, _] = useStore()
    const month = store.a ? store.a.month : (new Date()).getMonth() + 1
    const year = store.a ? store.a.year : (new Date()).getFullYear().toString()
    return <div className="bi-a" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        <div style={{display: 'flex', width: '100%', fontSize: 20}}><h1>{store.a ? store.a.year : year}年</h1>
            <h1 style={{}}>{store.a ? store.a.month : month}月</h1></div>
        {
            subjects_income.map(val => {
                return <Chart id={val.id} year={store.a ? store.a.year : year} month={store.a ? store.a.month : month} />
            })
        }
        {
            subjects_cost.map((val, index) => {
                return <Chart id={subjects_income[index].id} year={year} month={month} cost_id={val.id} />
            })
        }
    </div>
}