import React, { useEffect, useState } from 'react';
import http from '../../../../../utils/http'
import { doneRate, handelResp, increaseRate } from '../../../../../utils'
import './style.less'
import { opts } from '../preview'
import { handleAnyFields } from '../anySelector';
const props = [
  // {
  //   label: "公司名",
  //   type: "input",
  //   name: "co_name",
  //   isSystem: true
  // },
  {
    label: "年",
    type: "input",
    name: "year",
    isSystem: true
  },
  {
    label: "月",
    type: "input",
    name: "month",
    isSystem: true
  },
  {
    label: "公司名",
    type: "coSelect",
    name: "co_id",
    isSystem: true
  },
  {
    label: "指标",
    type: "anySelect",
    name: "analyze_id",
    // isSystem: true
    prop:{
      analyze_type: 'D'
    }
  },
]
const monthOpt = {}
opts.forEach(opt =>{
  monthOpt[opt.value] = opt.label
})
const items = [
  {
    label: '预算数',
    field: 'estimate_value'
  },
  {
    label: '实绩数',
    field: 'achievement_value'
  },
  {
    label: '完成率',
    field: ''
  },
  {
    label: '上年同期',
    field: 'sply_value'
  },
  {
    label: '同比增长',
    field: ''
  },
]
const items2 = [
  {
    label: '累计预算',
    field: 'estimate_value'
  },
  {
    label: '累计实绩',
    field: 'achievement_value'
  },
  {
    label: '完成率',
    field: ''
  },
  {
    label: '上年同期',
    field: 'sply_value'
  },
  {
    label: '同比增长',
    field: ''
  },
  {
    label: '全年预算',
    field: 'estimate_value_year'
  },
  // {
  //   label: '累计实绩',
  //   field: 'achievement_value_YTM'
  // },
  {
    label: '年达成率',
    field: ''
  },
  // {
  //   label: '上年同期',
  //   field: 'sply_value'
  // },
  // {
  //   label: '同比增长',
  //   field: ''
  // },
]
function format(num) {
  return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
}
function Table(props) {
  const { year,  month, co_id, analyze_id} = props
  const [datas, setDatas] = useState([])
  const getData = async ()=>{
    if(!(year&&month&&co_id&&analyze_id))return
    const {url, params} = handleAnyFields(analyze_id, {
      co_id,
      year,
      month, 
    })
    const resp = await http.get(url, params)
    handelResp(resp).then(data=>{
      setDatas(data.resultData ? data.resultData : [])
    })

  }

  useEffect(()=>{
    getData()
  }, [year,  month, co_id, analyze_id])

  let isSubject = true

  if(datas.length > 1){
    if(datas[0].subject_name === datas[1].subject_name){
      isSubject = false
    }
  }
  // console.log('isSubject', isSubject)
  const isYTM = month&&month.indexOf('YTM') > -1
  const its = (isYTM ? items2 : items)
  return (
    <table className='rg-table'>
      <thead className="head">
        <tr>
        <td className="name" rowSpan={2}>{isSubject ? '科目名' : '公司名'}</td>
        {/* <div className="t-items"> */}
          <td className="date" colSpan={its.length}>{year}年{monthOpt[month] ? monthOpt[month] : `${month}月`.replace("YTM", '01~')}</td>
          </tr>
          <tr className="t-its">
            {
              its.map((item, index)=>{
                return <td className="t-item" key={index}>{item.label}</td>
              })
            }
          </tr>
        {/* </div> */}
      </thead>
      <tbody className="body">
          {datas.map((data, index)=>{
            return <tr className="line" key={index}>
               <td className="name">{isSubject ? data.subject_name : data.co_name}</td>
             {/* <div className="items" key={index}> */}
              {
                its.map((item, key)=>{
                  let value = data[item.field]
                  if(value){
                    value = value.toFixed(2)
                  }
                  if(data.unit === '元'){
                    value = format(value)
                  }
                  if(data.unit === '%'){
                    value = (value * 100).toFixed(2) + '%'
                  }
                  if(item.label === '完成率'){
                    // value = ((data.achievement_value / data.estimate_value) * 100)
                    value = doneRate(data.achievement_value, data.estimate_value) * 100
                    if(value){
                      value = value.toFixed(2) + '%'
                    }
                    if(!data.estimate_value){
                      value = 0
                    }
                  }
                  if(item.label === '同比增长'){
                    // ((data.achievement_value-  data.sply_value) * 100)
                    value = increaseRate(data.achievement_value, data.sply_value)  * 100
                    if(value){
                      value = value.toFixed(2) + '%'
                    }
                    if(!data.sply_value){
                      value = 0
                    }
                  }
                  if(item.label === '年达成率'){
                    value = doneRate(data.achievement_value_YTM, data.estimate_value_year) * 100
                    if(value){
                      value = value.toFixed(2) + '%'
                    }
                    if(!data.estimate_value){
                      value = 0
                    }
                  }
                  return <td className="item" key={key}>
                    <span> {value ? value : 0} </span>
                  </td>
                })
              }
            {/* </div> */}
            </tr>
          })}
      </tbody>
    </table>
  );
}

export default {Comp: Table, props};
