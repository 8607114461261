import React from 'react';
import './style.less'

export const Header = (props)=>{
  const {name} = props
  return <div className="rg-page-header">
    {name}
  </div>
}


export const Footer = props =>{
  const {num} = props
  return <div className="rg-page-footer">
    <div className="rg-page-num">
      <div className='rg-page-num-text'># {num ? num : 1}</div>
    </div>
    <div className="rg-footer-line1"></div>
    <div className="rg-footer-line2"></div>
  </div>
}