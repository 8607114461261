import Text from "./Text";
import Image from "./Image";
import Title from "./Title"
import BigTitle from "./bigTitle"
import Table from "./table";
import Blank from "./Blank";
import { PieC, PieA, BarA, RadarA } from './charts'
import MonthChart from "./charts/MonthChart";
import ManyAny from "./charts/MonthChart/manyAny";
import Desc from './desc'


export const wrap = [
    {
        label: '布局',
        type: 'radio',
        name: 'layout',
        defaultValue: 'single',
        options: [
          { label: '单列', value: 'single' },
          { label: '多列', value: 'multi' },
        ],
    },
]



export const mapping = {
    wrap: {props: wrap},
    divide: {Comp: ()=><div style={{height: 170}}/>,props: []},
    text: Text,
    image: Image,
    title: Title,
    bigTitle: BigTitle,
    table: Table,
    blank: Blank,
    piec: PieC,
    piea: PieA,
    bara: BarA,
    monthChart: MonthChart,
    desc: Desc,
    radar: RadarA,
    manyAny: ManyAny
}

export const groupComponent = [
  {
    name:'布局',
    key: 'layout',
    components: [
      { compId: '1', compName: '栅格', compType: 'wrap' },
      // { compId: '15', compName: '页分隔', compType: 'wrap' },
      { compId: '7', compName: '空白', compType: 'blank' },
    ]
  },
  {
    name: '文本',
    key: 'text',
    components: [
      { compId: '2', compName: '文本', compType: 'text', },
      { compId: '12', compName: '概括', compType: 'desc', },
      { compId: '4', compName: '标题', compType: 'title', },
    ]
  },
  {
    name: '图表',
    key: 'chart-table',
    components: [
      { compId: '6', compName: '表格', compType: 'table', },
      { compId: '8', compName: '环形图', compType: 'piec', },
      { compId: '9', compName: '饼图', compType: 'piea', },
      { compId: '10', compName: '柱状图', compType: 'bara', },
      { compId: '11', compName: '单指标月份图', compType: 'monthChart', },
      { compId: '14', compName: '多指标月份图', compType: 'manyAny', },
      { compId: '13', compName: '雷达', compType: 'radar', },
    ]
  }
]

export const component = [
    { compId: '1', compName: '栅格', compType: 'wrap' },
    { compId: '7', compName: '空白', compType: 'blank' },
    { compId: '2', compName: '文本', compType: 'text', },
    { compId: '12', compName: '概括', compType: 'desc', },
    // { compId: '3', compName: '图片', compType: 'image', },
    { compId: '4', compName: '标题', compType: 'title', },
    // { compId: '5', compName: '大标题', compType: 'bigTitle', },
    { compId: '6', compName: '表格', compType: 'table', },
    { compId: '8', compName: '环形图', compType: 'piec', },
    { compId: '9', compName: '饼图', compType: 'piea', },
    { compId: '10', compName: '柱状图', compType: 'bara', },
    { compId: '11', compName: '折线图', compType: 'monthChart', },
    { compId: '14', compName: '多指标折线图', compType: 'manyAny', },
    { compId: '13', compName: '雷达', compType: 'radar', },
]




export const settingSystem = (value, dts)=>{
        const {
          monthDate,
          co_id,
        } = value;
        // console.log(co_name);
        // const {co_id , co_name} = co
        const date = monthDate.split('-')
        const data = {
          year: date.length > 1 ? date[0] : null,
          month: date.length > 1 ? date[1] : null,
          co_id,
        };
        
        // console.log('dts', dts)
        // console.log('data', data)
        dts.forEach((val) => {
          // console.log('compType', val)
          if(val.compType === 'wrap'){
            settingSystem(value, val.children)
          }
          mapping[val.compType].props.forEach((prop) => {
    
            if (prop.isSystem && data[prop.name]) {
              val.formData[prop.name] = data[prop.name];
            }
          });
        });
        return dts
}