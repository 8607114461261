import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import http from "../../utils/http";
import { displayNum, handelResp, handleMonth, doneRate } from "../../utils";
import { cols, mapping } from "./weekly";
import { useStore } from "./store";
import { DatePicker, Row, Col, Select, Button } from "antd";
import "./weekly.less";
import L from 'lodash'

function WeeklyReport() {
  const [items, setItems] = useState([]);
  const [datas, setDatas] = useState([]);
  const [date, setDate] = useState(dayjs());
  const [store, _] = useStore();
  const [comps, setComps] = useState([]);
  const [coId, setCoId] = useState(localStorage.getItem("coId"));
  // const [co_type, setco_type] = useState(localStorage.getItem("co_type"))
  const [co_name, setco_name] = useState(localStorage.getItem("co_name"));

  const getData = async () => {
    // const url = store.isSuper
    //   ? "/weekly/getWeeklySummary"
    //   : "/weekly/getWeeklyReport";
    const url = "/weekly/getWeeklyReport";
    const resp = await http.get(url, {
      co_id: localStorage.getItem('co_type') === 'HQ' ? store.coId : coId,
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
    });
    handelResp(resp)
      .then((resp) => {
        setItems(resp.weeklyDatas ? resp.weeklyDatas : []);
        getDatas();
      })
      .catch((e) => {
        setItems([]);
        setDatas([]);
      });
  };
  const getCopms = async () => {
    const resp = await http.get("/base/selectUserCompany");
    handelResp(resp).then((data) => {
      if (data.userCompanys) {
        setComps(data.userCompanys);
      } else {
        setComps([]);
      }
    });
  };

  const getDatas = async () => {
    const url = "/weekly/getEnhances";

    const resp = await http.get(url, {
      co_id: localStorage.getItem('co_type') === 'HQ' ? store.coId : coId,
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
      page: 1,
      pageSize: 100,
    });
    handelResp(resp).then((data) => {
      data.enhances ? setDatas(data.enhances) : setDatas([]);
    });
  };

  useEffect(() => {
    getData();
    if (!store.isSuper) {
      getCopms();
    }
  }, [store, coId, date]);
  const monthFormat = "YYYY/MM";
  const btm = 5;
  const names = ["项目名", "目标", "累计完成", "累计完成率", "备注"];
  const mps = {
    0: "item_name",
    1: "target",
    2: "achievement",
  };
  const downLoad = async () => {
    const response = await http.download("/weekly/exportJyWeeklyExcel", {
      co_id: coId,
      year: date.year().toString(),
      month: handleMonth(date.month() + 1),
    });
    var blob = new Blob([response.data], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute(
      "download",
      decodeURI(co_name + "周报" + date.format(monthFormat) + ".xlsx")
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="weekly">
      <div className="header">
        <div>
          <DatePicker
            style={{ width: 180 }}
            onChange={(datem, dateString) => {
              setDate(datem);
            }}
            defaultValue={date}
            disabledDate={(current) => current > dayjs().endOf("day")}
            picker="month"
            format={monthFormat}
          />
          {localStorage.getItem('co_type') !== 'HQ' && (
            <Select
              style={{ width: 200 }}
              onChange={(e) => {
                const comp = comps.filter((val) => val.co_id === e)[0];
                setCoId(e);
                setco_name(comp.co_name);
                // setco_type(comp.co_type);
              }}
              value={coId}
            >
              {comps.map((val) => {
                return (
                  <Select.Option key={val.co_id}>{val.co_name}</Select.Option>
                );
              })}
            </Select>
          )}
        </div>
        <h1>{localStorage.getItem('co_type') === 'HQ' ? store.co_name : co_name}周报</h1>
        <div>
          <Button type="primary" onClick={downLoad}>
            下载周报
          </Button>
        </div>
      </div>
      {items.length ? (
        <div className="header-item">
          <Row align="middle" gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col span={3}>
              <h1></h1>
            </Col>
            {cols.map((col, key) => {
              return (
                <Col key={L.uniqueId()} span={3}>
                  <h1 style={{ textAlign: "right" }}>{col}</h1>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : null}
      {items.map((val) => {
        const isT = val.item_type === "01";
        return (
          <React.Fragment key={L.uniqueId()}>
            {
              <div className="item-header">
                <Row
                  style={{ marginBottom: btm }}
                  align="middle"
                  gutter={{ xs: 8, sm: 16, md: 24 }}
                >
                  <Col span={3}>
                    <h1 style={{ textAlign: "left" }}>{val.item_name}</h1>
                  </Col>
                  {cols.map((col) => {
                    // let value = 0;
                    // if (col === "累计完成率" && val.m_target) {
                    //   value = ((val.m_total / val.m_target) * 100).toFixed(2);
                    //   value = parseFloat(value);
                    // } else if (col === "当月累计数") {
                    //   value = val.w1 + val.w2 + val.w3 + val.w4;
                    // } else {
                    //   if (val.subitems) {
                    //     val.subitems.forEach((element) => {
                    //       value += element[mapping[col]];
                    //     });
                    //   }
                    //   val[mapping[col]] = value;
                    // }
                    let value = val[mapping[col]];
                    return (
                      <Col key={L.uniqueId()} span={3}>
                        <div>
                          <p className="number" style={{ textAlign: "right" }}>
                            {displayNum(value, col === "累计完成率" ? `%` : val.unit)}
                          </p>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            }
            {val.subitems && val.subitems.length > 1 && (
              <div className="items">
                {val.subitems.map((sub) => {
                  return (
                    <Row
                      style={{ marginBottom: btm }}
                      align="middle"
                      key={L.uniqueId()}
                      gutter={{ xs: 8, sm: 16, md: 24 }}
                    >
                      <Col span={3}>
                        {store.isSuper || isT ? (
                          <div style={{ textAlign: "right" }}>
                            {sub.subitem_name}
                          </div>
                        ) : (
                          <h1 style={{ textAlign: "left" }}>{val.item_name}</h1>
                        )}
                      </Col>
                      {cols.map((col) => {
                        // let value = 0;
                        // if (col === "当月累计数") {
                        //   value = sub.w1 + sub.w2 + sub.w3 + sub.w4;
                        // } else if (col === "累计完成率" && sub.m_target) {
                        //   value = (
                        //     ((sub.w1 + sub.w2 + sub.w3 + sub.w4) /
                        //       sub.m_target) *
                        //     100
                        //   ).toFixed(2);
                        //   value = parseFloat(value);
                        // } else {
                        //   value = sub[mapping[col]];
                        // }
                        let value = sub[mapping[col]];
                        return (
                          <Col span={3} key={L.uniqueId()}>
                            <div>
                              <p
                                className="number"
                                style={{ textAlign: "right" }}
                              >
                                {displayNum(value, col === "累计完成率" ? `%` : sub.unit)}
                              </p>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  );
                })}
              </div>
            )}
          </React.Fragment>
        );
      })}
      <div className="line"></div>
      <h1 style={{ fontSize: 16, textAlign: "left" }}>整改项目</h1>
      
      <Row style={{ marginBottom: btm }} gutter={16}>
          <Col span={4}></Col>
          {names.map((val, index) => {
            return (
              <Col key={L.uniqueId()} span={4}>
                <div style={{ textAlign: index > 0 ? "right" : "left" }}>
                  {val}
                </div>
              </Col>
            );
          })}
        </Row>
      <div className="items">
        {datas.map((val) => {
          return (
            <React.Fragment key={L.uniqueId()}>
              <div className="item-header">
                <Row style={{ marginBottom: btm }} gutter={16}>
                  <Col span={4} key={L.uniqueId()}>
                    <h1 style={{ textAlign: "left" }}>{val.co_name}</h1>
                  </Col>
                </Row>
              </div>
              {val.enhanceExt01s.map((val) => {
                return (
                  <Row style={{ marginBottom: btm }} gutter={16} key={L.uniqueId()}>
                    <Col span={4}></Col>
                    {names.map((_, index) => {
                      return (
                        <Col key={L.uniqueId()} span={4}>
                          <p
                            className="number"
                            style={{
                              textAlign: index > 0 ? "right" : "left",
                            }}
                          >
                            {index === 0 && val["item_name"]}
                            {index === 1 && displayNum(val['target']/100,val.unit) }
                            {index === 2 && displayNum(val['achievement']/100,val.unit) }
                            {index === 3 && displayNum(doneRate(val['achievement'], val['target']),"%") }
                            {index === 4 && val["memo"]}
                          </p>
                        </Col>
                      );
                    })}
                  </Row>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default WeeklyReport;
